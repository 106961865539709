import ActionTypes from './ActionTypes';
import { CategoryService, NotificationService } from '../services';

export const listCategories = () => async dispatch => {
  try {
    dispatch({ type: ActionTypes.LIST_CATEGORIES });
    const categories = await CategoryService.listMenu();
    dispatch({ 
      type: ActionTypes.LIST_CATEGORIES_SUCCESS, 
      payload: prepareCategories(categories) 
    });
  } catch (e) {
    dispatch({ type: ActionTypes.LIST_CATEGORIES_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const getCategory = (categoryId) => async dispatch => {
  try {
    dispatch({ type: ActionTypes.GET_CATEGORY, payload: categoryId });
    const category = categoryId 
      ? await CategoryService.get(categoryId) 
      : null;
    dispatch({ 
      type: ActionTypes.GET_CATEGORY_SUCCESS, 
      payload: category
    });
  } catch (e) {
    dispatch({ type: ActionTypes.GET_CATEGORY_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateCategoryField = ({ prop, value }) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CATEGORY_FIELD,
    payload: { prop, value }
  });
};

const prepareCategories = (categories) => {
  let result = [];

  categories.forEach(c => {
    if (!c.idCategoriaPai) {
      c.categorias = [];
      result.push(c);
    } else {
      const parent = result.find(f => f.id === c.idCategoriaPai);
      parent.categorias = [...parent.categorias, c];

      result = [...result.filter(f => f.id !== c.idCategoriaPai), parent];
    }
  });
  return result;
};

