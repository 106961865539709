import WebApi from './WebApi';
import { UploadDestinations } from '../constants';
import { FileService } from '.';

export class BackOfficeService {

  static async search({ pageNumber: numeroPagina, pageSize: registrosPorPagina, cpf, status }) {
    const response = await WebApi.get('/pedidos', { cpf, status, numeroPagina, registrosPorPagina });
    return response.data;
  }

  static async updateServiceOrder(orderId, serviceOrder) {
    const response = await WebApi.patch(`/pedidos/${orderId}/ordem-servico/${serviceOrder}`);
    return response.data;
  }

  static async updateStatus(orderId, status, idInvoice) {
    const response = await WebApi.patch(`/pedidos/${orderId}/status/${status}`, { idNotaFiscal: idInvoice });
    return response.data;
  }

  static async exportOrders() {
    const response = await WebApi.get('/pedidos/exportacao', { }, { responseType: 'blob'});
    return response.data;
  }

  static async uploadInvoice(file) {
    const data = new FormData();
    data.append('EnderecoDestino', `${UploadDestinations.Invoice}`);
    data.append('Arquivo', file, `nota-fiscal-${new Date().getTime()}.pdf`);
    return await FileService.upload(data); 
  }

}