import ActionTypes from './ActionTypes';
import { push } from 'connected-react-router';
import { NotificationService, ProductService } from '../services';

export const getProduct = (productId, requiredValidation = false) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.GET_PRODUCT });
    const product = await ProductService.get(productId, requiredValidation);
    const dataLayer = {
      event: 'productDetail',
      ecommerce: {
        click: {
          products: [
            {
              name: product.nome,
              id: product.id,
              price: product.preco,
              category: product.vacina ? 'Vacina' : 'Análises Clínico',
            },
          ],
        },
      },
    };
    window.dataLayer.push(dataLayer);
    dispatch({ type: ActionTypes.GET_PRODUCT_SUCCESS, payload: product });
  } catch (e) {
    dispatch({ type: ActionTypes.GET_PRODUCT_FAIL });
    dispatch(push('/'));
    NotificationService.showApiResponseErrorAlert(e);
  }
};
