import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import * as BackOfficeActions from '../../../actions/BackOfficeActions';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../../components';
import './style.scss';

const pageSize = 10;

class Products extends Component {
  componentDidMount = () => {
    this.paginateProducts(1);
  };

  paginateProducts = (pageNumber) => {
    this.props.searchProducts({ ...this.props, pageNumber, pageSize });
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.paginateProducts(1);
  };

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updateBackOfficeProductField({ prop, value });
  };

  handleInactiveProduct(product) {
    this.props.inactiveProduct(product);
  }

  handleActiveProduct(product) {
    this.props.activeProduct(product);
  }

  redirectToProductManager(product = null) {
    this.props.redirectToProductManager(product);
  }

  render = () => {
    const { products } = this.props;
    return (
        <div className="backoffice-products">
          <Header />
          <NavigationBar title="Gerenciar Produtos" breadcrumb={[
            { to: '/', title: 'Home' },
            { to: '/backoffice/produtos', title: 'Gerenciar Produtos' }
          ]}/>
         <div className="gray-container">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group text-right" title="Cadastrar">
                <button className="btn btn-export" onClick={() => this.redirectToProductManager()}><span className="glyphicon glyphicon-plus"></span></button>
              </div>
              <form className="backoffice-search-form" onSubmit={this.handleSearchSubmit}>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 form-group">
                  <input type="text" placeholder="Filtre por nome" name="cpf" value={this.props.name} onChange={e => this.handleChange('name', e)} />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 form-group">
                  <select name="status" value={this.props.active} onChange={(ev) => this.handleChange('active', ev)}>
                    <option value="">Filtre por status</option>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </div>
                <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 form-group">
                  <button className="btn btn-submit" type="submit"><span className="glyphicon glyphicon-search"></span></button>
                </div>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text">Código</th>
                    <th className="text">Nome</th>
                    <th className="text">UF</th>
                    <th className="text">Preço</th>
                    <th className="text">Status</th>
                    <th className="text">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map(o => (
                    <tr key={o.id}>
                      <td className="text small">
                        {o.codigoErp.split('-').map(cod => ( 
                          <p key={cod}>{cod}</p>
                        ))}
                      </td>
                      <td className="text small">{o.nome}</td>
                      <td className="text small">{o.uf}</td>
                      <td className="text small"><NumberFormat 
                        value={o.preco} 
                        displayType={'text'}
                        decimalSeparator={','} 
                        thousandSeparator={'.'} 
                        prefix={'R$'} 
                        fixedDecimalScale
                        decimalScale={2}/></td>
                      {o.ativo ? (
                        <td className="text small product-active">ATIVO</td>
                      ):(
                        <td className="text small product-inactive">INATIVO</td>
                      )}
                      <td>
                        <button className="btn btn-edit" title="Editar" onClick={() => this.redirectToProductManager(o)}>
                          <span className="glyphicon glyphicon-pencil"></span>
                        </button>
                        {o.ativo ? (
                          <button className="btn btn-edit btn-inactive" title="Inativar" onClick={() => this.handleInactiveProduct(o)}>
                            <span className="glyphicon glyphicon-arrow-down"></span>
                          </button>
                        ):(
                          <button className="btn btn-edit" title="Ativar" onClick={() => this.handleActiveProduct(o)}>
                            <span className="glyphicon glyphicon-arrow-up"></span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                  {products.length === 0 && (!this.pageNumber || this.pageNumber === 1) &&
                    <tr>
                      <td className="text text-center" colSpan="7">Não foram encontrados produtos!</td>
                    </tr>}
                  {products.length === 0 && this.pageNumber > 1 &&
                    <tr>
                      <td className="text text-center" colSpan="7">Não há mais produtos para serem exibidos!</td>
                    </tr>}
                </tbody>
              </table>
              <div className="col-xs-12 text-center">
                <button className="btn btn-pagination" disabled={this.props.pageNumber === 1} onClick={() => this.paginateProducts(this.props.pageNumber - 1)}>
                  <span className="glyphicon glyphicon-menu-left"></span> Anterior 
                </button>
                <button className="btn btn-pagination" disabled={products.length < pageSize} onClick={() => this.paginateProducts(this.props.pageNumber + 1)}>
                Próxima <span className="glyphicon glyphicon-menu-right"></span> 
                </button>
              </div>
            </div>
          </div>
        </div> 
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = ({ backOfficeProducts }) => {
    return { 
      ...backOfficeProducts
    };
  };
  
  export default connect(
    mapStateToProps,
    BackOfficeActions
  )(Products);