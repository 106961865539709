import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { Header, Footer, NavigationBar, CheckoutSteps } from '../../components';
import { ShippingType, ReceiverType, OrderStatus } from '../../constants';
import {
  redirectToBasket,
  fillReceiptFromOrder,
} from '../../actions/OrderActions';
import { redirectToUserOrders } from '../../actions/UserPageActions';
import './style.scss';
import NumberFormat from 'react-number-format';

class Receipt extends Component {
  state = {
    messages: {
      [OrderStatus.WaitingPayment]: 'Seu pagamento ainda não foi identificado.',
      [OrderStatus.PaymentError]:
        'Ocorreu um erro em seu pagamento, tente outra forma de pagamento.',
      [OrderStatus.Paid]: 'Seu pagamento foi autorizado.',
      [OrderStatus.Reversed]: 'Solicitação de estorno.',
      [OrderStatus.Canceled]:
        'Solicitação de cancelamento. Receberá em até 10 dias úteis a carta de cancelamento.',
      [OrderStatus.Sent]:
        'Seu pedido foi encaminhado para a entrega, favor verificar seu e-mail.',
      [OrderStatus.Delivered]:
        'Seu pedido foi entregue, obrigada pela preferência.',
      [OrderStatus.WaitingSchedule]:
        'Seu pedido está aguardando agendamento da visita.',
    },
  };

  componentDidMount = () => {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    if ((this.props.order || {}).id !== orderId)
      this.props.fillReceiptFromOrder(orderId);
  };

  redirectToUserOrders = () => {
    this.props.redirectToUserOrders();
  };

  render = () => {
    const { order } = this.props;
    return (
      <div className="receipt">
        <Header />
        <NavigationBar
          title="Recibo"
          breadcrumb={[
            { to: '/', title: 'Home' },
            { to: `/recibo/${(order || {}).id}`, title: 'Recibo' },
          ]}
        />
        <CheckoutSteps
          basket
          payment
          receipt
          products={order ? order.produtos : []}
        />
        {order && (
          <div className="gray-container">
            <div className="container">
              <div className="order-info text-center">
                <div className="order-number">
                  <p className="text">
                    Pedido: <span className="bold">{order.id}</span>
                  </p>
                </div>
                <p className="text text-center primary">
                  <img
                    src="/img/icons/exclamation.png"
                    srcSet="/img/icons/exclamation@2x.png 2x, /img/icons/exclamation@3x.png 3x"
                  />{' '}
                  {this.state.messages[order.status]}
                </p>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text">Qtde.</th>
                      <th className="text">Produto</th>
                      <th className="text">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(order.produtos || []).map((p) => (
                      <tr key={p.id}>
                        <td className="text">{p.quantidade}</td>
                        <td className="text">{p.nomeProduto}</td>
                        <td className="text">
                          <NumberFormat
                            value={p.precoUnitario}
                            displayType={'text'}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            prefix={'R$'}
                            fixedDecimalScale
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {order.taxaEntrega !== '0' && (
                    <tfoot>
                      <tr>
                        <td className="text semi-bold" colSpan="2">
                          Taxa de Deslocamento
                        </td>
                        <td className="text semi-bold">
                          <NumberFormat
                            value={order.taxaEntrega}
                            displayType={'text'}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            prefix={'+ R$'}
                            fixedDecimalScale
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
            </div>
            <div className="total">
              <div className="container">
                <p className="text semi-bold pull-left">TOTAL</p>
                <p className="text bold pull-right">
                  <NumberFormat
                    value={order.valorTotal}
                    displayType={'text'}
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    prefix={'R$'}
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </p>
              </div>
            </div>
            {order.pagamento ? (
              <div className="payment-info">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-6 text-left">
                      <p className="text bold">
                        Forma de Pagamento:{' '}
                        <span className="brand">
                          {order.pagamento.cartao.bandeira}
                        </span>
                      </p>
                    </div>

                    <div className="col-xs-6 text-right">
                      <p className="text semi-bold">
                        Valor parcelado:{' '}
                        <span className="bold">
                          {' '}
                          {order.pagamento.quantidadeParcelas}x
                          <NumberFormat
                            value={order.pagamento.valorParcela}
                            displayType={'text'}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            prefix={' R$ '}
                            fixedDecimalScale
                            decimalScale={2}
                          />
                        </span>
                      </p>
                    </div>

                    <div
                      className={`col-xs-12 text-left ${
                        order.idNotaFiscal ? 'visible' : 'hidden'
                      }`}
                      title="Download da Nota Fiscal"
                    >
                      <p className="text bold">
                        Nota Fiscal:
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_API_URL}/arquivos/${
                            order.idNotaFiscal
                          }`}
                          className="pdf"
                        >
                          <img
                            src="/img/icons/pdf.png"
                            srcSet="/img/icons/pdf@2x.png 2x, /img/icons/pdf@3x.png 3x"
                            className="img"
                          />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="container">
              <div className="row form-container">
                <form action="POST">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 className="title">Onde deseja receber a compra?</h3>
                    <div className="col-xs-12 form-group radios">
                      {order.tipoEntrega === ShippingType.Residence && (
                        <label className="radio-inline text">
                          <input
                            type="radio"
                            name="entrega"
                            value="home"
                            checked
                            disabled
                          />{' '}
                          No meu endereço
                        </label>
                      )}
                      {order.tipoEntrega === ShippingType.Unity && (
                        <label className="radio-inline text">
                          <input
                            type="radio"
                            name="entrega"
                            value="unity"
                            checked
                            disabled
                          />{' '}
                          Em uma unidade
                        </label>
                      )}
                    </div>
                    {order.tipoEntrega === ShippingType.Residence && (
                      <div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">CEP</label>
                          <input
                            type="text"
                            name="cep"
                            disabled
                            value={order.enderecoEntrega.cep}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">Endereço</label>
                          <input
                            type="text"
                            name="address"
                            disabled
                            value={order.enderecoEntrega.logradouro}
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">Número</label>
                          <input
                            type="text"
                            name="number"
                            disabled
                            value={order.enderecoEntrega.numero}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">Complemento</label>
                          <input
                            type="text"
                            name="complement"
                            disabled
                            value={order.enderecoEntrega.complemento}
                          />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                          <label className="text">Bairro</label>
                          <input
                            type="text"
                            name="neighborhood"
                            disabled
                            value={order.enderecoEntrega.bairro}
                          />
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 form-group">
                          <label className="text">Cidade</label>
                          <input
                            type="text"
                            name="cidade"
                            disabled
                            value={order.enderecoEntrega.cidade}
                          />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-group">
                          <label className="text">Estado</label>
                          <select
                            name="estado"
                            disabled
                            value={order.enderecoEntrega.estado}
                          >
                            <option value={order.enderecoEntrega.estado}>
                              {order.enderecoEntrega.estado}
                            </option>
                          </select>
                        </div>
                        {!order.produtos.find(
                          (p) => p.agendamentoObrigatorio
                        ) ? (
                          <div className="col-xs-12 form-group">
                            <label className="text">
                              Sugestão de Dia e Horário de Entrega
                            </label>
                            <input
                              type="text"
                              name="neighborhood"
                              disabled
                              value={
                                order.enderecoEntrega.sugestaoDataHoraEntrega
                              }
                            />
                          </div>
                        ) : (
                          <div className="col-xs-12 form-group">
                            <label className="text">Agendado para</label>
                            <input
                              type="text"
                              name="neighborhood"
                              disabled
                              value={
                                order.horarioAgendamento
                                  ? moment(order.horarioAgendamento).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  : ''
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {order.tipoEntrega === ShippingType.Unity && (
                      <div className="col-xs-12 unity">
                        <p className="text">{order.unidade.descricao}</p>
                        <p className="text">{order.unidade.logradouro}</p>
                        <p className="text">
                          {order.unidade.bairro} - {order.unidade.cidade} /{' '}
                          {order.unidade.estado}
                        </p>
                        {order.produtos.find(
                          (p) => p.agendamentoObrigatorio
                        ) && (
                          <p className="text">
                            Agendado para:{' '}
                            <span className="bold">
                              {order.horarioAgendamento
                                ? moment(order.horarioAgendamento).format(
                                    'DD/MM/YYYY HH:mm'
                                  )
                                : 'Não informado'}
                            </span>
                          </p>
                        )}
                        <p className="text">
                          Tipo de Retirada:{' '}
                          <span className="bold">
                            {order.driveThru
                              ? 'Serviço Drive-Thru'
                              : 'Dentro da Unidade'}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 className="title">
                      Quem será o beneficiário da compra?
                    </h3>

                    <div className="col-xs-12 form-group radios">
                      {order.recebedor.tipo === ReceiverType.Buyer && (
                        <label className="radio-inline text">
                          <input
                            type="radio"
                            name="recebedor"
                            value={ReceiverType.Buyer}
                            checked
                            disabled
                          />{' '}
                          O próprio comprador
                        </label>
                      )}
                      {order.recebedor.tipo === ReceiverType.Other && (
                        <label className="radio-inline text">
                          <input
                            type="radio"
                            name="recebedor"
                            value={ReceiverType.Other}
                            checked
                            disabled
                          />{' '}
                          Outra pessoa
                        </label>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                      <label className="text">Nome</label>
                      <input
                        type="text"
                        name="nome"
                        disabled
                        value={order.recebedor.nome}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                      <label className="text">CPF</label>
                      <InputMask
                        disabled
                        type="text"
                        mask="999.999.999-99"
                        alwaysShowMask={false}
                        maskChar=""
                        name="cpf"
                        value={order.recebedor.cpf}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                      <label className="text">Data de Nascimento</label>
                      <input
                        type="text"
                        name="bithday"
                        disabled
                        value={order.recebedor.dataNascimento}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                      <label className="text">Sexo</label>
                      <select name="genero" disabled>
                        <option value={order.recebedor.sexo}>
                          {order.recebedor.sexo}
                        </option>
                      </select>
                    </div>
                    {order.recebedor.tipo === ReceiverType.Other && (
                      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12 form-group">
                        <label className="text">Grau Parentesco</label>
                        <input
                          type="text"
                          name="grau"
                          disabled
                          value={order.recebedor.grauParentesco}
                        />
                      </div>
                    )}
                    <div className="col-xs-12 form-group">
                      {!order.recebedor.cpf && (
                        <div className="checkbox">
                          <label className="text">
                            <input
                              type="checkbox"
                              name="naoPossuiCpf"
                              disabled
                              checked
                              value={true}
                            />{' '}
                            Recebedor não possui CPF
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="btn-container text-center">
              <button
                type="submit"
                className="btn btn-submit checkout"
                onClick={this.redirectToUserOrders}
              >
                VER MEUS PEDIDOS
                <img
                  src="/img/icons/arrow-send.png"
                  srcSet="/img/icons/arrow-send@2x.png 2x, /img/icons/arrow-send@3x.png 3x"
                  className="pull-right"
                />
              </button>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = ({ receipt }) => {
  return {
    ...receipt,
  };
};

export default connect(
  mapStateToProps,
  {
    redirectToBasket,
    fillReceiptFromOrder,
    redirectToUserOrders,
  }
)(Receipt);
