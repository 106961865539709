import { push } from 'connected-react-router';
import Alert from 'react-s-alert';
import ActionTypes from './ActionTypes';
import {
  AuthenticationService,
  NotificationService,
  ProductService,
  CategoryService,
} from '../services';
import { Messages } from '../constants';
import { BasketService } from '../services/BasketService';
import { calculateAge } from '../utils';

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOGIN_USER });
    const user = await AuthenticationService.login(email, password);
    const basket = await BasketService.mergeBasket();

    const dataLayer = {
      event: 'login_success',
      userId: user.id,
      genero: user.sexo,
      idade: calculateAge(new Date(user.dataNascimento)),
    };
    window.dataLayer.push(dataLayer);

    dispatch({ type: ActionTypes.FILL_BASKET, payload: basket });
    dispatch({
      type: ActionTypes.LOGIN_USER_SUCCESS,
      payload: user,
    });
    dispatch(push(basket && basket.produtos.length > 0 ? '/pedido' : ''));
  } catch (e) {
    dispatch({ type: ActionTypes.LOGIN_USER_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const requestPasswordRecovery = (email) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REQUEST_PASSWORD_RECOVERY });
    await AuthenticationService.sendRecoveryPasswordEmail(email);
    dispatch({ type: ActionTypes.REQUEST_PASSWORD_RECOVERY_SUCCESS });
    Alert.success(Messages.PasswordRecoveryEmailSentMessage);
    dispatch(push('esqueci-minha-senha/codigo'));
  } catch (e) {
    dispatch({ type: ActionTypes.REQUEST_PASSWORD_RECOVERY_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const validatePasswordRecoveryCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE });
    await AuthenticationService.validateRecoveryPasswordCode(code);
    dispatch({ type: ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE_SUCCESS });
    Alert.success(Messages.PasswordRecoveryCodeSuccessValidatedMessage);
    dispatch(push(`/esqueci-minha-senha/codigo/${code}`));
  } catch (e) {
    dispatch({ type: ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const recoverPassword = (code, password, confirmation) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.RECOVER_PASSWORD });
    await AuthenticationService.recoverPassword(code, password, confirmation);
    dispatch({ type: ActionTypes.RECOVER_PASSWORD_SUCCESS });
    Alert.success(Messages.PasswordSuccessRecoveredMessage);
    dispatch(push('/login'));
  } catch (e) {
    dispatch({ type: ActionTypes.RECOVER_PASSWORD_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const logout = () => async (dispatch) => {
  try {
    AuthenticationService.logout();

    let categoryId = null;
    const category = await CategoryService.getPrincipal();
    if (category && category.length > 0) categoryId = category[0].id;

    dispatch({ type: ActionTypes.LOGOUT_USER_SUCCESS });
    dispatch({
      type: ActionTypes.SEARCH_PRODUCTS,
      payload: null,
    });
    const products = await ProductService.search(
      1,
      process.env.REACT_APP_PAGE_SIZE,
      null,
      categoryId
    );
    dispatch({
      type: ActionTypes.SEARCH_PRODUCTS_SUCCESS,
      payload: products,
    });
    dispatch(push('/'));
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_PRODUCTS_FAIL });
  }
};

export const verifyIfUserIsLogged = () => (dispatch) => {
  const email = AuthenticationService.getAuthEmail();
  updateAuthEmail(email)(dispatch);
  const user = AuthenticationService.userIsLogged()
    ? AuthenticationService.getLoggedUser()
    : null;
  dispatch({
    type: ActionTypes.VERIFY_LOGGED_USER_SUCCESS,
    payload: user,
  });
};

export const updateAuthEmail = (email) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_AUTH_EMAIL,
    payload: email || '',
  });
};

export const updateLoginField = ({ prop, value }) => ({
  type: ActionTypes.UPDATE_LOGIN_FIELD,
  payload: { prop, value },
});

export const updatePasswordRecoveryField = ({ prop, value }) => ({
  type: ActionTypes.UPDATE_PASSWORD_RECOVERY_FIELD,
  payload: { prop, value },
});
