import { push } from 'connected-react-router';
import Alert from 'react-s-alert';
import ActionTypes from './ActionTypes';
import { NotificationService, UserService, AuthenticationService, LocalizationService } from '../services';
import { Messages } from '../constants';
import { BasketService } from '../services';

export const updateUserRegisterField = ({ prop, value }) => (
  {
    type: ActionTypes.UPDATE_USER_REGISTER_FIELD,
    payload: { prop, value }
  }
);

export const registerUser = (userData) => async dispatch => {
  try {
    dispatch({ type: ActionTypes.REGISTER_USER });
    const savedBasket = BasketService.getBasketFromStorage();
    const params = UserService.prepareUserDataToSave(userData);

    await UserService.create(params);

    const user = await AuthenticationService.login(userData.email, userData.password);
    const basket = await BasketService.mergeBasket();
    
    if (basket) dispatch({ type: ActionTypes.FILL_BASKET, payload: basket });

    dispatch({
      type: ActionTypes.LOGIN_USER_SUCCESS,
      payload: user
    });
    dispatch({ type: ActionTypes.REGISTER_USER_SUCCESS });
    Alert.success(Messages.UserSuccessRegisteredMessage);

    dispatch(push(savedBasket && savedBasket.produtos.length > 0 ? '/pedido' : ''));

  } catch (e) {
    dispatch({ type: ActionTypes.REGISTER_USER_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const uploadProfilePicture = (file) => async dispatch => {
  try {
    dispatch({ type: ActionTypes.UPLOAD_USER_PICTURE });
    const uploadedFile = file 
      ? await UserService.uploadProfilePicture(file)
      : null;
    dispatch({ 
      type: ActionTypes.UPLOAD_USER_PICTURE_SUCCESS,
      payload: uploadedFile
    });
  } catch (e) {
    dispatch({ type: ActionTypes.UPLOAD_USER_PICTURE_FAIL });
  }
};

export const resetUserRegisterForm = () => ({ type: ActionTypes.RESET_REGISTER_FORM });

export const fillRegisterAddress = (cep) => async dispatch => {
  try {
    dispatch({ type: ActionTypes.FILL_REGISTER_ADDRESS });
    const localization = await LocalizationService.search(cep);
    dispatch({ type: ActionTypes.FILL_REGISTER_ADDRESS_SUCCESS, payload: localization });
  } catch (e) {
    NotificationService.showApiResponseErrorAlert(e);
    dispatch({ type: ActionTypes.FILL_REGISTER_ADDRESS_FAIL });
  }
};

export const clearRegisterAddres = () => ({ type: ActionTypes.FILL_REGISTER_ADDRESS_FAIL });
