import validate from 'validate.js';
import passwordValidator from './passwordValidator';
import cpfValidator from './cpfValidator';
import brDateValidator from './brDateValidator';

export const registerCustomValidators = () => {
  validate.validators.password = passwordValidator;
  validate.validators.cpf = cpfValidator;
  validate.validators.brDate = brDateValidator;
};
