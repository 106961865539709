
import WebApi from './WebApi';
import { StorageKeys, CacheKeys } from '../constants';
import { CacheService } from './CacheService';

export class AuthenticationService {

  static async login(email, password) {
    const response = await WebApi.post('/autenticacao/token', { email, senha: password });
    return await AuthenticationService.handleEmailLoginResponse(response);
  }

  static async sendRecoveryPasswordEmail(email) {
    await WebApi.post('/autenticacao/esqueci-minha-senha', { email });
  }

  static async validateRecoveryPasswordCode(code) {
    await WebApi.post(`/autenticacao/recuperacao-senha/${code}/validar`);
  }

  static async recoverPassword(code, password, confirmation) {
    await WebApi.post(`/autenticacao/recuperacao-senha/${code}`, 
      { senha: password, confirmacaoSenha: confirmation });
  }

  static getLoggedUser() {
    const storageLoggedUser = localStorage.getItem(StorageKeys.LoggedUser);
    if (!storageLoggedUser) return null;

    return JSON.parse(storageLoggedUser);
  }

  static getAuthEmail() {
    return localStorage.getItem(StorageKeys.AuthEmail);
  }  

  static getAuthToken() {
    return localStorage.getItem(StorageKeys.AuthToken);
  }

  static userIsLogged() {
    const tokenIsValid = AuthenticationService.tokenIsValid();
    if (!tokenIsValid) {
      AuthenticationService.removeAuthFromStorage();
      return false;
    }
    return true;
  }

  static logout() {
    AuthenticationService.removeAuthFromStorage();
    CacheService.remove(CacheKeys.Basket);
  }

  static removeAuthFromStorage() {
    localStorage.removeItem(StorageKeys.LoggedUser);
    localStorage.removeItem(StorageKeys.AuthTokenExpiresIn);
    localStorage.removeItem(StorageKeys.AuthToken);
  }

  static updateLoggedUser(user) {
    localStorage.setItem(StorageKeys.LoggedUser, JSON.stringify(user));
  }

  static updateAuthEmail(user) {
    localStorage.setItem(StorageKeys.AuthEmail, user.email);
  }

  static tokenIsValid() {
    const tokenExpireDate = AuthenticationService.getTokenExpiresIn();
    return tokenExpireDate && tokenExpireDate > new Date();
  }

  static getTokenExpiresIn() {
    const expireDateString = localStorage.getItem(StorageKeys.AuthTokenExpiresIn);
    if (!expireDateString) return null;

    return new Date(expireDateString);
  }

  static async handleLoginResponse(response) {
    const { token, expiraEm } = response.data;
    AuthenticationService.storeAuthToken(token, expiraEm);
    return await AuthenticationService.getUserInfo();
  }

  static async handleEmailLoginResponse(response) {
    const user = await AuthenticationService.handleLoginResponse(response);
    AuthenticationService.updateAuthEmail(user);
    return user;
  }

  static async getUserInfo() {
    const response = await WebApi.get('autenticacao/info');
    AuthenticationService.updateLoggedUser(response.data);
    return response.data;
  }

  static storeAuthToken(token, expiresIn) {    
    const tokenExpireDate = new Date();
    tokenExpireDate.setSeconds(tokenExpireDate.getSeconds() + expiresIn);
    localStorage.setItem(StorageKeys.AuthToken, token);
    localStorage.setItem(StorageKeys.AuthTokenExpiresIn, tokenExpireDate.toISOString());
  }
}
