import Alert from 'react-s-alert';
import { push } from 'connected-react-router';
import ActionTypes from './ActionTypes';
import { Messages, OrderStatus } from '../constants';
import {
  NotificationService,
  BackOfficeService,
  ProductService,
  CategoryService,
  UnityService,
} from '../services';

export const searchOrders = ({ pageNumber, pageSize, cpf, status }) => async (
  dispatch
) => {
  try {
    dispatch({
      type: ActionTypes.SEARCH_BACKOFFICE_ORDERS,
      payload: { pageNumber, pageSize, cpf, status },
    });
    const orders = await BackOfficeService.search({
      pageNumber,
      pageSize,
      cpf: cpf.replace(/[^\d]/g, ''),
      status,
    });
    dispatch({
      type: ActionTypes.SEARCH_BACKOFFICE_ORDERS_SUCCESS,
      payload: orders,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_BACKOFFICE_ORDERS_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateOrderStatus = (order, status, uploadedFile) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS });
    const idNotaFiscal = (uploadedFile || {}).id;
    await BackOfficeService.updateStatus(order.id, status, idNotaFiscal);
    dispatch({
      type: ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS_SUCCESS,
      payload: { order, status, idNotaFiscal },
    });
    Alert.success(Messages.OrderStatusUpdatedSuccessMessage);
  } catch (e) {
    dispatch({ type: ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateServiceOrder = (order, serviceOrder) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER });
    await BackOfficeService.updateServiceOrder(order.id, serviceOrder);
    dispatch({
      type: ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_SUCCESS,
      payload: { order, serviceOrder },
    });
    Alert.success(Messages.OrderServiceOrderUpdatedSuccessMessage);
  } catch (e) {
    dispatch({ type: ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateBackOfficeField = ({ prop, value, orderId }) => async (
  dispatch
) => {
  if (value === OrderStatus.Reversed) {
    const dataLayer = {
      ecommerce: {
        refund: {
          actionField: { id: orderId },
        },
      },
    };

    window.dataLayer.push(dataLayer);
  }

  dispatch({
    type: ActionTypes.UPDATE_BACKOFFICE_FIELD,
    payload: { prop, value },
  });
};

export const cancelOrderEdition = () => ({
  type: ActionTypes.CANCEL_BACKOFFICE_ORDER_EDITION,
});

export const editOrderStatus = (order) => ({
  type: ActionTypes.EDIT_BACKOFFICE_ORDER_STATUS,
  payload: order,
});

export const editServiceOrder = (order) => ({
  type: ActionTypes.EDIT_BACKOFFICE_ORDER_SERVICE_ORDER,
  payload: order,
});

export const clealRedirectUrl = () => ({
  type: ActionTypes.CLEAR_REDIRECT_URL,
});

export const uploadInvoice = (file) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPLOAD_INVOICE });
    const uploadedFile = file
      ? await BackOfficeService.uploadInvoice(file)
      : null;
    dispatch({
      type: ActionTypes.UPLOAD_INVOICE_SUCCESS,
      payload: uploadedFile,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.UPLOAD_INVOICE_FAIL });
  }
};

export const exportOrders = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.EXPORT_ORDERS });
    const file = await BackOfficeService.exportOrders();
    dispatch({
      type: ActionTypes.EXPORT_ORDERS_SUCCESS,
      payload: file,
    });
    var blob = new Blob([file], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    var csvURL = window.URL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'PedidosEcommerce.xlsx');
    tempLink.click();
  } catch (e) {
    dispatch({ type: ActionTypes.EXPORT_ORDERS_FAIL });
  }
};

export const searchProducts = ({
  pageNumber,
  pageSize,
  name,
  active,
}) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.SEARCH_BACKOFFICE_PRODUCTS,
      payload: { pageNumber, pageSize, name, active },
    });
    const products = await ProductService.searchBackOffice(
      pageNumber,
      pageSize,
      name,
      null,
      active
    );
    dispatch({
      type: ActionTypes.SEARCH_BACKOFFICE_PRODUCTS_SUCCESS,
      payload: products,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_BACKOFFICE_PRODUCTS_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateBackOfficeProductField = ({ prop, value }) => ({
  type: ActionTypes.UPDATE_BACKOFFICE_PRODUCT_FIELD,
  payload: { prop, value },
});

export const inactiveProduct = (product) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.INACTIVE_BACKOFFICE_PRODUCT,
      payload: product.id,
    });
    await ProductService.inactive(product.id);
    dispatch({
      type: ActionTypes.INACTIVE_BACKOFFICE_PRODUCT_SUCCESS,
      payload: product.id,
    });
    Alert.success(`Produto ${product.nome} foi INATIVADO com sucesso!`);
  } catch (e) {
    dispatch({ type: ActionTypes.INACTIVE_BACKOFFICE_PRODUCT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const fillUnitsUfs = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.FILL_UNITS_UFS });
    const ufs = await UnityService.listUfs();
    dispatch({
      type: ActionTypes.FILL_UNITS_UFS_SUCCESS,
      payload: ufs,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.FILL_UNITS_UFS_SUCCESS_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const activeProduct = (product) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.ACTIVE_BACKOFFICE_PRODUCT,
      payload: product.id,
    });
    await ProductService.active(product.id);
    dispatch({
      type: ActionTypes.ACTIVE_BACKOFFICE_PRODUCT_SUCCESS,
      payload: product.id,
    });
    Alert.success(`Produto ${product.nome} foi ATIVADO com sucesso!`);
  } catch (e) {
    dispatch({ type: ActionTypes.ACTIVE_BACKOFFICE_PRODUCT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const redirectToProductManager = (product) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TYPE_ACTION_MANAGER_PRODUCT,
    payload: product ? product.id : 0,
  });
  dispatch(push('produtos/gerenciar'));
};

export const fillProductToEdit = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_PRODUCT_TO_EDIT });
    const fullProduct = await ProductService.get(id);
    dispatch({ type: ActionTypes.GET_PRODUCT_TO_EDIT_SUCCESS });
    dispatch({ type: ActionTypes.FILL_PRODUCT_TO_EDIT, payload: fullProduct });
  } catch (e) {
    dispatch({ type: ActionTypes.GET_PRODUCT_TO_EDIT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateProduct = (productData) => async (dispatch) => {
  try {
    const product = productData.productEdit;
    dispatch({ type: ActionTypes.UPDATE_PRODUCT });
    await ProductService.update(product, productData.uploadedFile);
    dispatch({ type: ActionTypes.UPDATE_PRODUCT_SUCCESS, payload: product });
    Alert.success(`Produto ${product.nome} editado com sucesso!`);
  } catch (e) {
    dispatch({ type: ActionTypes.UPDATE_PRODUCT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const createProduct = (productData) => async (dispatch) => {
  try {
    const product = productData.productEdit;
    dispatch({ type: ActionTypes.CREATE_PRODUCT });
    const createdProduct = await ProductService.create(
      product,
      productData.uploadedFile
    );
    dispatch({
      type: ActionTypes.CREATE_PRODUCT_SUCCESS,
      payload: createdProduct,
    });
    Alert.success(`Produto ${product.nome} cadastrado com sucesso!`);
  } catch (e) {
    dispatch({ type: ActionTypes.CREATE_PRODUCT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateProductField = ({ prop, value }) => ({
  type: ActionTypes.UPDATE_BACKOFFICE_PRODUCT_EDIT_FIELD,
  payload: { prop, value },
});

export const resetFormProductEdit = () => ({
  type: ActionTypes.RESET_FORM_PRODUCT_EDIT,
});

export const redirectToProductList = () => (dispatch) => {
  dispatch(push('/backoffice/produtos'));
};

export const uploadProductPicture = (file) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPLOAD_PRODUCT_PICTURE });
    const uploadedFile = await ProductService.uploadProductPicture(file);
    dispatch({
      type: ActionTypes.UPLOAD_PRODUCT_PICTURE_SUCCESS,
      payload: uploadedFile,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.UPLOAD_PRODUCT_PICTURE_FAIL });
  }
};

export const fillCategories = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.FILL_CATEGORIES });
    const categories = await CategoryService.list(false);
    dispatch({
      type: ActionTypes.FILL_CATEGORIES_SUCCESS,
      payload: categories,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.FILL_CATEGORIES_FAIL });
  }
};

export const checkedCategory = (category) => ({
  type: ActionTypes.CHECKED_CATEGORY,
  payload: category,
});

export const uncheckedCategory = (category) => ({
  type: ActionTypes.UNCHECKED_CATEGORY,
  payload: category,
});

export const checkedStok = (state) => ({
  type: ActionTypes.CHECKED_STOK,
  payload: state,
});

export const uncheckedStok = (state) => ({
  type: ActionTypes.UNCHECKED_STOK,
  payload: state,
});

export const searchCategories = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SEARCH_CATEGORIES });
    const categories = await CategoryService.listWithProducts();
    dispatch({
      type: ActionTypes.SEARCH_CATEGORIES_SUCCESS,
      payload: categories,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_CATEGORIES_FAIL });
  }
};

export const getCategoryProducts = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_CATEGORY_PRODUCTS });
    const categoryProducts = await CategoryService.getCategoryProducts(
      categoryId
    );
    dispatch({
      type: ActionTypes.GET_CATEGORY_PRODUCTS_SUCCESS,
      payload: categoryProducts,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.GET_CATEGORY_PRODUCTS_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const sortProductsChanged = (products) => ({
  type: ActionTypes.SORT_PRODUCTS_CHANGED,
  payload: products,
});

export const saveCategorySort = ({ category, categoryProducts }) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.SAVE_CATEGORY_PRODUCTS_SORT });
    await CategoryService.saveSort(category.id, categoryProducts);
    dispatch({ type: ActionTypes.SAVE_CATEGORY_PRODUCTS_SORT_SUCCESS });
    Alert.success('Ordenação atualizada com sucesso!');
  } catch (e) {
    dispatch({ type: ActionTypes.SAVE_CATEGORY_PRODUCTS_SORT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const getCategoryDetail = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_CATEGORY_DETAILS });
    const category = await CategoryService.get(categoryId);
    dispatch({
      type: ActionTypes.GET_CATEGORY_DETAILS_SUCCESS,
      payload: category,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.GET_CATEGORY_DETAILS_FAIL });
  }
};
