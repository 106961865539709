import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Helmet } from 'react-helmet';
import { Header, Footer } from '../../components';
import { getProduct } from '../../actions/ProductDetailActions';
import { addProductToBasket } from '../../actions/BasketActions';

import './style.scss';

class ProductDetail extends Component {
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getProduct(id, true);
  };

  addToBasket = () => {
    if (this.props.comRedirecionamento) {
      var win = window.open(this.props.urlRedirecionamento, '_blank');
      win.focus();
    } else this.props.addProductToBasket(this.props);
  };

  getProductImage = () => {
    const { imagens } = this.props;
    if (!imagens) return null;
    return imagens.find((i) => !i.fotoCapa) || imagens.find((i) => i.fotoCapa);
  };

  render = () => (
    <div className="product-detail">
      <Helmet>
        <title>{this.props.nome}</title>
        <meta
          name="description"
          content={`${this.props.nome} - ${this.props.indicacao}`}
        />
      </Helmet>
      <Header />
      <div className="gray-container">
        <div className="product-header">
          <div className="container">
            {(this.props.imagens || []).length === 0 && (
              <img src="/img/vacina.png" className="product-img" />
            )}
            {(this.props.imagens || []).length > 0 && (
              <img
                src={`${process.env.REACT_APP_API_URL}/arquivos/${
                  this.getProductImage().id
                }`}
                className="product-img"
              />
            )}
            <div className="product-info pull-left">
              <h1 className="title">{this.props.nome}</h1>
              <p className="text price">
                <NumberFormat
                  value={this.props.preco}
                  displayType={'text'}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  prefix={'R$'}
                  fixedDecimalScale
                  decimalScale={2}
                />
              </p>
            </div>
            <div className="btn-container">
              {this.props.idArquivoBula && (
                <Link
                  target="_blank"
                  to={`${process.env.REACT_APP_API_URL}/arquivos/${this.props.idArquivoBula}`}
                  className="btn pdf"
                >
                  <img
                    src="/img/icons/pdf.png"
                    srcSet="/img/icons/pdf@2x.png 2x, /img/icons/pdf@3x.png 3x"
                  />
                </Link>
              )}

              <button
                className={`btn basket ${
                  !this.props.idArquivoBula ? 'without-pdf' : ''
                }`}
                onClick={this.addToBasket}
              >
                <img
                  src="/img/icons/cart-white.png"
                  srcSet="/img/icons/cart-white@2x.png 2x, /img/icons/cart-white@3x.png 3x"
                />
              </button>
            </div>
          </div>
        </div>

        <div className="container details">
          <div className="title-container">
            <img
              src="/img/icons/heart.png"
              srcSet="/img/icons/heart@2x.png 2x, /img/icons/heart@3x.png 3x"
              className="pull-left"
            />
            <h2 className="title">Informações</h2>
          </div>
          <div className="content">
            {this.props.indicacao && (
              <div>
                <h3 className="title secondary">Indicação</h3>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: this.props.indicacao }}
                ></p>
              </div>
            )}
            {this.props.descricao && (
              <div>
                <h3 className="title secondary">Descrição</h3>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: this.props.descricao }}
                ></p>
              </div>
            )}
            {this.props.exames && (
              <div>
                <h3 className="title secondary">Exames</h3>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: this.props.exames }}
                ></p>
              </div>
            )}
            {this.props.esquemaAplicacao && (
              <div>
                <h3 className="title secondary">Esquema de aplicação</h3>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.esquemaAplicacao,
                  }}
                ></p>
              </div>
            )}
            {this.props.eventosAdversos && (
              <div>
                <h3 className="title secondary">Eventos adversos</h3>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.eventosAdversos,
                  }}
                ></p>
              </div>
            )}
            {this.props.eventosAdversos && (
              <div>
                <h3 className="title secondary">Contraindicações</h3>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.contraindicacoes,
                  }}
                ></p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
const mapStateToProps = ({ productDetail }) => {
  return {
    ...productDetail.product,
  };
};

export default connect(mapStateToProps, { getProduct, addProductToBasket })(
  ProductDetail
);
