import moment from 'moment';
import WebApi from './WebApi';
import { AuthenticationService, FileService } from '.';
import { UploadDestinations } from '../constants';

export class UserService {

  static async update(user) {
    const response = await WebApi.put('/usuarios', user);
    await AuthenticationService.updateLoggedUser(response.data);
    return response.data;
  }

  static async create(user) {
    const response = await WebApi.post('/usuarios', user);
    return response.data;
  }

  static async uploadProfilePicture(file) {
    const data = new FormData();
    data.append('EnderecoDestino', `${UploadDestinations.UserProfile}`);
    data.append('Arquivo', file, `perfil-${new Date().getTime()}.jpg`);
    return await FileService.upload(data); 
  }

  static prepareUserDataToSave(userData) {
    const { cpf, cep, email } = userData;
    let params = {
      nome: userData.name,
      cpf: cpf.replace(/[^\d]/g, ''),
      rg: userData.rg,
      email,
      nomeMae: userData.motherName,
      telefone: userData.phone,
      celular: userData.cellPhone,
      sexo: userData.genre,
      idFotoPerfil: (userData.uploadedFile || {}).id,
      dataNascimento: userData.birthday ? 
        moment(userData.birthday, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
        : null, 
      endereco: {
        cep: cep.replace(/[^\d]/g, ''),
        estado: userData.state,
        cidade: userData.city,
        bairro: userData.neighborhood,
        logradouro: userData.address,
        numero: userData.number,
        complemento: userData.complement
      }
    };
    if (userData.password) params = { ...params, senha: userData.password, confirmacaoSenha: userData.confirmation };
    return params;
  }
}
