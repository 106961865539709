import ActionTypes from '../actions/ActionTypes';

const initialState = {
  orders: [],
  cpf: '',
  status: '',
  pageNumber: 1,

  serviceOrderOpened: false,
  statusOpened: false,

  selectedOrder: {},
  serviceOrder: '',
  orderStatus: '',

  uploadedFile: {},
  fileName: ''
};

const updateOrderStatus = (orders, { order, status, idNotaFiscal }) => {
  for (let i = 0; i < orders.length; i += 1) {
    if (orders[i].id === order.id)
      orders[i] = { ...orders[i], status, idNotaFiscal };
  }
  return [ ...orders ];
};

const updateServiceOrder = (orders, { order, serviceOrder }) => {
  for (let i = 0; i < orders.length; i += 1) {
    if (orders[i].id === order.id)
      orders[i] = { ...orders[i], ordemServico: serviceOrder };
  }
  return [ ...orders ];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_BACKOFFICE_ORDERS: 
      return { ...state, pageNumber: action.payload.pageNumber };
    case ActionTypes.SEARCH_BACKOFFICE_ORDERS_SUCCESS:
      return { ...state, orders: action.payload };
    case ActionTypes.UPLOAD_INVOICE:
      return { ...state, uploadedFile: null, fileName: '' };
    case ActionTypes.UPLOAD_INVOICE_SUCCESS:
      return { ...state, uploadedFile: action.payload, fileName: action.payload.nome };
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS_SUCCESS:
      return { 
        ...state, 
        orders: updateOrderStatus(state.orders, action.payload),
        selectedOrder: {},
        orderStatus: '',
        serviceOrder: ''
      };
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_SUCCESS:
      return { 
        ...state, 
        orders: updateServiceOrder(state.orders, action.payload),
        selectedOrder: {},
        orderStatus: '',
        serviceOrder: ''
      };
    case ActionTypes.UPDATE_BACKOFFICE_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    case ActionTypes.CANCEL_BACKOFFICE_ORDER_EDITION: 
      return { ...initialState, orders: state.orders, cpf: state.cpf };
      case ActionTypes.EDIT_BACKOFFICE_ORDER_STATUS:
        return { 
          ...state, 
          selectedOrder: action.payload, 
          orderStatus: action.payload.status || '',
          statusOpened: true, 
          serviceOrderOpened: false,
          uploadedFile: null, 
          fileName: '' 
        };
      case ActionTypes.EDIT_BACKOFFICE_ORDER_SERVICE_ORDER:
        return { 
          ...state, 
          selectedOrder: action.payload, 
          serviceOrder: action.payload.ordemServico || '',
          statusOpened: false, 
          serviceOrderOpened: true
        };
    default:
      return state;
  }
};
