import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../components';

import * as UserRegisterActions from '../../actions/UserRegisterActions';
import { updateUserProfile, fillUserRegisterFromLoggedUser } from '../../actions/UserPageActions';
import { ValidationService } from '../../services';
import validation from './validation';
import './style.scss';

class Register extends Component {

  state = {
    selectedFile: null,
    uploadedFile: null
  };

  componentDidMount = () => {
    const { loggedUser } = this.props;
    const { pathname } = this.props.location;
    if (pathname === '/meus-dados' && loggedUser)
      this.props.fillUserRegisterFromLoggedUser(loggedUser);
    else if (pathname === '/cadastro')
      this.props.resetUserRegisterForm();
  };

  formIsValid = () => ValidationService.validate(this.props, validation);

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updateUserRegisterField({ prop, value });
  };

  handleCheckboxChange = (prop, event) => {
    const { checked } = event.target;
    this.props.updateUserRegisterField({ prop, value: checked });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.formIsValid()) return;
    if (!this.props.id) this.props.registerUser(this.props);
    else this.props.updateUserProfile(this.props);
  };

  fileChangedHandler = (event) => {
    const { files } = event.target;
    const file = files.length > 0 ? files[0] : null;
    this.props.uploadProfilePicture(file);
  };

  handleFillAdrress = event => {
    this.props.fillRegisterAddress(event.target.value);
  }

  handleClearAddres = () => {
    this.props.clearRegisterAddres();
  }

  render = () => (
    <div className="register">
      <Header />
      <NavigationBar title={this.props.id ? 'Meus Dados' : 'Cadastro'} breadcrumb={[
        { to: '/', title: 'Home' },
        { to: this.props.id ? '/meus-dados' : '/cadastro', title: this.props.id ? 'Meus Dados' : 'Cadastro' },
      ]}/>
      <div className="gray-container">
        <div className="container">
          {!this.props.id && <p className="text text-center page-description">Preencha o formulário com seus dados para criar uma conta em nosso site.</p>}

          {this.props.id && <p className="text text-center page-description">Preencha o formulário com seus dados para atualizar as suas informações.</p>}

          <div className="form-container">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
              <div className="col-xs-12 text-center">
                <label htmlFor="file" className="uploader">
                  {!this.props.uploadedFile &&
                    <img src="/img/icons/upload.png" srcSet="/img/icons/upload@2x.png 2x, /img/icons/upload@3x.png 3x" />
                  }
                  {this.props.uploadedFile && 
                    <div className="user-picture">
                      <img src={`${process.env.REACT_APP_API_URL}/arquivos/${this.props.uploadedFile.id}`} className="avatar"/>
                      <span className="camera text-center">
                        <img src="/img/icons/camera.png" srcSet="/img/icons/camera@2x.png 2x, /img/icons/camera@3x.png 3x" />
                      </span>
                    </div>
                  }
                </label>
                <input id="file" type="file" className="file-input" onChange={this.fileChangedHandler}></input>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Nome Completo *</label>
                <input type="text" name="name" value={this.props.name} onChange={e => this.handleChange('name', e)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">CPF *</label>
                <InputMask type="text" placeholder="999.999.999-99" mask="999.999.999-99" alwaysShowMask={false} maskChar="" name="cpf" value={this.props.cpf} onChange={e => this.handleChange('cpf', e)} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">RG *</label>
                <input type="text" name="rg" maxLength="11" value={this.props.rg} onChange={e => this.handleChange('rg', e)} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Data de Nascimento *</label>
                <InputMask type="text" placeholder="DD/MM/AAAA" mask="99/99/9999" alwaysShowMask={false} maskChar="" name="birthday" value={this.props.birthday} onChange={e => this.handleChange('birthday', e)}/>
              </div>
              <div className="col-xs-12 form-group">
                <label className="text primary">Nome da Mãe *</label>
                <input type="text" name="motherName" value={this.props.motherName} onChange={e => this.handleChange('motherName', e)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">E-mail *</label>
                <input type="text" placeholder="seuemail@email.com" name="email" value={this.props.email} onChange={e => this.handleChange('email', e)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label className="text primary">Sexo *</label>
                <select name="genero" value={this.props.genre} onChange={e => this.handleChange('genre', e)}>
                  <option value="">Selecione</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">CEP *</label>
                <InputMask type="text" placeholder="99999-9999" mask="99999-9999" alwaysShowMask={false} maskChar="" name="cep" value={this.props.cep} onChange={e => { this.handleChange('cep', e); this.handleClearAddres(); }} onBlur={e => this.handleFillAdrress(e)} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Endereço de Cobrança *</label>
                <input type="text" name="address" value={this.props.address} onChange={e => this.handleChange('address', e)} disabled={!this.props.cepIsRural} className={`${!this.props.cepIsRural ? 'cursor-disabled' : ''} `}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Número *</label>
                <InputMask type="text" mask="999999" alwaysShowMask={false} maskChar="" name="number" value={this.props.number} onChange={e => this.handleChange('number', e)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Complemento</label>
                <input type="text" name="complement" value={this.props.complement} onChange={e => this.handleChange('complement', e)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Bairro *</label>
                <input type="text" name="neighborhood" value={this.props.neighborhood} onChange={e => this.handleChange('neighborhood', e)} disabled={!this.props.cepIsRural} className={`${!this.props.cepIsRural ? 'cursor-disabled' : ''} `} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Cidade *</label>
                <input type="text" name="city" value={this.props.city} onChange={e => this.handleChange('city', e)} disabled={true} className='cursor-disabled' />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Estado *</label>
                <select name="estado" value={this.props.state} onChange={ev => this.handleChange('state', ev)} disabled={true} className='cursor-disabled'>
                  <option value="AC">AC</option> 
                  <option value="AL">AL</option> 
                  <option value="AP">AP</option> 
                  <option value="AM">AM</option> 
                  <option value="BA">BA</option> 
                  <option value="CE">CE</option> 
                  <option value="DF">DF</option> 
                  <option value="ES">ES</option> 
                  <option value="GO">GO</option> 
                  <option value="MA">MA</option> 
                  <option value="MT">MT</option> 
                  <option value="MS">MS</option> 
                  <option value="MG">MG</option> 
                  <option value="PA">PA</option> 
                  <option value="PB">PB</option> 
                  <option value="PR">PR</option> 
                  <option value="PE">PE</option> 
                  <option value="PI">PI</option> 
                  <option value="RJ">RJ</option> 
                  <option value="RN">RN</option> 
                  <option value="RS">RS</option> 
                  <option value="RO">RO</option> 
                  <option value="RR">RR</option> 
                  <option value="SC">SC</option> 
                  <option value="SP">SP</option> 
                  <option value="SE">SE</option> 
                  <option value="TO">TO</option> 
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Celular *</label>
                <InputMask type="text" placeholder="(xx) 99999-9999" mask="(99) 99999-9999" alwaysShowMask={false} maskChar="" name="phone" value={this.props.cellPhone} onChange={e => this.handleChange('cellPhone', e)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Telefone</label>
                <InputMask type="text" placeholder="(xx) 99999999" mask="(99) 999999999" alwaysShowMask={false} maskChar="" name="cellPhone" value={this.props.phone} onChange={e => this.handleChange('phone', e)}/>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Senha {!this.props.id ? '*' : ''}</label>
                <input type="password" name="password" value={this.props.password} onChange={e => this.handleChange('password', e)}/>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Confirmação {!this.props.id ? '*' : ''}</label>
                <input type="password" name="confirmation" value={this.props.confirmation} onChange={e => this.handleChange('confirmation', e)}/>
              </div>
              <div className="col-xs-12 form-group">
                <p className="text">* Campos de preenchimento obrigatório.</p>
              </div>
              <div className="col-xs-12 text-center btn-container">
                <button type="submit" className="btn btn-submit">{this.props.id ? 'ATUALIZAR MEUS DADOS' : 'CRIAR UMA CONTA'}</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ userRegister, auth }) => {
  const { loggedUser } = auth;
  return { 
    ...userRegister,
    loggedUser
  };
};

export default connect(
  mapStateToProps,
  { 
    ...UserRegisterActions, 
    updateUserProfile,
    fillUserRegisterFromLoggedUser
  }
)(Register);
