import ActionTypes from '../actions/ActionTypes';

const initialState = {
  categories: [],
  selectedCategory: null,
  selectedCategoryId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CATEGORY:
      return { ...state, selectedCategoryId: action.payload };
    case ActionTypes.GET_CATEGORY_SUCCESS:
      return { ...state, selectedCategory: action.payload };
    case ActionTypes.LIST_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload };
    case ActionTypes.UPDATE_CATEGORY_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    default:
      return state;
  }
};
