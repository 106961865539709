import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../components';
import { updatePasswordRecoveryField, recoverPassword } from '../../actions/AuthActions';
import { ValidationService } from '../../services';
import validation from './validation';
import './style.scss';

class PasswordRecovery extends Component {
  
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { match: { params: { code } } } = this.props;
    this.props.updatePasswordRecoveryField({ prop: 'code', value: code });
  };

  formIsValid = () => ValidationService.validate(this.props, validation);

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updatePasswordRecoveryField({ prop, value });
  };

  handleSubmit = (event) => {
    const { code, password, confirmation } = this.props;
    event.preventDefault();
    if (!this.formIsValid()) return;
    this.props.recoverPassword(code, password, confirmation);
  };

  render = () => (
    <div className="password-recovery">
      <Header />
      <NavigationBar title="Recuperação de Senha" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/login', title: 'Login' },
        { to: '/esqueci-minha-senha', title: 'Esqueci Minha Senha' },
        { to: '/esqueci-minha-senha/codigo', title: 'Recuperação de Senha' },
      ]}/>
      <div className="gray-container">
        <div className="container">
          <p className="text text-center page-description">Redefina a sua senha de acesso.</p>
          <div className="form-container">
            <form className="login-form" onSubmit={this.handleSubmit}>
              <h3 className="title text-center bold">Esqueci Minha Senha</h3>
              <div className="form-group">
                <label className="text bold">Senha:</label>
                <input type="password" name="password" autoComplete="off" value={this.props.password} onChange={e => this.handleChange('password', e)}/>
              </div>
              <div className="form-group">
                <label className="text bold">Confirmação:</label>
                <input className="confirmation" type="password" name="confirmation" autoComplete="off" value={this.props.confirmation} onChange={e => this.handleChange('confirmation', e)}/>
              </div>
              <button type="submit" className="btn bold">REDEFINIR</button>
              <div className="text-center">
                <Link to="/login" className="forgot-password bold">Voltar para o Login</Link>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link to="/cadastro" className="register">CRIAR UMA CONTA</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}

const mapStateToProps = ({ passwordRecovery }) => {
  return { 
    ...passwordRecovery
  };
};

export default connect(
  mapStateToProps,
  { recoverPassword, updatePasswordRecoveryField }
)(PasswordRecovery);