import ActionTypes from '../actions/ActionTypes';

const initialState = {
  produtos: [],
  updateInProgress: false
};

const verifyAvailability = (state, availability) => (
  [...state.produtos.map(p => ({ ...p, disponivel: availability.find(a => a.id === p.id).disponivel }))]
);

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_PRODUCT_TO_BASKET_SUCCESS:
    case ActionTypes.REMOVE_PRODUCT_FROM_BASKET_SUCCESS:
      return { ...state, ...action.payload };
    case ActionTypes.FILL_BASKET:
      return { ...initialState, ...action.payload };
    case ActionTypes.PAY_ORDER_SUCCESS:
    case ActionTypes.LOGOUT_USER_SUCCESS:
      return { ...initialState };
    case ActionTypes.VALIDATE_UNITY_AVAILABILITY_SUCCESS:
      return { ...state, produtos: verifyAvailability(state, action.payload.availability) };
    case ActionTypes.REGISTER_ORDER_SUCCESS:
      return { ...state, produtos: [] };
    case ActionTypes.INVALIDATE_BASKET:
      return { ...initialState };
    case ActionTypes.CHANGE_BASKET_ITEM_QUANTITY:
      return { ...state, updateInProgress: true };
    case ActionTypes.CHANGE_BASKET_ITEM_QUANTITY_SUCCESS:
      return { ...initialState, ...state, ...action.payload };
    case ActionTypes.CHANGE_BASKET_ITEM_QUANTITY_FAIL:
    return { ...state, updateInProgress: false };
    default:
      return state;
  }
};
