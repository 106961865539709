import moment from 'moment';
import { push } from 'connected-react-router';
import Alert from 'react-s-alert';
import ActionTypes from './ActionTypes';
import {
  UnityService,
  NotificationService,
  OrderService,
  BasketService,
  LocalizationService,
  SchedulingService,
} from '../services';
import {
  ReceiverType,
  ShippingType,
  Messages,
  ApiErrorCode,
} from '../constants';

export const getUnits = (products, user) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LIST_UNITS });
    const units = await UnityService.list();
    let unitsStateUser = units.filter((u) => u.estado == user.endereco.estado);
    const coronaIds = [85, 111, 112];
    const hasCoronaExam = products.find(
      (p) => p.agendamentoObrigatorio && coronaIds.includes(p.id)
    );
    if (hasCoronaExam) {
      let unitsAllowedCoronaExam = [
        2131,
        3734,
        4266,
        4786,
        5149,
        7055,
        7056,
        7054,
      ];

      let hasCoronaMg = products.find((p) => p.id == 111);
      if (hasCoronaMg)
        unitsAllowedCoronaExam = [
          ...unitsAllowedCoronaExam,
          2125,
          2877,
          2116,
          2549,
          2392,
          2679,
          2550,
          3801,
          3479,
          2117,
          2118,
          2133,
          4331,
          2135,
          2551,
          2136,
          2237,
          3772,
          4967,
          4913,
          3934,
          5064,
          5065,
          4929,
          2142,
          2552,
          2124,
          2119,
          3525,
          2120,
          3216,
          3199,
          2143,
          3935,
          2246,
          4265,
          3526,
          3730,
          4930,
          4972,
          3209,
          2807,
          5080,
          4963,
          3956,
          7041,
          7051,
          7053,
        ];

      unitsStateUser = unitsStateUser.filter((u) =>
        unitsAllowedCoronaExam.includes(u.id)
      );
    }

    const promotionIds = [134];
    const hasPromotionExam = products.find((p) => promotionIds.includes(p.id));
    if (hasPromotionExam) {
      const unitsAllowedPromotionExam = [
        2877,
        2116,
        5149,
        3479,
        2679,
        2550,
        3801,
        7057,
        2131,
        2118,
        2133,
        2135,
        2551,
        2137,
        5064,
        5065,
        2142,
        3734,
        2124,
        4786,
        2143,
        3935,
        3526,
        4266,
        4930,
        3209,
        5080,
      ];
      unitsStateUser = unitsStateUser.filter((u) =>
        unitsAllowedPromotionExam.includes(u.id)
      );
    }
    unitsStateUser = unitsStateUser.sort((a, b) => {
      if (a.descricao < b.descricao) {
        return -1;
      }
      if (a.descricao > b.descricao) {
        return 1;
      }
      return 0;
    });
    dispatch({
      type: ActionTypes.LIST_UNITS_SUCCESS,
      payload: { units: unitsStateUser || [], products },
    });
    if (!unitsStateUser || unitsStateUser.length <= 0)
      Alert.error(Messages.UnitsNotFound);
  } catch (e) {
    dispatch({ type: ActionTypes.LIST_UNITS_FAIL });
  }
};

export const payOrder = ({
  basket,
  shipping,
  receiver,
  payment,
  order,
}) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.PAY_ORDER });

    if (order) {
      await executePaymentAndFinalize(order, payment)(dispatch);
      return;
    }

    if (shipping.type === ShippingType.Residence) {
      const zipCodeIsValid = await validateShippingZipCode(
        shipping.cep,
        basket
      )(dispatch);
      if (!zipCodeIsValid) {
        Alert.error(Messages.VerifyZipCodeMessage);
        dispatch({ type: ActionTypes.PAY_ORDER_FAIL });
        return;
      }
      const residenceUnity = await UnityService.getResidenceUnity(
        shipping.state
      );
      shipping.unityId = residenceUnity.id;
      const shippingAddress = await registerShippingAddress(shipping)(dispatch);
      shipping.shippingAddressId = shippingAddress.id;
    }

    const allProductsAreAvailable = await validateUnityAvailability(
      shipping.unityId,
      basket
    )(dispatch);
    if (!allProductsAreAvailable) {
      Alert.error(Messages.VerifyProductAvailabilityMessage);
      dispatch({ type: ActionTypes.PAY_ORDER_FAIL });
      return;
    }

    const receiverResult = await registerReceiver(receiver)(dispatch);
    const savedOrder = await registerOrder(
      basket,
      shipping,
      receiverResult
    )(dispatch);

    BasketService.clearCache();

    await executePaymentAndFinalize(savedOrder, payment)(dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.PAY_ORDER_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
    const invalidBasket = (e.data || []).some(
      (d) => d.codigo === ApiErrorCode.InvalidBasket
    );
    if (invalidBasket) {
      BasketService.clearCache();
      dispatch({ type: ActionTypes.INVALIDATE_BASKET });
      dispatch(push('/pedido'));
    }
  }
};

export const redirectToHome = () => push('/');

export const redirectToBasket = () => push('/pedido');

export const redirectToLogin = () => push('/login');

export const validateUnityAvailability = (unityId, basket) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.VALIDATE_UNITY_AVAILABILITY });
    const unityAvailability = await UnityService.checkAvailability(
      unityId,
      basket.produtos.map((p) => p.id)
    );
    const result = basket.produtos.map((p) => {
      const availability = unityAvailability.find((u) => u.idProduto === p.id);
      return { id: p.id, disponivel: availability.quantidade >= p.quantidade };
    });
    const allProductsAreAvailable = !result.some((p) => !p.disponivel);
    dispatch({
      type: ActionTypes.VALIDATE_UNITY_AVAILABILITY_SUCCESS,
      payload: { availability: result, allProductsAreAvailable, doctorId: 20 },
    });
    return allProductsAreAvailable;
  } catch (e) {
    dispatch({ type: ActionTypes.VALIDATE_UNITY_AVAILABILITY_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateOrderField = ({ prop, value }) => ({
  type: ActionTypes.UPDATE_ORDER_FIELD,
  payload: { prop, value },
});

export const fillReceiverWithLoggedUser = (loggedUser) => ({
  type: ActionTypes.FILL_RECEIVER_FROM_LOGGED_USER,
  payload: loggedUser,
});

export const fillShippingAddressWithLoggedUserAddress = (
  loggedUser,
  basket
) => async (dispatch) => {
  dispatch({
    type: ActionTypes.FILL_SHIPPING_ADDRESS_WITH_LOGGED_USER_ADDRESS,
    payload: loggedUser.endereco,
  });
  await validateShippingZipCode(loggedUser.endereco.cep, basket)(dispatch);
};

export const validateShippingZipCode = (zipCode, basket) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.VALIDATE_SHIPPING_ZIPCODE });
    if (!zipCode) {
      dispatch({ type: ActionTypes.VALIDATE_SHIPPING_ZIPCODE_FAIL });
      return false;
    }

    const zipCodeInfo = await OrderService.validateZipCode(
      zipCode.replace(/[^\d]/g, '')
    );

    const localization = await LocalizationService.search(
      zipCode.replace(/[^\d]/g, '')
    );

    const productClearDeliveryTaxInBasket = basket.produtos.find(
      (p) => p.entregaSemCusto
    );
    const tax = productClearDeliveryTaxInBasket ? 0 : zipCodeInfo.taxa;
    const unity = await UnityService.getResidenceUnity(zipCodeInfo.uf);
    dispatch({
      type: ActionTypes.VALIDATE_SHIPPING_ZIPCODE_SUCCESS,
      payload: { unityId: unity.id, tax: tax, localization },
    });
    await validateUnityAvailability(unity.id, basket)(dispatch);
    return { unityId: unity.id, tax: tax };
  } catch (e) {
    NotificationService.showApiResponseErrorAlert(e);
    dispatch({ type: ActionTypes.VALIDATE_SHIPPING_ZIPCODE_FAIL });
    return false;
  }
};

export const procceedToPayment = (orderData) => (dispatch) => {
  calculatePaymentParcels(orderData)(dispatch);

  const products = orderData.produtos;
  const shipping = orderData.shipping;
  const dataLayer = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: 'pagamento' },
        products: products.map((product) => ({
          name: product.nomeProduto,
          id: product.id,
          price: product.precoUnitario,
          brand: shipping.unityId,
          category: product.vacina ? 'Vacina' : 'Análises Clínico',
          quantity: product.quantidade,
        })),
      },
    },
  };
  window.dataLayer.push(dataLayer);

  dispatch(push('/pagamento'));
};

export const handleShippingTypeChange = (
  type,
  basket,
  validateZipCode = false
) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.HANDLE_SHIPPING_TYPE_CHANGE });
    const productClearDeliveryTaxInBasket = basket.produtos.find(
      (p) => p.entregaSemCusto
    );
    if (type === ShippingType.Residence && !productClearDeliveryTaxInBasket) {
      Alert.success(Messages.ShippingTaxAddedMessage);
    }
    dispatch({
      type: ActionTypes.HANDLE_SHIPPING_TYPE_CHANGE_SUCCESS,
      payload: { type },
    });
    if (type === ShippingType.Unity)
      dispatch({ type: ActionTypes.CLEAR_DELIVERY_TAX });
    else if (
      basket.shipping.cep &&
      (basket.shipping.tax <= 0 || validateZipCode)
    )
      await validateShippingZipCode(basket.shipping.cep, basket)(dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.HANDLE_SHIPPING_TYPE_CHANGE_FAIL });
  }
};

export const changeBrand = (brand) => ({
  type: ActionTypes.CHANGE_BRAND,
  payload: brand,
});

export const fillReceiptFromOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER });
    const order = await OrderService.get(orderId);
    const payload = { order };
    dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER_SUCCESS, payload });
  } catch (e) {
    dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

const executePaymentAndFinalize = (order, payment) => async (dispatch) => {
  const paymentResult = await executePayment(order, payment)(dispatch);
  dispatch({
    type: ActionTypes.PAY_ORDER_SUCCESS,
    payload: { order: { ...order, pagamento: paymentResult } },
  });
  const productWithoutCostInBasket = order.produtos.find(
    (p) => p.agendamentoObrigatorio
  );
  const route = productWithoutCostInBasket
    ? `/agendamento/${order.id}`
    : `/recibo/${order.id}`;
  dispatch(push(route));
  Alert.success(Messages.OrderCreatedSuccessMessage);
};

const translateCardBrand = (cardType) => {
  const translate = {
    visa: 'Visa',
    mastercard: 'Master',
    'american-express': 'Amex',
    elo: 'Elo',
    jcb: 'JCB',
    'diners-club': 'Diners',
    discover: 'Discover',
  };
  return translate[cardType];
};

const executePayment = (order, payment) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.EXECUTE_PAYMENT });
    const {
      parcels,
      type,
      cardNumber,
      securityCode,
      expirationMonth,
      expirationYear,
      cardName,
      brand,
    } = payment;
    const paymentParams = {
      parcelas: parcels,
      tipoPagamento: type,
      numeroCartao: cardNumber.split('.').join(''),
      codigoSeguranca: securityCode,
      dataValidade: `${expirationMonth}/${expirationYear}`,
      nomeImpresso: cardName,
      bandeira: brand,
    };
    const paymentResult = await OrderService.pay(order.id, paymentParams);

    const dataLayer = {
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: order.id,
            affiliation: 'Online Store',
            revenue: order.valorTotal,
            tax: null,
            shipping: order.taxaEntrega,
            coupon: null,
          },
          products: order.produtos.map((product) => ({
            name: product.nomeProduto,
            id: product.id,
            price: product.precoUnitario,
            brand: order.unidade.id,
            category: product.vacina ? 'Vacina' : 'Análises Clínico',
            quantity: product.quantidade,
          })),
        },
      },
    };
    window.dataLayer.push(dataLayer);

    dispatch({
      type: ActionTypes.EXECUTE_PAYMENT_SUCCESS,
      payload: paymentResult,
    });
    return paymentResult;
  } catch (e) {
    dispatch({ type: ActionTypes.EXECUTE_PAYMENT_FAIL });
    throw e;
  }
};

const registerOrder = (basket, shipping, receiver) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REGISTER_ORDER });
    const orderParams = {
      tipoEntrega: shipping.type,
      idUnidade: shipping.unityId,
      driveThru: shipping.driveThru,
      idRecebedor: receiver.id,
      idEndereco: shipping.shippingAddressId,
    };

    const order = await OrderService.registerOrder(basket.id, orderParams);
    dispatch({ type: ActionTypes.REGISTER_ORDER_SUCCESS, payload: order });

    return order;
  } catch (e) {
    dispatch({ type: ActionTypes.REGISTER_ORDER_FAIL });
    throw e;
  }
};

const registerShippingAddress = (shippingData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REGISTER_SHIPPING_ADDRESS });
    const {
      cep,
      state,
      city,
      neighborhood,
      address,
      number,
      complement,
      deliverySuggestion,
    } = shippingData;
    const shippingParams = {
      cep: cep.replace(/[^\d]/g, ''),
      estado: state,
      cidade: city,
      bairro: neighborhood,
      logradouro: address,
      numero: number,
      complemento: complement,
      sugestaoDataHoraEntrega: deliverySuggestion,
    };
    const shippingAddress = await OrderService.registerShippingAddress(
      shippingParams
    );
    dispatch({
      type: ActionTypes.REGISTER_SHIPPING_ADDRESS_SUCCESS,
      payload: shippingAddress,
    });
    return shippingAddress;
  } catch (e) {
    dispatch({ type: ActionTypes.REGISTER_SHIPPING_ADDRESS_FAIL });
    throw e;
  }
};

const registerReceiver = (receiverData) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REGISTER_RECEIVER });
    const { name, birthday, genre, relationship, cpf, type } = receiverData;
    const receiverParams = {
      nome: name,
      dataNascimento: birthday
        ? moment(birthday, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
        : null,
      sexo: genre,
      grauParentesco: relationship,
      cpf: cpf.replace(/[^\d]/g, ''),
      tipo: type,
      comprador: type === ReceiverType.Buyer,
    };
    const receiver = await OrderService.registerReceiver(receiverParams);
    dispatch({
      type: ActionTypes.REGISTER_RECEIVER_SUCCESS,
      payload: receiver,
    });
    return receiver;
  } catch (e) {
    dispatch({ type: ActionTypes.REGISTER_RECEIVER_FAIL });
    throw e;
  }
};

const calculatePaymentParcels = (orderData) => (dispatch) => {
  const totalValue = orderData.shipping.tax + orderData.valorProdutos;
  let parcelNumber = totalValue < 50 ? 1 : Math.trunc(totalValue / 50);
  const parcels = [];

  parcelNumber = parcelNumber > 12 ? 12 : parcelNumber;

  for (let i = 1; i <= parcelNumber; i += 1) {
    parcels.push({
      number: i,
      value: totalValue / i,
    });
  }
  dispatch({ type: ActionTypes.CALCULATE_PAYMENT_PARCELS, payload: parcels });
};

export const searchSchedulingDay = (order) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SEARCH_SCHEDULING_DAY });
    let doctorId = null;
    if (order.tipoEntrega === ShippingType.Unity) {
      const productWithFlag = order.produtos.find(
        (p) => p.agendamentoObrigatorio
      );
      const unityAvailability = await UnityService.checkAvailability(
        order.unidade.id,
        [productWithFlag.id]
      );
      doctorId = unityAvailability[0].codigoMedico;
      dispatch({
        type: ActionTypes.FILL_DOCTOR,
        payload: doctorId,
      });
    }
    const state =
      order.tipoEntrega === ShippingType.Unity
        ? order.unidade.estado
        : order.enderecoEntrega.estado;
    const days = await SchedulingService.searchSchedulingDay(state, doctorId);
    dispatch({
      type: ActionTypes.SEARCH_SCHEDULING_DAY_SUCCESS,
      payload: days,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_SCHEDULING_DAY_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const handleChangeSchedulingDay = (date, state, doctor) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_SHCEDULING_DAY, payload: date });
    if (date) {
      dispatch({ type: ActionTypes.SEARCH_SCHEDULING_HOUR });
      const hours = await SchedulingService.searchSchedulingHour(
        date,
        state,
        doctor
      );
      dispatch({
        type: ActionTypes.SEARCH_SCHEDULING_HOUR_SUCCESS,
        payload: hours,
      });
      if (!hours || hours.length <= 0)
        Alert.error(Messages.SchedulingNoHourAvailable);
    }
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_SCHEDULING_HOUR_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const skipSchedulinStep = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_SHCEDULING_SELECTED_DATA });
  dispatch(push(`/recibo/${id}`));
};

export const handleChangeSchedulingHour = (hour) => async (dispatch) => {
  dispatch({ type: ActionTypes.UPDATE_SHCEDULING_HOUR, payload: hour });
};

export const saveScheduling = (order, scheduling) => async (dispatch) => {
  const schedules = scheduling.schedulingHours.filter(
    (h) => h.horario == scheduling.schedulingHour
  );
  for (var index in schedules) {
    try {
      dispatch({ type: ActionTypes.SAVE_SCHEDULING });
      await SchedulingService.saveScheduling(schedules[index], order);
      dispatch({ type: ActionTypes.SAVE_SCHEDULING_SUCCESS });
      dispatch(push(`/recibo/${order.id}`));
      Alert.success(
        `Agendamento realizado com sucesso para o dia ${moment(
          schedules[index].horario
        ).format('DD/MM/YYYY HH:mm')}`
      );
      return;
    } catch (e) {
      dispatch({ type: ActionTypes.SAVE_SCHEDULING_FAIL });
    }
  }
  dispatch({ type: ActionTypes.SAVE_SCHEDULING_FAIL });
  Alert.error(Messages.SchedulingCannotBeSaved);
};

export const fillScheduleFromOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER });
    const order = await OrderService.get(orderId);
    const payload = { order };
    dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER_SUCCESS, payload });
    await searchSchedulingDay(order)(dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};
