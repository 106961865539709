import { Messages } from "../../constants";

const validation = {
  email: {
    presence: {
      message: Messages.RequiredEmailValidationMessage,
      allowEmpty: false
    },
    email: {
      message: Messages.InvalidEmailValidationMessage
    }
  },
  name: {
    presence: {
      message: Messages.RequiredNameValidationMessage,
      allowEmpty: false
    }
  },
  state: {
    presence: {
      message: Messages.RequiredStateValidationMessage,
      allowEmpty: false
    }
  },
  phone: {
    presence: {
      message: Messages.RequiredPhoneValidationMessage,
      allowEmpty: false
    }
  },
  message: {
    presence: {
      message: Messages.RequiredMessageValidationMessage,
      allowEmpty: false
    }
  }
};

export default validation;
