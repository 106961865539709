import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../components';
import { validatePasswordRecoveryCode, updatePasswordRecoveryField } from '../../actions/AuthActions';
import { ValidationService } from '../../services';
import validation from './validation';
import './style.scss';

class PasswordRecoveryCode extends Component {
  
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formIsValid = () => ValidationService.validate(this.props, validation);

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updatePasswordRecoveryField({ prop, value });
  };

  handleSubmit = (event) => {
    const { code } = this.props;
    event.preventDefault();
    if (!this.formIsValid()) return;
    this.props.validatePasswordRecoveryCode(code);
  };

  render = () => (
    <div className="password-recovery-code">
      <Header />
      <NavigationBar title="Esqueci Minha Senha" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/login', title: 'Login' },
        { to: '/esqueci-minha-senha', title: 'Esqueci Minha Senha' },
        { to: '/esqueci-minha-senha/codigo', title: 'Recuperação de Senha' },
      ]}/>
      <div className="gray-container">
        <div className="container">
          <p className="text text-center page-description">Informe o código númerico recebido em seu e-mail.</p>
          <div className="form-container">
            <form className="login-form" onSubmit={this.handleSubmit}>
              <h3 className="title text-center bold">Esqueci Minha Senha</h3>
              <div className="form-group">
                <label className="text bold">Código:</label>
                <input type="text" name="codigo" autoComplete="off" maxLength="4" value={this.props.code} onChange={e => this.handleChange('code', e)}/>
              </div>
              <button type="submit" className="btn bold">ENVIAR</button>
              <div className="text-center">
                <Link to="/login" className="forgot-password bold">Voltar para o Login</Link>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link to="/cadastro" className="register">CRIAR UMA CONTA</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}

const mapStateToProps = ({ passwordRecovery }) => {
  return { 
    ...passwordRecovery
  };
};

export default connect(
  mapStateToProps,
  { validatePasswordRecoveryCode, updatePasswordRecoveryField }
)(PasswordRecoveryCode);