import Alert from 'react-s-alert';
import { push } from 'connected-react-router';
import ActionTypes from './ActionTypes';
import { BasketService } from '../services/BasketService';
import { NotificationService } from '../services';
import { handleShippingTypeChange, getUnits } from './OrderActions';

export const addProductToBasket = (product) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.ADD_PRODUCT_TO_BASKET });
    const basket = await BasketService.addProduct(product);

    const dataLayer = {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'BRL',
        add: {
          products: [
            {
              name: product.nome,
              id: product.id,
              price: product.preco,
              category: product.vacina ? 'Vacina' : 'Análises Clínico',
            },
          ],
        },
      },
    };
    window.dataLayer.push(dataLayer);

    dispatch({
      type: ActionTypes.ADD_PRODUCT_TO_BASKET_SUCCESS,
      payload: basket,
    });
    Alert.success(
      `Produto ${product.nome} adicionada ao seu pedido com sucesso!`
    );
    dispatch(push('/pedido'));
  } catch (e) {
    dispatch({ type: ActionTypes.ADD_PRODUCT_TO_BASKET_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const removeProductFromBasket = (
  currentBasket,
  type,
  product,
  user
) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.REMOVE_PRODUCT_FROM_BASKET });
    const basket = await BasketService.removeProduct(product);

    const dataLayer = {
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              name: product.nomeProduto,
              id: product.id,
              price: product.precoUnitario,
              category: product.vacina ? 'Vacina' : 'Análises Clínico',
            },
          ],
        },
      },
    };
    window.dataLayer.push(dataLayer);

    dispatch({
      type: ActionTypes.REMOVE_PRODUCT_FROM_BASKET_SUCCESS,
      payload: basket,
    });
    currentBasket = { ...currentBasket, produtos: basket.produtos };
    await handleShippingTypeChange(type, currentBasket, true)(dispatch);
    await getUnits(basket.produtos, user)(dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.REMOVE_PRODUCT_FROM_BASKET_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const fillBasketFromStorage = () => {
  const basketFromStorage = BasketService.getBasketFromStorage();
  return { type: ActionTypes.FILL_BASKET, payload: basketFromStorage };
};

export const changeItemQuantity = (item, quantity) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.CHANGE_BASKET_ITEM_QUANTITY });
    const basket = await BasketService.changeItemQuantity(item, quantity);
    dispatch({
      type: ActionTypes.CHANGE_BASKET_ITEM_QUANTITY_SUCCESS,
      payload: basket,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.CHANGE_BASKET_ITEM_QUANTITY_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};
