import { push } from 'connected-react-router';
import ActionTypes from './ActionTypes';
import { NotificationService, CategoryService } from '../services';
import { ProductService } from '../services';

export const searchProducts = (search = null, categoryId = null) => async (
  dispatch
) => {
  try {
    if (!search && !categoryId) {
      const category = await CategoryService.getPrincipal();
      if (category && category.length > 0) categoryId = category[0].id;
    }
    dispatch({
      type: ActionTypes.SEARCH_PRODUCTS,
      payload: search,
    });
    const products = await ProductService.search(
      1,
      process.env.REACT_APP_PAGE_SIZE,
      search,
      categoryId
    );

    const dataLayer = {
      ecommerce: {
        event: 'impressions',
        currencyCode: 'BRL',
        impressions: products.map((product, index) => ({
          name: product.nome,
          id: product.id,
          price: product.preco,
          category: product.vacina ? 'Vacina' : 'Análises Clínico',
          list: 'Search Results',
          position: index,
        })),
      },
    };
    window.dataLayer.push(dataLayer);

    dispatch({
      type: ActionTypes.SEARCH_PRODUCTS_SUCCESS,
      payload: products,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.SEARCH_PRODUCTS_FAIL });
    if (e.problem !== 'CANCEL_ERROR')
      NotificationService.showApiResponseErrorAlert(e);
  }
};

export const paginateProducts = (
  pageNumber,
  search = null,
  categoryId = null
) => async (dispatch) => {
  try {
    if (!search && !categoryId) {
      const category = await CategoryService.getPrincipal();
      if (category && category.length > 0) categoryId = category[0].id;
    }
    dispatch({ type: ActionTypes.PAGINATE_PRODUCTS, payload: pageNumber });
    const products = await ProductService.search(
      pageNumber,
      process.env.REACT_APP_PAGE_SIZE,
      search,
      categoryId
    );
    const payload = { products: products, pageNumber };
    dispatch({
      type: ActionTypes.PAGINATE_PRODUCTS_SUCCESS,
      payload,
    });
  } catch (e) {
    dispatch({ type: ActionTypes.PAGINATE_PRODUCTS_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateProductSearchField = ({ prop, value }) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_PRODUCT_SEARCH_FIELD,
    payload: { prop, value },
  });
};

export const redirectToProductSearch = (searchTerm) => (dispatch) => {
  dispatch(push(`/busca?s=${searchTerm}`));
};
