import WebApi from './WebApi';
import { CacheService } from '.';
import { CacheKeys } from '../constants';

export class BasketService {

  static async addProduct(product) {
    let basket = BasketService.getBasketFromStorage();
    const basketProduct = {
      idProduto: product.id,
      quantidade: 1
    };
    if (basket && basket.produtos.length > 0) basket = await BasketService._addProductToBasket(basket, basketProduct);
    else basket = await BasketService._createBasket(basketProduct);
    BasketService.saveBasketToStorage(basket);
    return basket;
  }

  static async removeProduct(product) {
    let basket = BasketService.getBasketFromStorage();
    const response = await WebApi.delete(`/checkout/carrinho/${basket.id}/produto/${product.id}`);
    basket = response.data;
    BasketService.saveBasketToStorage(basket);
    return basket;
  }

  static async changeItemQuantity(item, quantity) {
    let basket = BasketService.getBasketFromStorage();
    const response = await WebApi.patch(`/checkout/carrinho/${basket.id}/produto/${item.id}/quantidade/${quantity}`);
    basket = response.data;
    BasketService.saveBasketToStorage(basket);
    return basket;
  }

  static async mergeBasket() {
    let basket = BasketService.getBasketFromStorage();
    if (basket && basket.produtos.length > 0) basket = await BasketService._mergeWithExistingBasket(basket);
    else basket = await BasketService._getUserPendingBasket();
    
    if (basket) BasketService.saveBasketToStorage(basket);
    else CacheService.remove(CacheKeys.Basket);

    return basket;
  };

  static saveBasketToStorage(basket) {
    CacheService.remove(CacheKeys.Basket);
    CacheService.saveWithExpireDate(CacheKeys.Basket, basket, basket.dataExpiracao);
  };

  static getBasketFromStorage() {
    return CacheService.get(CacheKeys.Basket);
  }

  static clearCache() {
    return CacheService.remove(CacheKeys.Basket);
  }

  static async _addProductToBasket(basket, basketProduct) {
    const response = await WebApi.post(`/checkout/carrinho/${basket.id}/produto`, basketProduct);
    return response.data;
  }

  static async _createBasket(basketProduct) {
    const response = await WebApi.post(`/checkout/carrinho/produto`, basketProduct);
    return response.data;
  }

  static async _getUserPendingBasket() {
    const response = await WebApi.get(`/checkout/carrinho`);
    return response.data;
  }

  static async _mergeWithExistingBasket(basket) {
    const response = await WebApi.patch(`/checkout/carrinho/${basket.id}/merge`);
    return response.data;
  }
}