import ActionTypes from '../actions/ActionTypes';

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
    case ActionTypes.ADD_PRODUCT_TO_BASKET:
    case ActionTypes.REQUEST_PASSWORD_RECOVERY:
    case ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE:
    case ActionTypes.RECOVER_PASSWORD:
    case ActionTypes.REGISTER_USER:
    case ActionTypes.SEND_CONTACT:
    case ActionTypes.UPLOAD_USER_PICTURE:
    case ActionTypes.SEARCH_PRODUCTS:
    case ActionTypes.GET_PRODUCT:
    case ActionTypes.REMOVE_PRODUCT_FROM_BASKET:
    case ActionTypes.SEARCH_CATEGORIES:
    case ActionTypes.PAY_ORDER:
    case ActionTypes.FILL_RECEIPT_FROM_ORDER:
    case ActionTypes.UPDATE_USER_PROFILE:
    case ActionTypes.INACTIVE_BACKOFFICE_PRODUCT:
    case ActionTypes.CHANGE_BASKET_ITEM_QUANTITY:
    case ActionTypes.FILL_CATEGORIES:
    case ActionTypes.UPLOAD_INVOICE:
    case ActionTypes.SAVE_CATEGORY_PRODUCTS_SORT:
    case ActionTypes.EXPORT_ORDERS:
    case ActionTypes.GET_FILE:
    case ActionTypes.ACTIVE_BACKOFFICE_PRODUCT:
    case ActionTypes.CREATE_PRODUCT:
    case ActionTypes.UPLOAD_PRODUCT_PICTURE:
    case ActionTypes.GET_CATEGORY_DETAILS:
    case ActionTypes.GET_PRODUCT_TO_EDIT:
    case ActionTypes.UPDATE_PRODUCT:
    case ActionTypes.GET_CATEGORY_PRODUCTS:
    case ActionTypes.SEARCH_BACKOFFICE_ORDERS:
    case ActionTypes.SEARCH_BACKOFFICE_PRODUCTS:
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS:
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER:
    case ActionTypes.FILL_REGISTER_ADDRESS:
    case ActionTypes.SEARCH_SCHEDULING_DAY:
    case ActionTypes.SEARCH_SCHEDULING_HOUR:
    case ActionTypes.SAVE_SCHEDULING:
    case ActionTypes.LIST_USER_ORDERS:
      return { ...initialState, loading: true };
    case ActionTypes.LIST_CATEGORIES_SUCCESS:
    case ActionTypes.LIST_CATEGORIES_FAIL:
    case ActionTypes.SEARCH_PRODUCTS_SUCESS:
    case ActionTypes.SEARCH_PRODUCTS_FAIL:
    case ActionTypes.UPLOAD_USER_PICTURE_SUCCESS:
    case ActionTypes.UPLOAD_USER_PICTURE_FAIL:
    case ActionTypes.UPLOAD_INVOICE_SUCCESS:
    case ActionTypes.UPLOAD_INVOICE_FAIL:
    case ActionTypes.EXPORT_ORDERS_SUCCESS:
    case ActionTypes.EXPORT_ORDERS_FAIL:
    case ActionTypes.GET_FILE_SUCCESS:
    case ActionTypes.GET_FILE_FAIL:
    case ActionTypes.UPLOAD_PRODUCT_PICTURE_FAIL:
    case ActionTypes.UPLOAD_PRODUCT_PICTURE_SUCCESS:
    case ActionTypes.FILL_CATEGORIES_SUCCESS:
    case ActionTypes.FILL_CATEGORIES_FAIL:
    case ActionTypes.CREATE_PRODUCT_SUCCESS:
    case ActionTypes.CREATE_PRODUCT_FAIL:
    case ActionTypes.SEND_CONTACT_SUCCESS:
    case ActionTypes.SEND_CONTACT_FAIL:
    case ActionTypes.REGISTER_USER_SUCCESS:
    case ActionTypes.REGISTER_USER_FAIL:
    case ActionTypes.RECOVER_PASSWORD_SUCCESS:
    case ActionTypes.RECOVER_PASSWORD_FAIL:
    case ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE_SUCCESS:
    case ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE_FAIL:
    case ActionTypes.REQUEST_PASSWORD_RECOVERY_SUCCESS:
    case ActionTypes.REQUEST_PASSWORD_RECOVERY_FAIL:
    case ActionTypes.LOGIN_USER_SUCCESS:
    case ActionTypes.LOGIN_USER_FAIL:
    case ActionTypes.SEARCH_PRODUCTS_SUCCESS:
    case ActionTypes.SEARCH_PRODUCTS_FAIL:
    case ActionTypes.UPDATE_PRODUCT_SUCCESS:
    case ActionTypes.UPDATE_PRODUCT_FAIL:
    case ActionTypes.ADD_PRODUCT_TO_BASKET_SUCCESS:
    case ActionTypes.ADD_PRODUCT_TO_BASKET_FAIL:
    case ActionTypes.GET_PRODUCT_SUCCESS:
    case ActionTypes.GET_PRODUCT_FAIL:
    case ActionTypes.REMOVE_PRODUCT_FROM_BASKET_SUCCESS:
    case ActionTypes.REMOVE_PRODUCT_FROM_BASKET_FAIL:
    case ActionTypes.PAY_ORDER_SUCCESS:
    case ActionTypes.SAVE_CATEGORY_PRODUCTS_SORT_FAIL:
    case ActionTypes.SAVE_CATEGORY_PRODUCTS_SORT_SUCCESS:
    case ActionTypes.PAY_ORDER_FAIL:
    case ActionTypes.GET_CATEGORY_PRODUCTS_SUCCESS:
    case ActionTypes.GET_CATEGORY_PRODUCTS_FAIL:
    case ActionTypes.SEARCH_CATEGORIES_SUCCESS:
    case ActionTypes.SEARCH_CATEGORIES_FAIL:
    case ActionTypes.FILL_RECEIPT_FROM_ORDER_SUCCESS:
    case ActionTypes.FILL_RECEIPT_FROM_ORDER_FAIL:
    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
    case ActionTypes.UPDATE_USER_PROFILE_FAIL:
    case ActionTypes.GET_CATEGORY_DETAILS_FAIL:
    case ActionTypes.GET_CATEGORY_DETAILS_SUCCESS:
    case ActionTypes.CHANGE_BASKET_ITEM_QUANTITY_SUCCESS:
    case ActionTypes.CHANGE_BASKET_ITEM_QUANTITY_FAIL:
    case ActionTypes.SEARCH_BACKOFFICE_ORDERS_SUCCESS:
    case ActionTypes.SEARCH_BACKOFFICE_ORDERS_FAIL:
    case ActionTypes.SEARCH_BACKOFFICE_PRODUCTS_SUCCESS:
    case ActionTypes.ACTIVE_BACKOFFICE_PRODUCT_FAIL:
    case ActionTypes.ACTIVE_BACKOFFICE_PRODUCT_SUCCESS:
    case ActionTypes.SEARCH_BACKOFFICE_PRODUCTS_FAIL:
    case ActionTypes.INACTIVE_BACKOFFICE_PRODUCT_FAIL:
    case ActionTypes.INACTIVE_BACKOFFICE_PRODUCT_SUCCESS:
    case ActionTypes.GET_PRODUCT_TO_EDIT_SUCCESS:
    case ActionTypes.GET_PRODUCT_TO_EDIT_FAIL:
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS_SUCCESS:
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_STATUS_FAIL:
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_SUCCESS:
    case ActionTypes.UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_FAIL:
    case ActionTypes.FILL_REGISTER_ADDRESS_SUCCESS:
    case ActionTypes.FILL_REGISTER_ADDRESS_FAIL:
    case ActionTypes.SEARCH_SCHEDULING_DAY_SUCCESS:
    case ActionTypes.SEARCH_SCHEDULING_DAY_FAIL:
    case ActionTypes.SEARCH_SCHEDULING_HOUR_SUCCESS:
    case ActionTypes.SEARCH_SCHEDULING_HOUR_FAIL:
    case ActionTypes.SAVE_SCHEDULING_SUCCESS:
    case ActionTypes.SAVE_SCHEDULING_FAIL:
    case ActionTypes.LIST_USER_ORDERS_FAIL:
    case ActionTypes.LIST_USER_ORDERS_SUCCESS:
      return { ...initialState, loading: false };
    default:
      return state;
  }
};
