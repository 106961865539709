import ActionTypes from '../actions/ActionTypes';

const initialState = {
  loggedUser: null,
  email: '',
  password: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
    case ActionTypes.LOGIN_USER_SUCCESS:
      return { ...initialState, loggedUser: action.payload, email: action.payload.email };
    case ActionTypes.LOGOUT_USER_SUCCESS: 
      return { ...state, loggedUser: null };
    case ActionTypes.LOGIN_USER_FAIL:
      return { ...state, password: '' };
    case ActionTypes.VERIFY_LOGGED_USER_SUCCESS:
      return { ...initialState, loggedUser: action.payload };
    case ActionTypes.UPDATE_AUTH_EMAIL:
      return { ...state, email: action.payload };
    case ActionTypes.UPDATE_LOGIN_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    default:
      return state;
  }
};
