import Alert from "react-s-alert";
import { push } from "connected-react-router";
import ActionTypes from "./ActionTypes";
import { Messages } from "../constants";

import {
    FileService
  } from "../services";

  import {
    NotificationService,
    BackOfficeService,
    ProductService,
    CategoryService,
    UnityService
  } from "../services";

export const getFile = (fileId) => async dispatch => {
    try {
      dispatch({ 
        type: ActionTypes.GET_FILE,
        payload: { fileId }
      });
      const file = await FileService.getFile(fileId);
      dispatch({
        type: ActionTypes.GET_FILE_SUCCESS
      });
      var blob = new Blob([file], {
        type: "application/pdf"
      });
      var pdfURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement("a");
      tempLink.href = pdfURL;
      tempLink.setAttribute("download", "NotaFiscal.pdf");
      tempLink.click();
    } catch (e) {
      dispatch({ type: ActionTypes.GET_FILE_FAIL });
      NotificationService.showApiResponseErrorAlert(e);
    }
  };