import ActionTypes from '../actions/ActionTypes';

const initialState = {
  products: [],
  pageNumber: 1,
  initial: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_PRODUCTS:
      return { ...initialState, searchTerm: action.payload, initial: false };
      case ActionTypes.SEARCH_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload };
      case ActionTypes.PAGINATE_PRODUCTS_SUCCESS:
        return { 
          ...state, 
          products: [ ...state.products, ...action.payload.products ],  
          pageNumber: action.payload.pageNumber
        };
      case ActionTypes.UPDATE_PRODUCT_SEARCH_FIELD:
        return { ...state, [action.payload.prop]: action.payload.value };
    default:
      return state;
  }
};
