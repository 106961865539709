export const calculateAge = (birthday) => {
  const today = new Date();

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  const birthdayYear = birthday.getFullYear();
  const birthdayMonth = birthday.getMonth();
  const birthdayDay = birthday.getDate();

  let age = currentYear - birthdayYear;

  if (
    currentMonth < birthdayMonth ||
    (currentMonth == birthdayMonth && currentDay < birthdayDay)
  ) {
    age--;
  }

  return age < 0 ? 0 : age;
};
