import { Messages, ShippingType } from '../../constants';

const validation = {
  unityId: {
    presence: { 
      message: Messages.RequiredShippingUnityValidationMessage,
      allowEmpty: false
    }
  },
  address: {
    presence: (value, attributes) => {
      if (attributes.type === ShippingType.Residence) {
        return { 
          message: Messages.RequiredShippingAddressValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
  city: {
    presence: (value, attributes) => {
      if (attributes.type === ShippingType.Residence) {
        return { 
          message: Messages.RequiredShippingCityValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
  state: {
    presence: (value, attributes) => {
      if (attributes.type === ShippingType.Residence) {
        return { 
          message: Messages.RequiredShippingStateValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
  neighborhood: {
    presence: (value, attributes) => {
      if (attributes.type === ShippingType.Residence) {
        return { 
          message: Messages.RequiredShippingNeighborhoodValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
  number: {
    presence: (value, attributes) => {
      if (attributes.type === ShippingType.Residence) {
        return { 
          message: Messages.RequiredNumberValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  }
};

export default validation;