import moment from 'moment';
import ActionTypes from '../actions/ActionTypes';

const initialState = {
  id: null,
  name: '',
  cep: '',
  email: '',
  motherName: '',
  address: '',
  phone: '',
  city: '',
  state: '',
  password: '',
  confirmation: '',
  cpf: '',
  rg: '',
  number: '',
  complement: '',
  neighborhood: '',
  cellPhone: '',
  birthday: '',
  genre: '',
  uploadedFile: null,
  cepIsRural: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FILL_REGISTER_ADDRESS_SUCCESS:
      return { 
        ...state,
        address: action.payload.logradouro, 
        city: action.payload.cidade, 
        state: action.payload.estado, 
        neighborhood: action.payload.bairro,
        number: '', 
        complement: '', 
        cepIsRural: action.payload.cepRural
      };
    case ActionTypes.FILL_REGISTER_ADDRESS_FAIL:
      return { ...state, address: '', city: '', state: '', number: '', complement: '', cepIsRural: false, neighborhood: '' };
    case ActionTypes.RESET_REGISTER_FORM:
      return { ...initialState };
    case ActionTypes.UPDATE_USER_REGISTER_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    case ActionTypes.UPLOAD_USER_PICTURE:
      return { ...state, uploadedFile: null };
    case ActionTypes.UPLOAD_USER_PICTURE_SUCCESS:
      return { ...state, uploadedFile: action.payload };
    case ActionTypes.FILL_USER_REGISTER_FROM_LOGGED_USER:
      return {
        ...initialState,
        id: action.payload.id,
        cpf: action.payload.cpf,
        rg: action.payload.rg,
        name: action.payload.nome,
        motherName: action.payload.nomeMae,
        email: action.payload.email,
        phone: action.payload.telefone,
        genre: action.payload.sexo,
        cellPhone: action.payload.celular,
        address: action.payload.endereco.logradouro,
        cep: action.payload.endereco.cep,
        city: action.payload.endereco.cidade,
        number: action.payload.endereco.numero,
        complement: action.payload.endereco.complemento || '',
        state: action.payload.endereco.estado,
        neighborhood: action.payload.endereco.bairro,
        uploadedFile: action.payload.idFotoPerfil ? { id: action.payload.idFotoPerfil } : null,
        birthday: action.payload.dataNascimento 
          ? moment(action.payload.dataNascimento).format('DD/MM/YYYY')
          : null, 
      };
    default:
      return state;
  }
};
