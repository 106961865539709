import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

class NavigationBar extends Component {
  render = () => (
    <div className="navigation-bar ">
      <div className="container">
        <div className="title-container pull-left">
          <img src="/img/icons/heart.png" srcSet="/img/icons/heart@2x.png 2x, /img/icons/heart@3x.png 3x" className="pull-left"/>
          <h1 className="title semi-bold">{this.props.title}</h1>
        </div>
        <ul className="list-unstyled pull-right breadcrumb">
          {this.props.breadcrumb.map(b => (
            <li key={b.to}><Link to={b.to}>{b.title}</Link></li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default NavigationBar;

