import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Footer, NavigationBar } from "../../../components";
import "./style.scss";
import * as BackOfficeActions from "../../../actions/BackOfficeActions";
import { sortable } from "react-sortable";

class BackOfficeCategorySort extends Component {
  componentDidMount = () => {
    const {
      match: {
        params: { categoryId }
      }
    } = this.props;

    this.props.getCategoryDetail(categoryId);
    this.props.getCategoryProducts(categoryId);
  };

  handleSortProduct = items => {
    this.props.sortProductsChanged(items);
  };

  saveCategorySort() {
    this.props.saveCategorySort({ ...this.props });
  }

  render = () => {
    const {
      match: {
        params: { categoryId }
      }
    } = this.props;
    return (
      <div className="category-sort">
        <Header />
        <NavigationBar
          title="Ordenar Categoria"
          breadcrumb={[
            { to: "/", title: "Home" },
            { to: "/backoffice/categorias/", title: "Listar Categorias" },
            {
              to: `/backoffice/categorias/ordenar/${categoryId}`,
              title: "Ordenar Categoria"
            }
          ]}
        />
        <div className="gray-container">
          <div className="container">
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                <p className="category-description">
                  {this.props.category.descricao}
                </p>
              </div>
              <div
                className="col-xs-4 col-sm-4 col-md-4 col-lg-4 form-group text-right"
                title="Salvar"
              >
                <button
                  className="btn btn-export"
                  onClick={() => this.saveCategorySort()}
                >
                  <span className="glyphicon glyphicon-floppy-disk"></span>
                </button>
              </div>
            </div>
            <p className="text text-center small">
              Arraste os produtos para ordenar
            </p>
            <ul className="list-group">
              {this.props.categoryProducts.map((cp, i) => (
                <SortableItem
                  key={i}
                  onSortItems={this.handleSortProduct}
                  items={this.props.categoryProducts}
                  sortId={i}
                >
                  {`${cp.nomeProduto} - ${cp.estado}`}
                </SortableItem>
              ))}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

class Item extends Component {
  render() {
    return (
      <li {...this.props} className="list-group-item">
        {this.props.children}
      </li>
    );
  }
}
const SortableItem = sortable(Item);

const mapStateToProps = ({ backOfficeCategories }) => {
  return {
    ...backOfficeCategories
  };
};

export default connect(
  mapStateToProps,
  BackOfficeActions
)(BackOfficeCategorySort);
