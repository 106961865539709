import React, { Component } from "react";
import "./style.scss";
import moment from "moment";

class Footer extends Component {
  render = () => (
    <footer className="footer">
      <div className="group-box">
        <img src="/img/logo-total.png" className="logo pull-left" />
        <div className="copyright">
          <p className="text small bold">
            ©{moment().format("YYYY")} Grupo São Marcos
          </p>
          <p className="text small">Todos os direitos reservados</p>
        </div>
      </div>
      <div className="social-box">
        <div className="social">
          <img src="/img/logo-sm.png" className="company-symbol" />
          <div className="social-icons">
            <a
              href="https://facebook.com/saomarcoslaboratorio"
              target="_blank"
              className="space"
            >
              <img
                src="/img/icons/face-dark.png"
                srcSet="/img/icons/face-dark@2x.png 2x,/img/icons/face-dark@3x.png 3x"
              />
            </a>
            <a
              href="https://www.instagram.com/saomarcoslaboratorio"
              target="_blank"
            >
              <img
                src="/img/icons/insta-dark.png"
                srcSet="/img/icons/insta-dark@2x.png 2x,/img/icons/insta-dark@3x.png 3x"
              />
            </a>
            <a href="https://facebook.com/saomarcoslaboratorio" target="_blank">
              <span className="social-name">saomarcoslaboratorio</span>
            </a>
          </div>
        </div>
        <div className="social">
          <img src="/img/logo-labhormon.png" className="company-symbol" />
          <div className="social-icons">
            <a href="https://www.facebook.com/labhormon/" target="_blank">
              <img
                src="/img/icons/face-dark.png"
                srcSet="/img/icons/face-dark@2x.png 2x,/img/icons/face-dark@3x.png 3x"
              />
            </a>
            <a href="https://www.facebook.com/labhormon/" target="_blank">
              <span>labhormon</span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <span className="text">By:</span>
        <a href="http://labsit.io" target="_blank">
          <img
            src="/img/icons/labsit.png"
            srcSet="/img/icons/labsit@2x.png 2x,/img/icons/labsit@3x.png 3x"
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
