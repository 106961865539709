import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Alert from "react-s-alert";
import InputMask from "react-input-mask";
import {
  Header,
  Footer,
  NavigationBar,
  CheckoutSteps,
  BasketItemQuantity,
} from "../../components";
import { removeProductFromBasket } from "../../actions/BasketActions";
import * as OrderActions from "../../actions/OrderActions";
import { ShippingType, ReceiverType, Messages } from "../../constants";
import receiverValidation from "./receiverValidation";
import shippingValidation from "./shippingValidation";
import validation from "./validation";
import "./style.scss";
import { ValidationService } from "../../services";

class Basket extends Component {
  state = {
    showDriveThru: false,
  };

  constructor(props) {
    super(props);
    this.handleUnityChange = this.handleUnityChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShippingTypeChange = this.handleShippingTypeChange.bind(this);
    this.handleReceiverTypeChange = this.handleReceiverTypeChange.bind(this);
    this.handleZipCodeBlur = this.handleZipCodeBlur.bind(this);
    this.handleTermsAcceptedChange = this.handleTermsAcceptedChange.bind(this);
    this.handleTermsPrivacyAcceptedChange = this.handleTermsPrivacyAcceptedChange.bind(
      this
    );
  }

  componentDidMount = () => {
    this.props.getUnits(this.props.produtos, this.props.loggedUser);
  };

  redirectToLogin = () => this.props.redirectToLogin();

  handleUnityChange = (event) => {
    const { value } = event.target;
    this.props.validateUnityAvailability(value, this.props);
    this.props.updateOrderField({
      prop: "shipping",
      value: {
        ...this.props.shipping,
        unityId: value,
        driveThru: false,
      },
    });
    this.verifyDriveThru(value);
  };

  verifyDriveThru = (unityId) => {
    const requiredSchedule = !!this.props.produtos.find(
      (p) => p.agendamentoObrigatorio
    );
    const unit = this.props.units.find((u) => u.id == unityId);
    this.setState({
      showDriveThru: requiredSchedule && (unit || {}).driveThru,
    });
  };

  handleRemoveProduct = (product) => {
    this.props.removeProductFromBasket(
      this.props,
      this.props.shipping.type,
      product,
      this.props.loggedUser
    );
  };

  handleChange = (keyFirstLevel, keySecondLevel, event) => {
    const { value } = event.target;
    const prop = this.props[keyFirstLevel];
    this.props.updateOrderField({
      prop: keyFirstLevel,
      value: { ...prop, [keySecondLevel]: value },
    });
  };

  handleDriveThruChange = () => {
    this.props.updateOrderField({
      prop: "shipping",
      value: {
        ...this.props.shipping,
        driveThru: !this.props.shipping.driveThru,
      },
    });
  };

  handleTermsAcceptedChange = (event) => {
    const { checked } = event.target;
    this.props.updateOrderField({ prop: "termsAccepted", value: checked });
  };

  handleTermsPrivacyAcceptedChange = (event) => {
    const { checked } = event.target;
    this.props.updateOrderField({
      prop: "termsPrivacyAccepted",
      value: checked,
    });
  };

  handleReceiverDontHaveCpfChange = (event) => {
    const { checked } = event.target;
    this.props.updateOrderField({
      prop: "receiver",
      value: {
        ...this.props.receiver,
        dontHaveCpf: checked,
        cpf: checked ? "" : this.props.receiver.cpf,
      },
    });
  };

  handleReceiverTypeChange = (event) => {
    const { value } = event.target;
    this.handleChange("receiver", "type", event);
    const receiver = value === ReceiverType.Buyer ? this.props.loggedUser : {};
    this.props.fillReceiverWithLoggedUser(receiver);
  };

  handleShippingTypeChange = (event) => {
    const { value } = event.target;
    if (!this.props.shipping.cep && value === ShippingType.Residence) {
      this.props.fillShippingAddressWithLoggedUserAddress(
        this.props.loggedUser,
        this.props
      );
    }
    this.props.handleShippingTypeChange(value, this.props);
  };

  handleZipCodeBlur = (event) => {
    const { value } = event.target;
    this.props.validateShippingZipCode(value, this.props);
  };

  validateReceiver = () =>
    ValidationService.validate(this.props.receiver, receiverValidation);

  validateShipping = () =>
    ValidationService.validate(this.props.shipping, shippingValidation);

  validateTermsAccepted = () =>
    ValidationService.validate(this.props, validation);

  handleSubmit = (event) => {
    event.preventDefault();
    Alert.closeAll();
    if (this.props.shipping.unityId && !this.props.canProcceed) {
      Alert.error(Messages.VerifyProductAvailabilityMessage);
    } else if (
      !this.props.zipCodeValidated &&
      this.props.shipping.type === ShippingType.Residence
    ) {
      Alert.error(Messages.VerifyZipCodeMessage);
    } else if (
      this.validateShipping() &&
      this.validateReceiver() &&
      this.validateTermsAccepted() &&
      this.props.canProcceed
    ) {
      this.props.procceedToPayment(this.props);
    }
  };

  redirectToHome = () => {
    this.props.redirectToHome();
  };

  render = () => (
    <div className="basket">
      <Header />
      <NavigationBar
        title="Pedido"
        breadcrumb={[
          { to: "/", title: "Home" },
          { to: "/pedido", title: "Pedido" },
        ]}
      />
      <CheckoutSteps basket products={this.props.produtos} />
      <div className="gray-container">
        <div className="container">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text">Produto</th>
                  <th className="text">Valor Unitário</th>
                  <th className="text quantity">Qtde.</th>
                  <th className="text" />
                </tr>
              </thead>
              <tbody>
                {this.props.produtos.map((p) => (
                  <tr
                    key={p.id}
                    className={p.disponivel === false ? "unavailable" : ""}
                  >
                    <td className="text">
                      {p.nomeProduto}{" "}
                      {p.disponivel === false
                        ? "(Indisponível para a forma de entrega selecionada)"
                        : ""}
                    </td>
                    <td className="text">
                      <NumberFormat
                        value={p.precoUnitario}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={"R$"}
                        fixedDecimalScale
                        decimalScale={2}
                      />
                    </td>
                    <td className="text quantity">
                      <BasketItemQuantity item={p} />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn"
                        title="Remover produto"
                        onClick={() => this.handleRemoveProduct(p)}
                      >
                        <span className="glyphicon glyphicon-trash" />
                      </button>
                    </td>
                  </tr>
                ))}

                {this.props.produtos.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text text-center">
                      Você ainda não adicionou produtos no seu carrinho de
                      compras!
                    </td>
                  </tr>
                )}
              </tbody>
              {this.props.produtos.length > 0 && (
                <tfoot>
                  <tr>
                    <td className="text semi-bold" colSpan="2">
                      Taxa de Deslocamento
                    </td>
                    <td className="text semi-bold" colSpan="2">
                      <NumberFormat
                        value={this.props.shipping.tax}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        prefix={"+ R$"}
                        fixedDecimalScale
                        decimalScale={2}
                      />{" "}
                      <span className="text small">
                        {this.props.shipping.type === ShippingType.Unity
                          ? " (Somente para aplicação em domicílio)"
                          : ""}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
        {this.props.produtos.length > 0 && (
          <div className="total">
            <div className="container">
              <p className="text semi-bold pull-left">TOTAL</p>
              <p className="text bold pull-right">
                <NumberFormat
                  value={this.props.valorProdutos + this.props.shipping.tax}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  prefix={"R$"}
                  fixedDecimalScale
                  decimalScale={2}
                />
              </p>
            </div>
          </div>
        )}
        {this.props.produtos.length > 0 && (
          <div className="container">
            <div className="row form-container">
              {this.props.loggedUser && (
                <form action="POST" onSubmit={this.handleSubmit}>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 className="title">Onde deseja receber a compra?</h3>
                    <div className="col-xs-12 form-group radios">
                      <label className="radio-inline text">
                        <input
                          type="radio"
                          name="shippingType"
                          value={ShippingType.Residence}
                          checked={
                            this.props.shipping.type === ShippingType.Residence
                          }
                          onChange={this.handleShippingTypeChange}
                        />{" "}
                        No meu endereço
                      </label>
                      <label className="radio-inline text">
                        <input
                          type="radio"
                          name="shippingType"
                          value={ShippingType.Unity}
                          checked={
                            this.props.shipping.type === ShippingType.Unity
                          }
                          onChange={this.handleShippingTypeChange}
                        />{" "}
                        Em uma unidade
                      </label>
                    </div>
                    {this.props.shipping.type === ShippingType.Residence && (
                      <div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">CEP *</label>
                          <InputMask
                            placeholder="99999-9999"
                            className={
                              this.props.zipCodeValidated
                                ? "validated"
                                : this.props.shipping.cep
                                ? "invalidated"
                                : ""
                            }
                            type="text"
                            mask="99999-9999"
                            alwaysShowMask={false}
                            maskChar=""
                            name="cep"
                            value={this.props.shipping.cep}
                            onChange={(ev) =>
                              this.handleChange("shipping", "cep", ev)
                            }
                            onBlur={this.handleZipCodeBlur}
                          />
                        </div>

                        <div className="col-xs-12 form-group">
                          <label className="text">Endereço *</label>
                          <input
                            type="text"
                            name="address"
                            value={this.props.shipping.address}
                            onChange={(ev) =>
                              this.handleChange("shipping", "address", ev)
                            }
                            disabled={
                              !this.props.zipCodeValidated ||
                              !this.props.shipping.cepIsRural
                            }
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">Número *</label>
                          <InputMask
                            type="text"
                            mask="999999"
                            alwaysShowMask={false}
                            maskChar=""
                            name="number"
                            value={this.props.shipping.number}
                            onChange={(ev) =>
                              this.handleChange("shipping", "number", ev)
                            }
                            disabled={!this.props.zipCodeValidated}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                          <label className="text">Complemento</label>
                          <input
                            type="text"
                            name="complement"
                            value={this.props.shipping.complement}
                            onChange={(ev) =>
                              this.handleChange("shipping", "complement", ev)
                            }
                            disabled={!this.props.zipCodeValidated}
                          />
                        </div>
                        <div className="col-xs-12 form-group">
                          <label className="text">Bairro *</label>
                          <input
                            type="text"
                            name="neighborhood"
                            value={this.props.shipping.neighborhood}
                            onChange={(ev) =>
                              this.handleChange("shipping", "neighborhood", ev)
                            }
                            disabled={
                              !this.props.zipCodeValidated ||
                              !this.props.shipping.cepIsRural
                            }
                          />
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 form-group">
                          <label className="text">Cidade *</label>
                          <input
                            type="text"
                            name="city"
                            value={this.props.shipping.city}
                            onChange={(ev) =>
                              this.handleChange("shipping", "city", ev)
                            }
                            disabled={!this.props.zipCodeValidated}
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 form-group">
                          <label className="text">Estado *</label>
                          <select
                            name="estado"
                            value={this.props.shipping.state}
                            onChange={(ev) =>
                              this.handleChange("shipping", "state", ev)
                            }
                            disabled={true}
                          >
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                          </select>
                        </div>

                        {!this.props.produtos.find(
                          (p) => p.agendamentoObrigatorio
                        ) && (
                          <div className="col-xs-12 form-group">
                            <label className="text">
                              Sugestão de Dia e Horário de Entrega *
                            </label>
                            <input
                              type="text"
                              name="neighborhood"
                              value={this.props.shipping.deliverySuggestion}
                              onChange={(ev) =>
                                this.handleChange(
                                  "shipping",
                                  "deliverySuggestion",
                                  ev
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {this.props.shipping.type === ShippingType.Unity && (
                      <div>
                        <div className="col-xs-12 form-group unity">
                          <label className="text">Unidade *</label>
                          <select
                            name="unidade"
                            value={this.props.shipping.unityId}
                            onChange={this.handleUnityChange}
                          >
                            <option value="">Selecione uma unidade</option>
                            {this.props.units.map((u) => (
                              <option key={u.id} value={u.id}>
                                {u.estado} - {u.empresa} - {u.descricao}
                              </option>
                            ))}
                          </select>
                        </div>
                        {this.state.showDriveThru && (
                          <div className="col-xs-12">
                            <h3 className="title drive-thru-title">
                              Como gostaria de realizar a retirada?
                            </h3>

                            <div className="col-xs-12 form-group radios radios-drive-thru">
                              <label className="radio-inline text">
                                <input
                                  type="radio"
                                  name="driveThru"
                                  value={false}
                                  checked={!this.props.shipping.driveThru}
                                  onChange={this.handleDriveThruChange}
                                />{" "}
                                Dentro da unidade
                              </label>
                              <label className="radio-inline text">
                                <input
                                  type="radio"
                                  name="driveThru"
                                  value={true}
                                  checked={this.props.shipping.driveThru}
                                  onChange={this.handleDriveThruChange}
                                />{" "}
                                Serviço drive-thru
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="col-xs-12">
                          <a
                            className="text unity-link"
                            href={
                              this.props.loggedUser.endereco.estado === "SP"
                                ? "http://www.labhormon.com.br/nossas-unidades/"
                                : "https://saomarcoslaboratorio.com.br/br/p/206/unidades.aspx"
                            }
                            target="_blank"
                          >
                            Endereço das unidades
                          </a>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 className="title">
                      Quem será o beneficiário da compra?
                    </h3>

                    <div className="col-xs-12 form-group radios">
                      <label className="radio-inline text">
                        <input
                          type="radio"
                          name="receiver"
                          value={ReceiverType.Buyer}
                          checked={
                            this.props.receiver.type === ReceiverType.Buyer
                          }
                          onChange={this.handleReceiverTypeChange}
                        />{" "}
                        O próprio comprador
                      </label>
                      <label className="radio-inline text">
                        <input
                          type="radio"
                          name="reveiver"
                          value={ReceiverType.Other}
                          checked={
                            this.props.receiver.type === ReceiverType.Other
                          }
                          onChange={this.handleReceiverTypeChange}
                        />{" "}
                        Outra pessoa
                      </label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                      <label className="text">Nome *</label>
                      <input
                        type="text"
                        name="name"
                        value={this.props.receiver.name}
                        onChange={(ev) =>
                          this.handleChange("receiver", "name", ev)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                      <label className="text">CPF *</label>
                      <InputMask
                        type="text"
                        placeholder="999.999.999-99"
                        readOnly={
                          this.props.receiver.dontHaveCpf ||
                          this.props.receiver.type === ReceiverType.Buyer
                        }
                        mask="999.999.999-99"
                        alwaysShowMask={false}
                        maskChar=""
                        name="cpf"
                        value={this.props.receiver.cpf}
                        onChange={(ev) =>
                          this.handleChange("receiver", "cpf", ev)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                      <label className="text">Data de Nascimento *</label>
                      <InputMask
                        type="text"
                        placeholder="DD/MM/AAAA"
                        mask="99/99/9999"
                        alwaysShowMask={false}
                        maskChar=""
                        name="birthday"
                        value={this.props.receiver.birthday}
                        onChange={(e) =>
                          this.handleChange("receiver", "birthday", e)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                      <label className="text">Sexo *</label>
                      <select
                        name="genero"
                        value={this.props.receiver.genre}
                        onChange={(ev) =>
                          this.handleChange("receiver", "genre", ev)
                        }
                      >
                        <option value="">Selecione</option>
                        <option value="Masculino">Masculino</option>
                        <option value="Feminino">Feminino</option>
                      </select>
                    </div>

                    {this.props.receiver.type === ReceiverType.Other && (
                      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12 form-group">
                        <label className="text">Grau Parentesco</label>
                        <input
                          type="text"
                          name="grau"
                          value={this.props.receiver.relationship}
                          onChange={(ev) =>
                            this.handleChange("receiver", "relationship", ev)
                          }
                        />
                      </div>
                    )}

                    {this.props.receiver.type === ReceiverType.Other && (
                      <div className="col-xs-12 form-group">
                        <div className="checkbox">
                          <label className="text">
                            <input
                              type="checkbox"
                              name="naoPossuiCpf"
                              onChange={this.handleReceiverDontHaveCpfChange}
                            />{" "}
                            Recebedor não possui CPF
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="col-xs-12 policy">
                      <div className="checkbox">
                        <label className="text">
                          <input
                            type="checkbox"
                            checked={this.props.termsAccepted}
                            onChange={this.handleTermsAcceptedChange}
                          />{" "}
                          Li e aceito os{" "}
                          <Link to="/termos" className="text" target="_blank">
                            Termos de Compra e Aplicação.
                          </Link>
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-12 policy">
                      <div className="checkbox">
                        <label className="text">
                          <input
                            type="checkbox"
                            checked={this.props.termsPrivacyAccepted}
                            onChange={this.handleTermsPrivacyAcceptedChange}
                          />{" "}
                          Li e aceito os{" "}
                          <Link
                            to="/termos-privacidade"
                            className="text"
                            target="_blank"
                          >
                            Termos de Privacidade.
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12 btn-container text-center">
                    <button
                      type="button"
                      className="btn btn-submit checkout back"
                      onClick={this.redirectToHome}
                    >
                      <img
                        src="/img/icons/arrow-back.png"
                        srcSet="/img/icons/arrow-back@2x.png 2x, /img/icons/arrow-back@3x.png 3x"
                        className="pull-left"
                      />
                      CONTINUAR COMPRANDO
                    </button>
                    <button type="submit" className="btn btn-submit checkout">
                      EFETUAR PAGAMENTO
                      <img
                        src="/img/icons/arrow-send.png"
                        srcSet="/img/icons/arrow-send@2x.png 2x, /img/icons/arrow-send@3x.png 3x"
                        className="pull-right"
                      />
                    </button>
                  </div>
                </form>
              )}
              {!this.props.loggedUser && (
                <div className="col-xs-12 btn-container text-center">
                  <button
                    type="button"
                    className="btn btn-submit checkout back"
                    onClick={this.redirectToHome}
                  >
                    <img
                      src="/img/icons/arrow-back.png"
                      srcSet="/img/icons/arrow-back@2x.png 2x, /img/icons/arrow-back@3x.png 3x"
                      className="pull-left"
                    />
                    CONTINUAR COMPRANDO
                  </button>
                  <button
                    type="button"
                    className="btn btn-submit checkout"
                    onClick={this.redirectToLogin}
                  >
                    FINALIZAR COMPRA
                    <img
                      src="/img/icons/arrow-send.png"
                      srcSet="/img/icons/arrow-send@2x.png 2x, /img/icons/arrow-send@3x.png 3x"
                      className="pull-right"
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ basket, order, auth }) => {
  const { loggedUser } = auth;
  return {
    ...basket,
    ...order,
    loggedUser,
  };
};

export default connect(
  mapStateToProps,
  {
    ...OrderActions,
    removeProductFromBasket,
  }
)(Basket);
