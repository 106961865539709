import ActionTypes from '../actions/ActionTypes';

const initialState = {
  categories: [],
  categoryProducts: [],
  category: {
    id: null,
    descricao: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_CATEGORIES_SUCCESS: 
      return { ...state, categories: action.payload };
    case ActionTypes.GET_CATEGORY_PRODUCTS_SUCCESS:
      return { ...state, categoryProducts: action.payload };
    case ActionTypes.GET_CATEGORY_PRODUCTS_FAIL:
      return { ...initialState }
    case ActionTypes.SORT_PRODUCTS_CHANGED:
      return { ...state, categoryProducts: action.payload }
    case ActionTypes.GET_CATEGORY_DETAILS_SUCCESS:
      return { ...state, category: action.payload }
    default:
      return state;
  }
};
