import WebApi from './WebApi';
import moment from 'moment';
import { ShippingType } from '../constants';

export class SchedulingService {
  static async searchSchedulingDay(state, doctorId) {
    const response = await WebApi.get(`/agendamentos/${state}/datas`, {
      codigoMedico: doctorId,
    });
    return response.data;
  }

  static async searchSchedulingHour(date, state, doctorId) {
    const response = await WebApi.get(`/agendamentos/${state}/horarios`, {
      codigoMedico: doctorId,
      data: moment(date).format('YYYY-MM-DD'),
    });
    return response.data;
  }

  static async saveScheduling(hour, order) {
    const response = await WebApi.post(
      `/agendamentos/${
        order.tipoEntrega === ShippingType.Unity
          ? order.unidade.estado
          : order.enderecoEntrega.estado
      }`,
      {
        codigoMedico: hour.codigoMedico,
        local: hour.local,
        horario: hour.horario,
        idPedido: order.id,
        driveThru: order.driveThru,
      }
    );
    return response.data;
  }
}
