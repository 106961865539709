import { Messages } from '../../constants';

const validation = {
  schedulingDate: {
    presence: {
      message: Messages.RequiredDateValidationMessage,
      allowEmpty: false
    }
  },
  schedulingHour: {
    presence: {
      message: Messages.RequiredHourValidationMessage,
      allowEmpty: false
    }
  }
};

export default validation;
