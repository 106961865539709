import { Messages } from '../../constants';

const validation = {
  code: {
    presence: {
      message: Messages.RequiredPasswordRecoveryCodeValidationMessage,
      allowEmpty: false
    },
    numericality: {
      onlyInteger: true,
      message: Messages.NumericPasswordRecoveryCodeValidationMessage,
    }
  }
};

export default validation;
