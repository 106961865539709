import { create } from 'apisauce';
import Alert from 'react-s-alert';
import { push } from 'connected-react-router';
import { AuthenticationService } from '.';
import { Messages } from '../constants';

const WebApi = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000
});

const handleResponse = (response) => {
  if (!response.ok) throw response;
};

const handleRequest = (request) => async () => {
  const token = await AuthenticationService.getAuthToken();
  if (token && !(await AuthenticationService.userIsLogged())) {
    push('/login');
    Alert.error(Messages.LoginExpiredMessage);
  }
  if (token) request.headers.Authorization = `Bearer ${token}`;
};

WebApi.addResponseTransform(handleResponse);

WebApi.addAsyncRequestTransform(handleRequest);

export default WebApi;

