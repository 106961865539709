export const Messages = {
  LoginExpiredMessage:
    "O seu login expirou. Por favor, informe novamente as suas credenciais.",
  InternalServerError:
    "Não conseguimos conectá-lo. Verifique a sua conexão com a internet.",
  PasswordRecoveryEmailSentMessage:
    "Verifique o seu e-mail para obter o código de recuperação de senha.",
  PasswordRecoveryCodeSuccessValidatedMessage:
    "Informe a sua nova senha e a confirmação para redefinir sua credencial.",
  PasswordSuccessRecoveredMessage:
    "Senha redefinida com sucesso. Utilize a nova senha para realizar o login.",
  UserSuccessRegisteredMessage: "Cadastro realizado com sucesso!",
  ContactSuccessSentMessage:
    "A sua mensagem foi enviada com sucesso. Em breve, retornaremos o seu contato. Muito obrigado!",
  RequiredEmailValidationMessage: "^Informe o seu e-mail.",
  RequiredPasswordValidationMessage: "^Informe a sua senha.",
  RequiredPasswordRecoveryCodeValidationMessage:
    "^Informe o código de recuperação de senha enviado para seu e-mail.",
  NumericPasswordRecoveryCodeValidationMessage:
    "^O código de recuperação de senha deve conter apenas números.",
  RequiredPhoneValidationMessage: "^Informe o seu telefone.",
  RequiredMessageValidationMessage:
    "^Informe a sua mensagem. Nós queremos te ouvir!",
  RequiredNameValidationMessage: "^Informe o seu nome.",
  InvalidEmailValidationMessage: "^Informe um e-mail válido.",
  RequiredPasswordConfirmationValidationMessage:
    "^Informe a confirmação de senha.",
  InvalidConfirmationPasswordValidationMessage: "^As senhas não conferem.",
  InvalidCpfValidationMessage: "^Informe um CPF válido.",
  RequiredCityValidationMessage: "^Informe a sua cidade.",
  RequiredStateValidationMessage: "^Informe o seu estado.",
  RequiredAddressValidationMessage: "^Informe o seu endereço.",
  RequiredCepValidationMessage: "^Informe o seu CEP.",
  RequiredCpfValidationMessage: "^Informe o seu CPF.",
  RequiredAcceptTermsValidationMessage:
    "^Você precisa aceitar a política de compras.",
  RequiredBasketAcceptTermsValidationMessage:
    "^Para prosseguir, você precisa ler e aceitar a política de compras.",
  RequiredBasketAcceptTermsPrivacyValidationMessage:
    "^Para prosseguir, você precisa ler e aceitar a política de privacidade.",
  RequiredReceiverNameValidationMessage: "^Informe o nome do recebedor.",
  RequiredReceiverAgeValidationMessage: "^Informe a idade do recebedor.",
  RequiredReceiverCpfValidationMessage: "^Informe o CPF do recebedor.",
  RequiredReceiverGenreValidationMessage: "^Informe o sexo do recebedor.",
  VerifyProductAvailabilityMessage:
    "Há produtos indisponíveis para a unidade selecionada. Por favor, verifique antes de prosseguir.",
  RequiredShippingAddressValidationMessage: "^Informe o endereço de aplicação.",
  RequiredShippingCityValidationMessage:
    "^Informe a cidade do endereço de aplicação.",
  RequiredShippingStateValidationMessage:
    "^Informe estado do endereço de aplicação.",
  RequiredShippingCepValidationMessage:
    "^Informe o CEP do endereço de aplicação.",
  RequiredShippingNeighborhoodValidationMessage:
    "^Informe o bairro do endereço de aplicação.",
  RequiredShippingUnityValidationMessage:
    "^Selecione a unidade para aplicação.",
  RequiredShippingDeliverySuggestionValidationMessage:
    "Informe a sugestão de dia e horário de entrega.",
  VerifyZipCodeMessage: "Infelizmente, não atendemos no CEP informado.",
  ShippingTaxAddedMessage:
    "Uma taxa para aplicação domiciliar será adicionada ao seu pedido após você informar seu endereço.",
  RequiredCardNameValidationMessage: "^Informe o nome impresso no cartão.",
  RequiredCardNumberValidationMessage: "^Informe o número do cartão.",
  RequiredCardExpirationYearValidationMessage:
    "^Informe o ano de expiração do cartão.",
  RequiredCardExpirationMonthValidationMessage:
    "^Informe o mês de expiração do cartão.",
  RequiredCardSecurityCodeValidationMessage:
    "^Informe o código de segurança do cartão.",
  RequiredCardBrandValidationMessage: "^Clique sobre a bandeira do seu cartão.",
  RequiredReceiverTypeValidationMessage:
    "^Quem receberá a vacina será o comprador ou outra pessoa?",
  OrderCreatedSuccessMessage:
    "Olá, nós recebemos o seu pagamento. Fique atento às observações do seu pedido.",
  UserProfileUpdatedSuccessMessage:
    "Os seus dados foram atualizados com sucesso!",
  RequiredNumberValidationMessage: "^Informe o número da sua residência.",
  RequiredCellPhoneValidationMessage: "^Informe o seu telefone celular.",
  RequiredBirthdayValidationMessage: "^Informe a sua data de nascimento.",
  InvalidBirthdayValidationMessage: "^Informe uma data de nascimento válida.",
  RequiredReceiverBirthdayValidationMessage:
    "^Informe a data de nascimento do recebedor.",
  RequiredGenreValidationMessage: "^Informe o seu sexo.",
  RequiredNeighborhoodValidationMessage: "^Informe o seu bairro.",
  RequiredBackOfficeSearchCpfValidationMessage:
    "^É necessário informar um CPF para filtrar os pedidos.",
  BackOfficeOrdersNotFound:
    "Não foram encontrados pedidos para o CPF informado.",
  OrderStatusUpdatedSuccessMessage:
    "O status do pedido foi atualizado com sucesso.",
  OrderServiceOrderUpdatedSuccessMessage:
    "A ordem de serviço do pedido foi atualizada com sucesso.",
  RequiredOrderServiceOrderValidationMessage: "Informe a ordem de serviço.",
  RequiredMotherNameValidationMessage: "^Informe o nome da mãe.",
  RequiredRgValidationMessage: "^Informe o número do RG.",
  ExtensionPdfOnly: "Extensão não permitida. Informe um arquivo PDF.",
  RequiredPriceValidationMessage: "^Informe o preço.",
  RequiredActiveValidationMessage: "^Informe o status.",
  RequiredAdverseEventsValidationMessage: "^Informe os eventos adversos.",
  RequiredApplicationSchemaValidationMessage:
    "^Informe o esquema de aplicação.",
  RequiredContraindicationValidationMessage: "^Informe as contraindicações.",
  RequiredIndicationValidationMessage: "^Informe a indicação.",
  RequiredErpCodeValidationMessage: "^Informe o código do erp.",
  UnitsNotFound: "Infelizmente não temos nenhuma unidade em seu estado.",
  SchedulingNoHourAvailable:
    "Nenhum horário disponível para a data selecionada. Por favor, selecione outra data.",
  RequiredDateValidationMessage: "^Selecione a data do agendamento.",
  RequiredHourValidationMessage: "^Selecione o horário do agendamento.",
  SchedulingCannotBeSaved:
    "Não foi possível agendar o horário selecionado. Entraremos em contato via telefone para marcar o agendamento.",
};
