import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/AuthActions';
import {
  updateProductSearchField,
  redirectToProductSearch,
} from '../../actions/ProductSearchActions';
import {
  listUserOrders,
  redirectToUserOrders,
  redirectToProfileEdit,
} from '../../actions/UserPageActions';
import { resetUserRegisterForm } from '../../actions/UserRegisterActions';
import './style.scss';
import Loader from '../Loader';
import { UserProfile } from '../../constants';

class Header extends Component {
  state = {
    searchOpened: false,
    searchTerm: '',
  };

  constructor(props) {
    super(props);
    this.logoutUser = this.logoutUser.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  handleSearchTermChange = (event) => {
    const { value } = event.target;
    this.setState({ searchTerm: value });
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.props.redirectToProductSearch(this.state.searchTerm);
    this.setState({ searchTerm: '' });
  };

  logoutUser = () => this.props.logout();

  redirectToUserOrders = () => {
    this.props.redirectToUserOrders();
  };

  redirectToProfileEdit = () => {
    this.props.redirectToProfileEdit(this.props.loggedUser);
  };

  render = () => {
    const { loggedUser } = this.props;
    return (
      <header className='header'>
        <Loader />
        <nav className='navbar navbar-default'>
          <div className='container'>
            <div className='navbar-header'>
              <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                data-target='#navbar-collapse'
                aria-expanded='false'
              >
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar' />
                <span className='icon-bar' />
                <span className='icon-bar' />
              </button>
              <Link className='navbar-brand' to='/'>
                <img
                  alt='Logo'
                  src='/img/logo-total.png'
                  width='290px'
                  height='50px'
                  className='logo'
                />
              </Link>
            </div>

            <div className='collapse navbar-collapse' id='navbar-collapse'>
              <ul className='nav navbar-nav left-nav'>
                <li>
                  <Link to='/vacinas/categoria/10/Analise-Clinica'>
                    <img
                      src='/img/icons/analise-clinica.png'
                      srcSet='/img/icons/analise-clinica@2x.png 2x,/img/icons/analise-clinica@3x.png 3x'
                      className='icon hidden-xs'
                    />
                    <span className='text'>Análises Clínicas</span>
                  </Link>
                </li>
                <li>
                  <Link to='/vacinas/categoria/1/Vacinas'>
                    <img
                      src='/img/icons/syringe.png'
                      srcSet='/img/icons/syringe@2x.png 2x,/img/icons/syringe@3x.png 3x'
                      className='icon hidden-xs'
                    />
                    <span className='text'>Vacinas</span>
                  </Link>
                </li>
                <li>
                  <Link to='/contato'>
                    <img
                      src='/img/icons/contact.png'
                      srcSet='/img/icons/contact@2x.png 2x,/img/icons/contact@3x.png 3x'
                      className='icon hidden-xs'
                    />
                    <span className='text'>Contato</span>
                  </Link>
                </li>
              </ul>
              <ul className='nav navbar-nav navbar-right'>
                {!loggedUser && (
                  <li
                    className={
                      this.state.searchOpened ? 'hidden visible-xs' : ''
                    }
                  >
                    <Link to='/login'>
                      <img
                        src='/img/icons/user.png'
                        srcSet='/img/icons/user@2x.png 2x,/img/icons/user@3x.png 3x'
                        className='icon hidden-xs'
                      />
                      <span className='text'>Login</span>
                    </Link>
                  </li>
                )}
                {loggedUser && (
                  <li
                    className={`dropdown ${
                      this.state.searchOpened ? 'hidden visible-xs' : ''
                    }`}
                  >
                    <Link
                      to='/login'
                      className='dropdown-toggle'
                      data-toggle='dropdown'
                      role='button'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      {loggedUser.idFotoPerfil && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/arquivos/${
                            loggedUser.idFotoPerfil
                          }`}
                          className='icon avatar hidden-xs'
                        />
                      )}
                      {!loggedUser.idFotoPerfil && (
                        <img
                          src='/img/icons/user.png'
                          srcSet='/img/icons/user@2x.png 2x,/img/icons/user@3x.png 3x'
                          className='icon hidden-xs'
                        />
                      )}

                      <span className='text'>
                        Olá, {this.props.loggedUser.nome}!{' '}
                        <span className='caret' />
                      </span>
                    </Link>
                    <ul className='dropdown-menu'>
                      <li>
                        <a
                          href='javascript:'
                          onClick={this.redirectToProfileEdit}
                        >
                          Meus Dados
                        </a>
                      </li>
                      <li>
                        <a
                          href='javascript:'
                          onClick={this.redirectToUserOrders}
                        >
                          Meus Pedidos
                        </a>
                      </li>
                      {loggedUser.perfil.codigo === UserProfile.Admin && (
                        <li>
                          <Link to='/backoffice/pedidos'>
                            Gerenciar Pedidos
                          </Link>
                        </li>
                      )}
                      {loggedUser.perfil.codigo === UserProfile.Admin && (
                        <li>
                          <Link to='/backoffice/produtos'>
                            Gerenciar Produtos
                          </Link>
                        </li>
                      )}
                      {loggedUser.perfil.codigo === UserProfile.Admin && (
                        <li>
                          <Link to='/backoffice/categorias'>
                            Ordenar Produtos
                          </Link>
                        </li>
                      )}
                      <li>
                        <a href='javascript:' onClick={this.logoutUser}>
                          Sair
                        </a>
                      </li>
                    </ul>
                  </li>
                )}

                <li
                  className={`basket ${
                    this.state.searchOpened ? 'hidden visible-xs' : ''
                  }`}
                >
                  <Link to='/pedido'>
                    <img
                      src='/img/icons/basket.png'
                      srcSet='/img/icons/basket@2x.png 2x,/img/icons/basket@3x.png 3x'
                      className='icon hidden-xs'
                    />
                    <span className='badge'>
                      {this.props.basket.produtos.length}
                    </span>
                    <span className='text hidden visible-xs'>Pedido</span>
                  </Link>
                </li>
                <li>
                  <a
                    className={`hidden-xs ${
                      this.state.searchOpened ? 'hidden' : 'visible'
                    }`}
                    href='javascript:'
                    onClick={() => this.setState({ searchOpened: true })}
                  >
                    <img
                      src='/img/icons/search.png'
                      srcSet='/img/icons/search@2x.png 2x,/img/icons/search@3x.png 3x'
                      className='icon hidden-xs'
                    />
                    <span className='text hidden visible-xs'>Busca</span>
                  </a>
                  <form
                    className={`search-form visible-xs ${
                      this.state.searchOpened
                        ? 'visible-sm visible-md visible-lg'
                        : ''
                    }`}
                    onSubmit={this.handleSearchSubmit}
                  >
                    <input
                      type='text'
                      name='search'
                      value={this.state.searchTerm}
                      onChange={this.handleSearchTermChange}
                      onBlur={() => this.setState({ searchOpened: false })}
                    />
                    <button className='btn' type='submit'>
                      <img
                        src='/img/icons/search-2.png'
                        srcSet='/img/icons/search-2@2x.png 2x,/img/icons/search-2@3x.png 3x'
                      />
                    </button>
                  </form>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  };
}

const mapStateToProps = ({ basket, auth, productSearch }) => {
  const { loggedUser } = auth;
  const { searchTerm } = productSearch;
  return {
    basket,
    loggedUser,
    searchTerm,
  };
};

export default connect(
  mapStateToProps,
  {
    logout,
    updateProductSearchField,
    redirectToProductSearch,
    listUserOrders,
    redirectToUserOrders,
    resetUserRegisterForm,
    redirectToProfileEdit,
  }
)(Header);
