import { Messages } from '../../constants';

const validation = {
  email: {
    presence: {
      message: Messages.RequiredEmailValidationMessage,
      allowEmpty: false
    }
  }
};

export default validation;
