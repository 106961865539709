import { Messages } from '../../constants';

const validation = {
  type: {
    presence: {
      message: Messages.RequiredReceiverTypeValidationMessage,
      allowEmpty: false
    }
  },
  name: {
    presence: {
      message: Messages.RequiredReceiverNameValidationMessage,
      allowEmpty: false
    }
  },
  birthday: {
    presence: {
      message: Messages.RequiredReceiverBirthdayValidationMessage,
      allowEmpty: false
    },
    brDate: {
      message: Messages.InvalidBirthdayValidationMessage,
    }
  },
  genre: {
    presence: {
      message: Messages.RequiredReceiverGenreValidationMessage,
      allowEmpty: false
    }
  },
  
  cpf: {
    presence: (value, attributes) => {
      if (!attributes.dontHaveCpf) {
        return { 
          message: Messages.RequiredReceiverCpfValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    },
    cpf: {
      message: Messages.InvalidCpfValidationMessage
    }
  }
};

export default validation;