import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../components';
import { requestPasswordRecovery, updateLoginField } from '../../actions/AuthActions';
import { ValidationService } from '../../services';
import validation from './validation';
import './style.scss';

class ForgotPassword extends Component {
  
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formIsValid = () => ValidationService.validate(this.props, validation);

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updateLoginField({ prop, value });
  };

  handleSubmit = (event) => {
    const { email } = this.props;
    event.preventDefault();
    if (!this.formIsValid()) return;

    this.props.requestPasswordRecovery(email);
  };

  render = () => (
    <div className="forgot-password">
      <Header />
      <NavigationBar title="Recuperação de Senha" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/login', title: 'Login' },
        { to: '/esqueci-minha-senha', title: 'Esqueci Minha Senha' },
      ]}/>
      <div className="gray-container">
        <div className="container">
          <p className="text text-center page-description">Informe o seu e-mail de cadastro para receber o código de recuperação de senha.</p>
          <div className="form-container">
            <form className="login-form" onSubmit={this.handleSubmit}>
              <h3 className="title text-center bold">Esqueci Minha Senha</h3>
              <div className="form-group">
                <label className="text bold">E-mail:</label>
                <input type="text" name="email" autoComplete="off" value={this.props.email} onChange={e => this.handleChange('email', e)}/>
              </div>
              <button type="submit" className="btn bold">ENVIAR</button>
              <div className="text-center">
                <Link to="/login" className="forgot-password bold">Voltar para o Login</Link>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link to="/cadastro" className="register">CRIAR UMA CONTA</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}

const mapStateToProps = ({ auth }) => {
  return { 
    ...auth
  };
};

export default connect(
  mapStateToProps,
  { requestPasswordRecovery, updateLoginField }
)(ForgotPassword);