import ActionTypes from "./ActionTypes";
import { NotificationService } from "../services";
import Alert from "react-s-alert";
import { ContactService } from "../services";
import { Messages } from "../constants";

export const sendContact = contactData => async dispatch => {
  try {
    dispatch({ type: ActionTypes.SEND_CONTACT });
    const { email } = contactData;
    const contact = {
      email,
      estado: contactData.state,
      nome: contactData.name,
      telefone: contactData.phone,
      mensagem: contactData.message
    };
    await ContactService.send(contact);
    dispatch({ type: ActionTypes.SEND_CONTACT_SUCCESS });
    Alert.success(Messages.ContactSuccessSentMessage);
  } catch (e) {
    dispatch({ type: ActionTypes.SEND_CONTACT_FAIL });
    NotificationService.showApiResponseErrorAlert(e);
  }
};

export const updateContactField = ({ prop, value }) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_CONTACT_FIELD,
    payload: { prop, value }
  });
};
