import React, { Component } from 'react';
import { ValidationService } from "../../services";
import validation from "./validation";
import moment from 'moment';
import { 
  searchSchedulingDay,
  handleChangeSchedulingDay,
  skipSchedulinStep,
  handleChangeSchedulingHour,
  saveScheduling,
  fillScheduleFromOrder
} from '../../actions/OrderActions';
import { connect } from 'react-redux';
import { 
  Header, 
  Footer,
  NavigationBar,
  CheckoutSteps,
} from '../../components';
import {
  ShippingType,
} from '../../constants';
import './style.scss';

class Scheduling extends Component {

  componentDidMount = () => {
    if (this.props.order)
      this.props.searchSchedulingDay(this.props.order);
    else {
      const {
        match: {
          params: { orderId }
        }
      } = this.props;
      if ((this.props.order || {}).id !== orderId)
        this.props.fillScheduleFromOrder(orderId);
      }
  };

  handleChangeSchedulingDay = event => {
    const state = this.props.order.tipoEntrega === ShippingType.Unity ? this.props.order.unidade.estado : this.props.order.enderecoEntrega.estado;
    this.props.handleChangeSchedulingDay(event.target.value, state, this.props.doctorId);
  }

  handleChangeSchedulingHour = event => {
    this.props.handleChangeSchedulingHour(event.target.value);
  }

  skipStep = () => {
    this.props.skipSchedulinStep(this.props.order.id);
  }

  handleSubmit = event => {
    event.preventDefault();
    if (!this.formIsValid()) return;
    this.props.saveScheduling(this.props.order, this.props.scheduling);
  };

  formIsValid = () =>
    ValidationService.validate({ schedulingDate: this.props.schedulingDate, schedulingHour: this.props.schedulingHour }, validation);

  render = () => (
    <div className="scheduling">
      <Header />
      <NavigationBar title="Pedido" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/pedido', title: 'Pedido' },
        { to: '/pagamento', title: 'Pagamento' },
        { to: '/agendamento', title: 'Agendamento' },
      ]}/>
      <CheckoutSteps 
        basket 
        payment
        products={this.props.order ? this.props.order.produtos : []}
      />
      <div className="gray-container">
        <div className="container">
          <div className="row">
            <form onSubmit={this.handleSubmit}>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 select-box">
                <h3 className="title">Selecione data e hora desejados para a coleta</h3>
                <div className="col-xs-4 form-group select-item">
                  <label className="text">Data *</label>
                  <select name="day" onChange={this.handleChangeSchedulingDay}>
                    <option value="">SELECIONE</option>
                    {this.props.schedulingDays.map(d => (
                      <option key={d} value={d}>{moment(d).format("DD/MM/YYYY")}</option>
                    ))}
                  </select>
                </div>
                <div className="col-xs-4 form-group select-item">
                  <label className="text">Horário *</label>
                  <select value={this.props.schedulingHour} name="hour" disabled={!this.props.schedulingDate || this.props.schedulingHoursOptions.length <= 0} onChange={this.handleChangeSchedulingHour}>
                    <option value="">SELECIONE</option>
                    {this.props.schedulingHoursOptions.map(h => (
                      <option key={h} value={h}>{moment(h).format("HH:mm")}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-xs-12 btn-container actions">
                <button type="button" className="btn btn-submit checkout back" onClick={this.skipStep}>
                  PULAR ETAPA
                </button>
                <button type="submit" className="btn btn-submit checkout">
                  REALIZAR AGENDAMENTO
                  <img src="/img/icons/arrow-send.png" srcSet="/img/icons/arrow-send@2x.png 2x, /img/icons/arrow-send@3x.png 3x" className="pull-right"/>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ receipt, auth, order }) => {
  const { loggedUser } = auth;
  return {
    ...receipt,
    schedulingDays: order.schedulingDays,
    schedulingHoursOptions: order.schedulingHoursOptions,
    schedulingDate: order.schedulingDate,
    schedulingHour: order.schedulingHour,
    scheduling: order,
    doctorId: order.doctorId,
    loggedUser,
  };
};

export default connect(
  mapStateToProps,
  { searchSchedulingDay, handleChangeSchedulingDay, skipSchedulinStep, handleChangeSchedulingHour, saveScheduling, fillScheduleFromOrder }
)(Scheduling);
