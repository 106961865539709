import WebApi from './WebApi';

export class CategoryService {

  static async listMenu() {
    const response = await WebApi.get(`/categorias`, { vitrine: false });
    return response.data;
  }
  
  static async list() {
    const response = await WebApi.get(`/categorias`);
    return response.data;
  }

  static async listWithProducts() {
    const response = await WebApi.get(`/categorias`, { comProduto: true });
    return response.data;
  }

  static async getCategoryProducts(categoryId) {
    const response = await WebApi.get(`/categorias/${categoryId}/produtos`);
    return response.data;
  }

  static async get(categoryId) {
    const response = await WebApi.get(`/categorias/${categoryId}`);
    return response.data;
  }

  static async getPrincipal() {
    const response = await WebApi.get(`/categorias`, { vitrine: true });
    return response.data;
  }

  static async saveSort(categoryId, products) {
    const response = await WebApi.put(`/categorias/${categoryId}/ordenacao`, { categoriaProduto: products });
    return response.data;
  }
}