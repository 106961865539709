import { Messages, ShippingType } from '../../constants';

const validation = {
  cardName: {
    presence: { 
      message: Messages.RequiredCardNameValidationMessage,
      allowEmpty: false
    }
  },
  cardNumber: {
    presence: { 
      message: Messages.RequiredCardNumberValidationMessage,
      allowEmpty: false
    },
  },
  securityCode: {
    presence: { 
      message: Messages.RequiredCardSecurityCodeValidationMessage,
      allowEmpty: false
    }
  },
  expirationYear: {
    presence: {
      message: Messages.RequiredCardExpirationYearValidationMessage,
      allowEmpty: false
    }
  },
  expirationMonth: {
    presence: {
      message: Messages.RequiredCardExpirationMonthValidationMessage,
      allowEmpty: false
    }
  },
  brand: {
    presence:{ 
        message: Messages.RequiredCardBrandValidationMessage,
        allowEmpty: false
    }
  },
};

export default validation;