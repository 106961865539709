import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import InputMask from 'react-input-mask';
import * as OrderActions from '../../actions/OrderActions';
import { 
  Header, 
  Footer,
  NavigationBar,
  CheckoutSteps
} from '../../components';
import './validation';
import './style.scss';
import { ValidationService } from '../../services';
import validation from './validation';
import { ShippingType, CardType } from '../../constants';

class Payment extends Component {

  constructor(props) {
    super(props);
    this.handleParcelNumberChange = this.handleParcelNumberChange.bind(this);
  }

  handleChange = (keyFirstLevel, keySecondLevel, event) => {
    const { value } = event.target;
    const prop = this.props[keyFirstLevel];
    this.props.updateOrderField({ prop: keyFirstLevel, value: { ...prop, [keySecondLevel]: value }});
  };

  handleParcelNumberChange = (event) => {
    const parcel = this.props.parcels.find(p => p.number === parseInt(event.target.value, 10));
    const { value, number } = parcel;
    this.props.updateOrderField({ 
      prop: 'payment', 
      value: { ...this.props.payment, parcels: number, parcelValue: value }
    });
  };
  
  componentDidUpdate = () => {
    if (!this.props.canProcceed || 
        (this.props.shipping.type === ShippingType.Residence && !this.props.zipCodeValidated))  {
      this.props.redirectToBasket();
    }
  };

  formIsValid = () => ValidationService.validate(this.props.payment, validation);

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.paymentInProgress || !this.formIsValid()) return;
    this.props.payOrder(this.props);
  };

  changeBrand = (brand) => {
    this.props.changeBrand(brand);
  };

  render = () => (
    <div className="payment">
      <Header />
      <NavigationBar title="Pagamento" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/pedido', title: 'Pedido' },
        { to: '/pagamento', title: 'Pagamento' },
      ]}/>
      <CheckoutSteps 
        basket
        payment 
        products={this.props.basket.produtos}
      />
      <div className="gray-container">
        <div className="container">
          <div className="form-container">
            <form method="POST" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center">
                {CardType.map((brand, key) => <img key={key} className={"brand " + (this.props.payment.brand && this.props.payment.brand !== brand.name ? 'unchecked' : '')} src={brand.src} onClick={() => this.changeBrand(brand.name)} />)}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center">
                <p className="text small">Clique sobre a bandeira para selecionar</p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <label className="text primary">NÚMERO DO CARTÃO</label>
                <InputMask type="text" placeholder="9999.9999.9999.9999" mask="9999.9999.9999.9999" alwaysShowMask={false} maskChar="" name="cardNumber" value={this.props.payment.cardNumber} onChange={(ev) => this.handleChange('payment', 'cardNumber', ev)} />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <label className="text primary">NOME IMPRESSO NO CARTÃO</label>
                <input type="text" name="cardName" value={this.props.payment.cardName} onChange={(ev) => this.handleChange('payment', 'cardName', ev)}/>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label className="text primary">MÊS DO VENCIMENTO</label>
                <select name="expirationMonth" value={this.props.payment.expirationMonth} onChange={ev => this.handleChange('payment', 'expirationMonth', ev)}>
                  <option value="">Selecione</option>
                  <option value="01">Janeiro</option> 
                  <option value="02">Fevereiro</option> 
                  <option value="03">Março</option> 
                  <option value="04">Abril</option> 
                  <option value="05">Maio</option> 
                  <option value="06">Junho</option> 
                  <option value="07">Julho</option> 
                  <option value="08">Agosto</option> 
                  <option value="09">Setembro</option> 
                  <option value="10">Outubro</option> 
                  <option value="11">Novembro</option> 
                  <option value="12">Dezembro</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                <label className="text primary">ANO DO VENCIMENTO</label>
                <InputMask type="text" mask="9999" placeholder="XXXX" alwaysShowMask={false} maskChar="" name="expirationYear" value={this.props.payment.expirationYear} onChange={ev => this.handleChange('payment', 'expirationYear', ev)}/>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <label className="text primary">CÓDIGO DE SEGURANÇA</label>
                <InputMask type="text" mask="9999" alwaysShowMask={false} maskChar="" name="securityCode" value={this.props.payment.securityCode} onChange={(ev) => this.handleChange('payment', 'securityCode', ev)}/>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <label className="text primary">NÚMERO DE PARCELAS</label>
                <select name="parcelas" value={this.props.payment.parcels} onChange={this.handleParcelNumberChange}>
                  {this.props.parcels.map(p => <option key={p.number} value={p.number}>{p.number}x s/juros</option>)}
                </select>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <p className="text text-center">Valor parcelado: 
                    <span className="bold"> {this.props.payment.parcels}x 
                      <NumberFormat 
                        value={this.props.payment.parcelValue || (this.props.shipping.tax + this.props.basket.valorProdutos)} 
                        displayType={'text'}
                        decimalSeparator={','} 
                        thousandSeparator={'.'} 
                        prefix={' R$ '} 
                        fixedDecimalScale
                        decimalScale={2}
                      />
                    </span>
                  </p>
              </div>
            </div>
            <div className="text-center btn-container">
              <button type="submit" className="btn btn-submit checkout">
                {this.props.basket.produtos && this.props.basket.produtos.find(p => p.agendamentoObrigatorio) ? 'REALIZAR PAGAMENTO' : 'FINALIZAR PEDIDO'}
                <img src="/img/icons/arrow-send.png" srcSet="/img/icons/arrow-send@2x.png 2x, /img/icons/arrow-send@3x.png 3x" className="pull-right"/>
              </button>
            </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>);
  
}

const mapStateToProps = ({ order, auth, basket }) => {
  const { loggedUser } = auth;
  return { 
    ...order,
    basket,
    loggedUser
  };
};

export default connect(
  mapStateToProps,
  {
    ...OrderActions
  }
)(Payment);