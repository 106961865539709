import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Header, Footer, NavigationBar } from '../../../components';
import { ShippingType } from '../../../constants';
import {
  listUserOrders,
  seeOrderDetail,
  handleScheduleButton,
} from '../../../actions/UserPageActions';
import './style.scss';
import { OrderStatusTranslation, OrderStatus } from '../../../constants';

class Orders extends Component {
  componentDidMount = () => {
    this.props.listUserOrders();
  };

  handleDetailButton = (order) => {
    this.props.seeOrderDetail(order);
  };

  handleScheduleButton = (order) => {
    this.props.handleScheduleButton(order.id);
  };

  render = () => {
    const { orders } = this.props;
    return (
      <div className="my-orders">
        <Header />
        <NavigationBar
          title="Meus Pedidos"
          breadcrumb={[
            { to: '/', title: 'Home' },
            { to: '/meus-pedidos', title: 'Meus Pedidos' },
          ]}
        />
        <div className="gray-container min-padding">
          <div className="container">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text">Código</th>
                    <th className="text">Produtos</th>
                    <th className="text">Entrega</th>
                    <th className="text">Valor Total</th>
                    <th className="text">Status</th>
                    <th className="text">Data</th>
                    <th className="text" />
                  </tr>
                </thead>
                <tbody>
                  {orders.map((o) => (
                    <tr key={o.id}>
                      <td className="text small">{o.id}</td>
                      <td className="text small">
                        {o.produtos.map((p) => (
                          <p key={p.id}>
                            {p.nomeProduto} ({p.quantidade}) -{' '}
                            <NumberFormat
                              value={p.precoUnitario}
                              displayType={'text'}
                              decimalSeparator={','}
                              thousandSeparator={'.'}
                              prefix={'R$'}
                              fixedDecimalScale
                              decimalScale={2}
                            />
                          </p>
                        ))}
                      </td>
                      <td className="text small">
                        {o.tipoEntrega === ShippingType.Unity && (
                          <div>
                            <p className="text small bold">
                              Retirada na Unidade
                            </p>
                            <p className="text small">{o.unidade.descricao}</p>
                            <p className="text small">{o.unidade.logradouro}</p>
                            <p className="text small">
                              {o.unidade.bairro} - {o.unidade.cidade} /{' '}
                              {o.unidade.estado}
                            </p>
                            {o.produtos.find(
                              (p) => p.agendamentoObrigatorio
                            ) && (
                              <p className="text small">
                                Agendado para:{' '}
                                <span className="bold">
                                  {o.horarioAgendamento
                                    ? moment(o.horarioAgendamento).format(
                                        'DD/MM/YYYY HH:mm'
                                      )
                                    : 'Não informado'}
                                </span>
                              </p>
                            )}
                            <p className="text small">
                              Tipo de Retirada:{' '}
                              <span className="bold">
                                {o.driveThru
                                  ? 'Serviço Drive-Thru'
                                  : 'Dentro da Unidade'}
                              </span>
                            </p>
                          </div>
                        )}
                        {o.tipoEntrega === ShippingType.Residence && (
                          <div>
                            <p className="text small bold">
                              Aplicação em Domicílio
                            </p>
                            <p className="text small">
                              Taxa de Deslocamento:{' '}
                              <span className="bold">
                                <NumberFormat
                                  value={o.taxaEntrega}
                                  displayType={'text'}
                                  decimalSeparator={','}
                                  thousandSeparator={'.'}
                                  prefix={'R$'}
                                  fixedDecimalScale
                                  decimalScale={2}
                                />
                              </span>
                            </p>
                            <p className="text small">
                              {o.enderecoEntrega.cep}
                            </p>
                            <p className="text small">
                              {o.enderecoEntrega.logradouro}
                            </p>
                            <p className="text small">
                              {o.enderecoEntrega.bairro} -{' '}
                              {o.enderecoEntrega.cidade} /{' '}
                              {o.enderecoEntrega.estado}
                            </p>
                            {o.produtos.find(
                              (p) => p.agendamentoObrigatorio
                            ) && (
                              <p className="text small">
                                Agendado para:{' '}
                                <span className="bold">
                                  {o.horarioAgendamento
                                    ? moment(o.horarioAgendamento).format(
                                        'DD/MM/YYYY HH:mm'
                                      )
                                    : 'Não informado'}
                                </span>
                              </p>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="text small">
                        <NumberFormat
                          value={o.valorTotal}
                          displayType={'text'}
                          decimalSeparator={','}
                          thousandSeparator={'.'}
                          prefix={'R$'}
                          fixedDecimalScale
                          decimalScale={2}
                        />
                      </td>
                      <td className="text small">
                        {OrderStatusTranslation[o.status]}
                      </td>
                      <td className="text small">
                        {moment(o.dataCriacao).format('DD/MM/YYYY HH:mm')}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn bold"
                          onClick={() => this.handleDetailButton(o)}
                        >
                          VER DETALHES
                        </button>
                        {!o.horarioAgendamento &&
                          o.status === OrderStatus.WaitingSchedule &&
                          o.produtos.find((p) => p.agendamentoObrigatorio) && (
                            <button
                              className="btn bold btn-schedule"
                              onClick={() => this.handleScheduleButton(o)}
                            >
                              AGENDAR COLETA
                            </button>
                          )}
                      </td>
                    </tr>
                  ))}
                  {orders.length === 0 && (
                    <tr>
                      <td className="text text-center" colSpan="7">
                        Você ainda não possui pedidos!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row text-center">
              <span className="text cancel">
                Para cancelamentos de pedidos entre em contato através do
                telefone <strong>(31) 3508-2486</strong> ou e-mail{' '}
                <strong>ecommerce.saomarcos@gsmarcos.com.br.</strong>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = ({ userPage }) => {
  return {
    ...userPage,
  };
};

export default connect(
  mapStateToProps,
  { listUserOrders, seeOrderDetail, handleScheduleButton }
)(Orders);
