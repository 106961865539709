import { Messages } from '../../constants';

const validation = {
  password: {
    presence: {
      message: Messages.RequiredPasswordValidationMessage,
      allowEmpty: false
    }
  },
  confirmation: {
    equality: {
      attribute: 'password',
      message: Messages.InvalidConfirmationPasswordValidationMessage
    },
    presence: (value, attributes) => {
      if (attributes.password) {
        return { 
          message: Messages.RequiredPasswordConfirmationValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
};

export default validation;
