const actions = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",

  UPDATE_AUTH_EMAIL: "UPDATE_AUTH_EMAIL",
  VERIFY_LOGGED_USER_SUCCESS: "VERIFY_LOGGED_USER_SUCCESS",
  UPDATE_LOGIN_FIELD: "UPDATE_LOGIN_FIELD",

  REQUEST_PASSWORD_RECOVERY: "REQUEST_PASSWORD_RECOVERY",
  REQUEST_PASSWORD_RECOVERY_SUCCESS: "REQUEST_PASSWORD_RECOVERY_SUCCESS",
  REQUEST_PASSWORD_RECOVERY_FAIL: "REQUEST_PASSWORD_RECOVERY_FAIL",

  VALIDATE_PASSWORD_RECOVERY_CODE: "VALIDATE_PASSWORD_RECOVERY_CODE",
  VALIDATE_PASSWORD_RECOVERY_CODE_SUCCESS:
    "VALIDATE_PASSWORD_RECOVERY_CODE_SUCCESS",
  VALIDATE_PASSWORD_RECOVERY_CODE_FAIL: "VALIDATE_PASSWORD_RECOVERY_CODE_FAIL",
  UPDATE_PASSWORD_RECOVERY_FIELD: "UPDATE_PASSWORD_RECOVERY_FIELD",

  RECOVER_PASSWORD: "RECOVER_PASSWORD",
  RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  RECOVER_PASSWORD_FAIL: "RECOVER_PASSWORD_FAIL",

  UPDATE_USER_REGISTER_FIELD: "UPDATE_USER_REGISTER_FIELD",

  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAIL: "REGISTER_USER_FAIL",

  UPLOAD_USER_PICTURE: "UPLOAD_USER_PICTURE",
  UPLOAD_USER_PICTURE_SUCCESS: "UPLOAD_USER_PICTURE_SUCCESS",
  UPLOAD_USER_PICTURE_FAIL: "UPLOAD_USER_PICTURE_FAIL",

  UPDATE_CONTACT_FIELD: "UPDATE_CONTACT_FIELD",

  SEND_CONTACT: "SEND_CONTACT",
  SEND_CONTACT_SUCCESS: "SEND_CONTACT_SUCCESS",
  SEND_CONTACT_FAIL: "SEND_CONTACT_FAIL",

  LIST_PRODUCTS: "LIST_PRODUCTS",
  LIST_PRODUCTS_SUCCESS: "LIST_PRODUCTS_SUCCESS",
  LIST_PRODUCTS_FAIL: "LIST_PRODUCTS_FAIL",

  SEARCH_PRODUCTS: "SEARCH_PRODUCTS",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAIL: "SEARCH_PRODUCTS_FAIL",

  PAGINATE_PRODUCTS: "PAGINATE_PRODUCTS",
  PAGINATE_PRODUCTS_SUCCESS: "PAGINATE_PRODUCTS_SUCCESS",
  PAGINATE_PRODUCTS_FAIL: "PAGINATE_PRODUCTS_FAIL",

  ADD_PRODUCT_TO_BASKET: "ADD_PRODUCT_TO_BASKET",
  ADD_PRODUCT_TO_BASKET_SUCCESS: "ADD_PRODUCT_TO_BASKET_SUCCESS",
  ADD_PRODUCT_TO_BASKET_FAIL: "ADD_PRODUCT_TO_BASKET_FAIL",

  LIST_CATEGORIES: "LIST_CATEGORIES",
  LIST_CATEGORIES_SUCCESS: "LIST_CATEGORIES_SUCCESS",
  LIST_CATEGORIES_FAIL: "LIST_CATEGORIES_FAIL",

  GET_CATEGORY: "GET_CATEGORY",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAIL: "GET_CATEGORY_FAIL",

  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAIL: "GET_PRODUCT_FAIL",

  UPDATE_PRODUCT_SEARCH_FIELD: "UPDATE_PRODUCT_SEARCH_FIELD",
  UPDATE_CATEGORY_FIELD: "UPDATE_CATEGORY_FIELD",

  FILL_BASKET: "FILL_BASKET",

  REMOVE_PRODUCT_FROM_BASKET: "REMOVE_PRODUCT_FROM_BASKET",
  REMOVE_PRODUCT_FROM_BASKET_SUCCESS: "REMOVE_PRODUCT_FROM_BASKET_SUCCESS",
  REMOVE_PRODUCT_FROM_BASKET_FAIL: "REMOVE_PRODUCT_FROM_BASKET_FAIL",

  LIST_UNITS: "LIST_UNITS",
  LIST_UNITS_SUCCESS: "LIST_UNITS_SUCCESS",
  LIST_UNITS_FAIL: "LIST_UNITS_FAIL",

  PAY_ORDER: "PAY_ORDER",
  PAY_ORDER_SUCCESS: "PAY_ORDER_SUCCESS",
  PAY_ORDER_FAIL: "PAY_ORDER_FAIL",

  REGISTER_RECEIVER: "REGISTER_RECEIVER",
  REGISTER_RECEIVER_SUCCESS: "REGISTER_RECEIVER_SUCCESS",
  REGISTER_RECEIVER_FAIL: "REGISTER_RECEIVER_FAIL",

  REGISTER_SHIPPING_ADDRESS: "REGISTER_SHIPPING_ADDRESS",
  REGISTER_SHIPPING_ADDRESS_SUCCESS: "REGISTER_SHIPPING_ADDRESS_SUCCESS",
  REGISTER_SHIPPING_ADDRESS_FAIL: "REGISTER_SHIPPING_ADDRESS_FAIL",

  REGISTER_ORDER: "REGISTER_ORDER",
  REGISTER_ORDER_SUCCESS: "REGISTER_ORDER_SUCCESS",
  REGISTER_ORDER_FAIL: "REGISTER_ORDER_FAIL",

  EXECUTE_PAYMENT: "EXECUTE_PAYMENT",
  EXECUTE_PAYMENT_SUCCESS: "EXECUTE_PAYMENT_SUCCESS",
  EXECUTE_PAYMENT_FAIL: "EXECUTE_PAYMENT_FAIL",

  VALIDATE_UNITY_AVAILABILITY: "VALIDATE_UNITY_AVAILABILITY",
  VALIDATE_UNITY_AVAILABILITY_SUCCESS: "VALIDATE_UNITY_AVAILABILITY_SUCCESS",
  VALIDATE_UNITY_AVAILABILITY_FAIL: "VALIDATE_UNITY_AVAILABILITY_FAIL",

  UPDATE_ORDER_FIELD: "UPDATE_ORDER_FIELD",

  FILL_RECEIVER_FROM_LOGGED_USER: "FILL_RECEIVER_FROM_LOGGED_USER",

  HANDLE_SHIPPING_TYPE_CHANGE: "HANDLE_SHIPPING_TYPE_CHANGE",
  HANDLE_SHIPPING_TYPE_CHANGE_SUCCESS: "HANDLE_SHIPPING_TYPE_CHANGE_SUCCESS",
  HANDLE_SHIPPING_TYPE_CHANGE_FAIL: "HANDLE_SHIPPING_TYPE_CHANGE_FAIL",

  VALIDATE_SHIPPING_ZIPCODE: "VALIDATE_SHIPPING_ZIPCODE",
  VALIDATE_SHIPPING_ZIPCODE_SUCCESS: "VALIDATE_SHIPPING_ZIPCODE_SUCCESS",
  VALIDATE_SHIPPING_ZIPCODE_FAIL: "VALIDATE_SHIPPING_ZIPCODE_FAIL",

  FILL_SHIPPING_ADDRESS_WITH_LOGGED_USER_ADDRESS:
    "FILL_SHIPPING_ADDRESS_WITH_LOGGED_USER_ADDRESS",

  CALCULATE_PAYMENT_PARCELS: "CALCULATE_PAYMENT_PARCELS",
  IDENTIFY_CARD_BRAND_FROM_CARD_NUMBER: "IDENTIFY_CARD_BRAND_FROM_CARD_NUMBER",
  CHANGE_BRAND: "CHANGE_BRAND",
  INVALIDATE_BASKET: "INVALIDATE_BASKET",

  FILL_RECEIPT_FROM_ORDER: "FILL_RECEIPT_FROM_ORDER",
  FILL_RECEIPT_FROM_ORDER_SUCCESS: "FILL_RECEIPT_FROM_ORDER_SUCCESS",
  FILL_RECEIPT_FROM_ORDER_FAIL: "FILL_RECEIPT_FROM_ORDER_FAIL",

  LIST_USER_ORDERS: "LIST_USER_ORDERS",
  LIST_USER_ORDERS_SUCCESS: "LIST_USER_ORDERS_SUCCESS",
  LIST_USER_ORDERS_FAIL: "LIST_USER_ORDERS_FAIL",

  FILL_USER_REGISTER_FROM_LOGGED_USER: "FILL_USER_REGISTER_FROM_LOGGED_USER",
  RESET_REGISTER_FORM: "RESET_REGISTER_FORM",

  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAIL: "UPDATE_USER_PROFILE_FAIL",

  CHANGE_BASKET_ITEM_QUANTITY: "CHANGE_BASKET_ITEM_QUANTITY",
  CHANGE_BASKET_ITEM_QUANTITY_SUCCESS: "CHANGE_BASKET_ITEM_QUANTITY_SUCCESS",
  CHANGE_BASKET_ITEM_QUANTITY_FAIL: "CHANGE_BASKET_ITEM_QUANTITY_FAIL",

  SEARCH_BACKOFFICE_ORDERS: "SEARCH_BACKOFFICE_ORDERS",
  SEARCH_BACKOFFICE_ORDERS_SUCCESS: "SEARCH_BACKOFFICE_ORDERS_SUCCESS",
  SEARCH_BACKOFFICE_ORDERS_FAIL: "SEARCH_BACKOFFICE_ORDERS_FAIL",

  UPDATE_BACKOFFICE_ORDER_STATUS: "UPDATE_BACKOFFICE_ORDER_STATUS",
  UPDATE_BACKOFFICE_ORDER_STATUS_SUCCESS:
    "UPDATE_BACKOFFICE_ORDER_STATUS_SUCCESS",
  UPDATE_BACKOFFICE_ORDER_STATUS_FAIL: "UPDATE_BACKOFFICE_ORDER_STATUS_FAIL",

  UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER:
    "UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER",
  UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_SUCCESS:
    "UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_SUCCESS",
  UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_FAIL:
    "UPDATE_BACKOFFICE_ORDER_SERVICE_ORDER_FAIL",

  UPDATE_BACKOFFICE_FIELD: "UPDATE_BACKOFFICE_FIELD",
  CANCEL_BACKOFFICE_ORDER_EDITION: "CANCEL_BACKOFFICE_ORDER_EDITION",

  EDIT_BACKOFFICE_ORDER_STATUS: "EDIT_BACKOFFICE_ORDER_STATUS",
  EDIT_BACKOFFICE_ORDER_SERVICE_ORDER: "EDIT_BACKOFFICE_ORDER_SERVICE_ORDER",

  UPLOAD_INVOICE: "UPLOAD_INVOICE",
  UPLOAD_INVOICE_SUCCESS: "UPLOAD_INVOICE_SUCCESS",
  UPLOAD_INVOICE_FAIL: "UPLOAD_INVOICE_FAIL",

  EXPORT_ORDERS: "EXPORT_ORDERS",
  EXPORT_ORDERS_SUCCESS: "EXPORT_ORDERS_SUCCESS",
  EXPORT_ORDERS_FAIL: "EXPORT_ORDERS_FAIL",

  GET_FILE: "GET_FILE",
  GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
  GET_FILE_FAIL: "GET_FILE_FAIL",

  SEARCH_BACKOFFICE_PRODUCTS: "SEARCH_BACKOFFICE_PRODUCTS",
  SEARCH_BACKOFFICE_PRODUCTS_SUCCESS: "SEARCH_BACKOFFICE_PRODUCTS_SUCCESS",
  SEARCH_BACKOFFICE_PRODUCTS_FAIL: "SEARCH_BACKOFFICE_PRODUCTS_FAIL",

  UPDATE_BACKOFFICE_PRODUCT_FIELD: "UPDATE_BACKOFFICE_PRODUCT_FIELD",

  INACTIVE_BACKOFFICE_PRODUCT: "INACTIVE_BACKOFFICE_PRODUCT",
  INACTIVE_BACKOFFICE_PRODUCT_SUCCESS: "INACTIVE_BACKOFFICE_PRODUCT_SUCCESS",
  INACTIVE_BACKOFFICE_PRODUCT_FAIL: "INACTIVE_BACKOFFICE_PRODUCT_FAIL",

  ACTIVE_BACKOFFICE_PRODUCT: "ACTIVE_BACKOFFICE_PRODUCT",
  ACTIVE_BACKOFFICE_PRODUCT_SUCCESS: "ACTIVE_BACKOFFICE_PRODUCT_SUCCESS",
  ACTIVE_BACKOFFICE_PRODUCT_FAIL: "ACTIVE_BACKOFFICE_PRODUCT_FAIL",

  FILL_PRODUCT_TO_EDIT: "FILL_PRODUCT_TO_EDIT",
  GET_PRODUCT_TO_EDIT: "GET_PRODUCT_TO_EDIT",
  GET_PRODUCT_TO_EDIT_SUCCESS: "GET_PRODUCT_TO_EDIT_SUCCESS",
  GET_PRODUCT_TO_EDIT_FAIL: "GET_PRODUCT_TO_EDIT_FAIL",

  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAIL: "UPDATE_PRODUCT_FAIL",

  UPDATE_BACKOFFICE_PRODUCT_EDIT_FIELD: "UPDATE_BACKOFFICE_PRODUCT_EDIT_FIELD",
  UPLOAD_PRODUCT_PICTURE: "UPLOAD_PRODUCT_PICTURE",
  UPLOAD_PRODUCT_PICTURE_FAIL: "UPLOAD_PRODUCT_PICTURE_FAIL",
  UPLOAD_PRODUCT_PICTURE_SUCCESS: "UPLOAD_PRODUCT_PICTURE_SUCCESS",

  SET_TYPE_ACTION_MANAGER_PRODUCT: "SET_TYPE_ACTION_MANAGER_PRODUCT",

  CREATE_PRODUCT: "CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAIL: "CREATE_PRODUCT_FAIL",

  RESET_FORM_PRODUCT_EDIT: "RESET_FORM_PRODUCT_EDIT",

  FILL_CATEGORIES: "FILL_CATEGORIES",
  FILL_CATEGORIES_SUCCESS: "FILL_CATEGORIES_SUCCESS",
  FILL_CATEGORIES_FAIL: "FILL_CATEGORIES_FAIL",

  CHECKED_CATEGORY: "CHECKED_CATEGORY",
  UNCHECKED_CATEGORY: "UNCHECKED_CATEGORY",

  SEARCH_CATEGORIES: "SEARCH_CATEGORIES",
  SEARCH_CATEGORIES_SUCCESS: "SEARCH_CATEGORIES_SUCCESS",
  SEARCH_CATEGORIES_FAIL: "SEARCH_CATEGORIES_FAIL",

  GET_CATEGORY_PRODUCTS: "GET_CATEGORY_PRODUCTS",
  GET_CATEGORY_PRODUCTS_SUCCESS: "GET_CATEGORY_PRODUCTS_SUCCESS",
  GET_CATEGORY_PRODUCTS_FAIL: "GET_CATEGORY_PRODUCTS_FAIL",

  SORT_PRODUCTS_CHANGED: "SORT_PRODUCTS_CHANGED",

  SAVE_CATEGORY_PRODUCTS_SORT: "SAVE_CATEGORY_PRODUCTS_SORT",
  SAVE_CATEGORY_PRODUCTS_SORT_SUCCESS: "SAVE_CATEGORY_PRODUCTS_SORT_SUCCESS",
  SAVE_CATEGORY_PRODUCTS_SORT_FAIL: "SAVE_CATEGORY_PRODUCTS_SORT_FAIL",

  GET_CATEGORY_DETAILS: "GET_CATEGORY_DETAILS",
  GET_CATEGORY_DETAILS_SUCCESS: "GET_CATEGORY_DETAILS_SUCCESS",
  GET_CATEGORY_DETAILS_FAIL: "GET_CATEGORY_DETAILS_FAIL",

  CLEAR_REDIRECT_URL: "CLEAR_REDIRECT_URL",

  FILL_BANNERS: "FILL_BANNERS",

  FILL_UNITS_UFS: "FILL_UNITS_UFS",
  FILL_UNITS_UFS_SUCCESS: "FILL_UNITS_UFS_SUCCESS",
  FILL_UNITS_UFS_SUCCESS_FAIL: "FILL_UNITS_UFS_SUCCESS_FAIL",

  CHECKED_STOK: "CHECKED_STOK",
  UNCHECKED_STOK: "UNCHECKED_STOK",

  CLEAR_DELIVERY_TAX: "CLEAR_DELIVERY_TAX",

  FILL_REGISTER_ADDRESS: 'FILL_REGISTER_ADDRESS',
  FILL_REGISTER_ADDRESS_SUCCESS: 'FILL_REGISTER_ADDRESS_SUCCESS',
  FILL_REGISTER_ADDRESS_FAIL: 'FILL_REGISTER_ADDRESS_FAIL',

  SEARCH_SCHEDULING_DAY: 'SEARCH_SCHEDULING_DAY',
  SEARCH_SCHEDULING_DAY_SUCCESS: 'SEARCH_SCHEDULING_DAY_SUCCESS',
  SEARCH_SCHEDULING_DAY_FAIL: 'SEARCH_SCHEDULING_DAY_FAIL',

  UPDATE_SHCEDULING_DAY: 'UPDATE_SHCEDULING_DAY',

  SEARCH_SCHEDULING_HOUR: 'SEARCH_SCHEDULING_HOUR',
  SEARCH_SCHEDULING_HOUR_SUCCESS: 'SEARCH_SCHEDULING_HOUR_SUCCESS',
  SEARCH_SCHEDULING_HOUR_FAIL: 'SEARCH_SCHEDULING_HOUR_FAIL',

  CLEAR_SHCEDULING_SELECTED_DATA: 'CLEAR_SHCEDULING_SELECTED_DATA',

  UPDATE_SHCEDULING_HOUR: 'UPDATE_SHCEDULING_HOUR',

  SAVE_SCHEDULING: 'SAVE_SCHEDULING',
  SAVE_SCHEDULING_SUCCESS: 'SAVE_SCHEDULING_SUCCESS',
  SAVE_SCHEDULING_FAIL: 'SAVE_SCHEDULING_FAIL',

  FILL_DOCTOR: 'FILL_DOCTOR',

};

export default actions;
