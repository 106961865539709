import ActionTypes from '../actions/ActionTypes';

const initialState = {
  code: '',
  password: '',
  confirmation: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.VALIDATE_PASSWORD_RECOVERY_CODE_FAIL:
      return { ...initialState };
    
    case ActionTypes.UPDATE_PASSWORD_RECOVERY_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value }
    default:
      return state;
  }
};
