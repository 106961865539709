import WebApi from "./WebApi";
import { CacheService } from ".";
import { CacheKeys } from "../constants";

export class UnityService {
  static async list() {
    const response = await WebApi.get("/unidades", {
      pagina: 1,
      registrosPorPagina: 99999
    });
    return response.data;
  }

  static async listUfs() {
    const response = await WebApi.get("/unidades/ufs");
    return response.data;
  }

  static async checkAvailability(unityId, productsIds) {
    const params = {
      idsProdutos: productsIds
    };
    const response = await WebApi.get(`/unidades/${unityId}/estoque`, params);
    return response.data;
  }

  static async getResidenceUnity(state) {
    const response = await WebApi.get("/unidades/domicilio", { Estado: state });
    return response.data;
  }
}
