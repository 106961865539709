import { push } from 'connected-react-router';
import Alert from 'react-s-alert';
import ActionTypes from './ActionTypes';
import { OrderService, NotificationService, UserService } from '../services';
import { Messages } from '../constants';

export const listUserOrders = () => async dispatch => {
  try {
    dispatch({ type: ActionTypes.LIST_USER_ORDERS });
    const orders = await OrderService.listUserOrders();
    dispatch({ type: ActionTypes.LIST_USER_ORDERS_SUCCESS, payload: orders });
  } catch (e) {
    NotificationService.showApiResponseErrorAlert(e);
    dispatch({ type: ActionTypes.LIST_USER_ORDERS_FAIL });
  }
};

export const redirectToUserOrders = () => async dispatch => {
  await listUserOrders()(dispatch);
  dispatch(push('/meus-pedidos'));
};

export const seeOrderDetail = (order) => dispatch => {
  dispatch({ type: ActionTypes.FILL_RECEIPT_FROM_ORDER_SUCCESS, payload: { order } });
  dispatch(push(`/recibo/${order.id}`));
};

export const fillUserRegisterFromLoggedUser = (loggedUser) => dispatch => 
  dispatch({ type: ActionTypes.FILL_USER_REGISTER_FROM_LOGGED_USER, payload: loggedUser });

export const redirectToProfileEdit = (loggedUser) => dispatch => {
  fillUserRegisterFromLoggedUser(loggedUser)(dispatch);
  dispatch(push('/meus-dados'));
};

export const updateUserProfile = (userData) => async dispatch => {
  try {
    dispatch({ type: ActionTypes.UPDATE_USER_PROFILE });
    const params = UserService.prepareUserDataToSave(userData);
    const user = await UserService.update(params);
    dispatch({ type: ActionTypes.UPDATE_USER_PROFILE_SUCCESS, payload: user });
    Alert.success(Messages.UserProfileUpdatedSuccessMessage);
  } catch (e) {
    NotificationService.showApiResponseErrorAlert(e);
    dispatch({ type: ActionTypes.UPDATE_USER_PROFILE_FAIL });
  }
};

export const handleScheduleButton = order => async dispatch => {
  dispatch(push(`/agendamento/${order}`));
};
