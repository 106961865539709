import moment from 'moment';
import { StorageKeys } from '../constants';

const cacheStorageKey = (key) => `${StorageKeys.Cache}_${key}`;

export class CacheService {
  
  static save(key, value, expiresIn) {
    localStorage.setItem(cacheStorageKey(key), JSON.stringify({
      value,
      expires: +moment().add(expiresIn, 's')
    }));
  }

  static saveWithExpireDate(key, value, expireDate) {
    localStorage.setItem(cacheStorageKey(key), JSON.stringify({
      value,
      expires: +moment(expireDate)
    }));
  }

  static get(key) {
    const itemString = localStorage.getItem(cacheStorageKey(key));
    if (!itemString) return null;    
    const item = JSON.parse(itemString);
    if (moment(item.expires).isBefore()) {
      localStorage.removeItem(cacheStorageKey(key));
      return null;
    }
    return item.value;
  }

  static remove(key) {
    localStorage.removeItem(cacheStorageKey(key));
  }
}
