import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { ProductBox } from '..';
import {
  searchProducts,
  paginateProducts
} from '../../actions/ProductSearchActions';
import './style.scss';

class ProductList extends Component {

  constructor(props) {
    super(props);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount = () => {
    this.searchProducts();
  };

  componentDidUpdate = (prevProps) => {
    const searchParam = queryString.parse(this.props.search);
    const prevSeachParam = queryString.parse(prevProps.search);
    const { selectedCategoryId } = this.props;
    if (searchParam.s !== prevSeachParam.s || selectedCategoryId !== prevProps.selectedCategoryId) {
      this.searchProducts();
    }
  };

  searchProducts = () => {
    const { s } = queryString.parse(this.props.search);
    this.props.searchProducts(s, this.props.selectedCategoryId);
  };

  handleLoadMore = () => {
    const { s } = queryString.parse(this.props.search);
    this.props.paginateProducts(this.props.pageNumber + 1, s, this.props.selectedCategoryId);
  };

  showLoadMoreButton = () => {
    return (this.props.products.length / this.props.pageNumber) === parseInt(process.env.REACT_APP_PAGE_SIZE, 10);
  };

  render = () => {
    const { products, colLg, colMd, colSm, colXs } = this.props;
    return (
      <div className="product-list row">
        {products.map(p => (
          <div key={p.id} className={`${colLg ? colLg : 'col-lg-4'} ${colMd ? colMd : 'col-md-4'} ${colSm ? colSm : 'col-sm-6'} ${colXs ? colXs : 'col-xs-12'}`} key={p.id}>
            <ProductBox product={p} />
          </div>
        ))}
        {!this.props.hidePagination && this.showLoadMoreButton() &&
          <div className="col-xs-12 text-center">
            <button className="btn btn-load-more" onClick={this.handleLoadMore}>
              MOSTRAR MAIS <img src="/img/icons/arrow-down.png" srcSet="/img/icons/arrow-down@2x.png 2x, /img/icons/arrow-down@3x.png 3x" />
            </button>
          </div>}
          {products.length === 0 && 
            <p className="text text-center">Nenhum produto encontrado</p>
          }
      </div>
    );
  }
}


const mapStateToProps = ({ productSearch, category, router }) => {
  const { search } = router.location;
  const { selectedCategoryId } = category;
  return { 
    ...productSearch,
    selectedCategoryId,
    search
  };
};

export default connect(
  mapStateToProps,
  { searchProducts, paginateProducts }
)(ProductList);