import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { addProductToBasket } from '../../actions/BasketActions';
import { SlugService } from '../../services';
import './style.scss';

class ProductBox extends Component {
  addToBasket = () => {
    if (this.props.product.comRedirecionamento) {
      var win = window.open(this.props.product.urlRedirecionamento, '_blank');
      win.focus();
    } else this.props.addProductToBasket(this.props.product);
  };

  handleProduct = (product) => {
    const dataLayer = {
      event: 'productClick',
      ecommerce: {
        click: {
          products: [
            {
              name: product.nome,
              id: product.id,
              price: product.preco,
              category: product.vacina ? 'Vacina' : 'Análises Clínico',
            },
          ],
        },
      },
    };
    window.dataLayer.push(dataLayer);
  };

  render = () => {
    const { product } = this.props;
    return (
      <div className='product-box'>
        <div className='text-center'>
          <Link
            onClick={() => {
              this.handleProduct(product);
            }}
            to={`/vacinas/${product.id}/${SlugService.apply(product.nome)}`}
          >
            <div className='offer-box'>
              {product.desconto && (
                <p className='offer-text'>{product.desconto}</p>
              )}
            </div>
            {!product.imagemCapa && product.vacina && (
              <img src='/img/vacina.png' />
            )}
            {!product.imagemCapa && !product.vacina && (
              <img src='/img/prancheta.png' className='prancheta' />
            )}
            {product.imagemCapa && (
              <img
                src={`${process.env.REACT_APP_API_URL}/arquivos/${product.imagemCapa.id}`}
              />
            )}
          </Link>
        </div>
        <Link
          onClick={() => {
            this.handleProduct(product);
          }}
          to={`/vacinas/${product.id}/${SlugService.apply(product.nome)}`}
        >
          <h4 className='title text-center'>{product.nome}</h4>
          <p className='text small gray text-center indication'>
            {product.indicacao}
          </p>
        </Link>
        <div className='options'>
          {product.desconto && (
            <p className='text gray text-center price discount'>
              <NumberFormat
                value={product.precoDe}
                displayType={'text'}
                decimalSeparator={','}
                thousandSeparator={'.'}
                prefix={'R$'}
                fixedDecimalScale
                decimalScale={2}
              />
            </p>
          )}
          <p className='text gray text-center bold price'>
            <NumberFormat
              value={product.preco}
              displayType={'text'}
              decimalSeparator={','}
              thousandSeparator={'.'}
              prefix={'R$'}
              fixedDecimalScale
              decimalScale={2}
            />
          </p>
          <div className='btn-container text-center'>
            <Link
              onClick={() => {
                this.handleProduct(product);
              }}
              to={`/vacinas/${product.id}/${SlugService.apply(product.nome)}`}
              className='btn detail'
            >
              <img
                src='/img/icons/eye.png'
                srcSet='/img/icons/eye@2x.png 2x, /img/icons/eye@3x.png 3x'
              />
            </Link>
            <button className='btn add-to-basket' onClick={this.addToBasket}>
              <img
                src='/img/icons/cart.png'
                srcSet='/img/icons/cart@2x.png 2x, /img/icons/cart@3x.png 3x'
              />
            </button>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(null, { addProductToBasket })(ProductBox);
