import Alert from 'react-s-alert';
import { Messages } from '../constants';

export class NotificationService {

  static showApiResponseErrorAlert(response) {
    const { data } = response;
    const isValidationError = data && Array.isArray(data);
    const errors = isValidationError
      ? `<ul class="list-unstyled">${data.map(e => `<li>${e.mensagem}</li>`).join('')}</ul>`
      : Messages.InternalServerError;
    
    if (!isValidationError) Alert.closeAll();

    Alert.error(errors, { timeout: 10000 });
  }

  static showValidationErrorAlert(errors) {
    const errorsLi = errors.map(e => `<li>${e}</li>`).join('');
    const errorHtml = `<ul class="list-unstyled">${errorsLi}</ul>`;
    Alert.error(errorHtml, { timeout: 10000 });
  }
}