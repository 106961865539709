import { OrderStatus } from '.';

export const OrderStatusAvailable = {
  [OrderStatus.WaitingPayment]: [
    OrderStatus.PaymentError,
    OrderStatus.Canceled,
    OrderStatus.Paid
  ],
  [OrderStatus.Paid]: [OrderStatus.Reversed, OrderStatus.Sent],
  [OrderStatus.Sent]: [OrderStatus.Reversed, OrderStatus.Delivered],
  [OrderStatus.PaymentError]: [OrderStatus.Canceled],
  [OrderStatus.WaitingSchedule]: [
    OrderStatus.Paid,
    OrderStatus.Reversed,
    OrderStatus.Sent
  ]
};
