import ActionTypes from "../actions/ActionTypes";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
  state: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEND_CONTACT_SUCCESS:
      return { ...initialState };
    case ActionTypes.UPDATE_CONTACT_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    default:
      return state;
  }
};
