import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Alert from 'react-s-alert';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Header, Footer, NavigationBar } from '../../../components';
import { ShippingType, Messages, OrderStatus } from '../../../constants';
import './style.scss';
import {
  OrderStatusTranslation,
  OrderStatusAvailable,
} from '../../../constants';
import * as BackOfficeActions from '../../../actions/BackOfficeActions';
import * as FileActions from '../../../actions/FileActions';

const pageSize = 10;

const paymentMethodTranslate = {
  CreditCard: 'Cartão de Crédito',
};

class Orders extends Component {
  state = {
    selectedFile: null,
    uploadedFile: null,
    fileName: '',
  };

  interval = null;

  constructor(props) {
    super(props);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleServiceOrderSubmit = this.handleServiceOrderSubmit.bind(this);
  }

  componentDidMount = () => {
    this.paginateProducts(1);
    this.interval = setInterval(
      () => this.paginateProducts(this.props.pageNumber),
      30000
    );
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  paginateProducts = (pageNumber) => {
    this.props.searchOrders({ ...this.props, pageNumber, pageSize });
  };

  cancelEdition = () => {
    this.props.cancelOrderEdition();
  };

  editServiceOrder = (order) => {
    this.props.editServiceOrder(order);
  };

  editStatus = (order) => {
    this.props.editOrderStatus(order);
  };

  handleChange = (prop, event, orderId = null) => {
    const { value } = event.target;
    this.props.updateBackOfficeField({ prop, value, orderId });
  };

  handleServiceOrderSubmit = (event) => {
    event.preventDefault();
    if (!this.props.serviceOrder) {
      Alert.error(Messages.RequiredOrderServiceOrderValidationMessage);
      return;
    }
    this.props.updateServiceOrder(
      this.props.selectedOrder,
      this.props.serviceOrder
    );
  };

  handleStatusSubmit = (event) => {
    event.preventDefault();
    if (this.props.orderStatus !== 'EmEntrega' || this.props.uploadedFile) {
      this.props.updateOrderStatus(
        this.props.selectedOrder,
        this.props.orderStatus,
        this.props.uploadedFile
      );
    }
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.paginateProducts(1);
  };

  export = () => {
    this.props.exportOrders();
  };

  fileChangedHandler = (event) => {
    const { files } = event.target;
    const file = files.length > 0 ? files[0] : null;
    if (file && file.type.includes('pdf')) {
      this.props.uploadInvoice(file);
    } else {
      Alert.error(Messages.ExtensionPdfOnly);
    }
  };

  render = () => {
    const { orders } = this.props;
    return (
      <div className='backoffice-orders'>
        <Header />
        <NavigationBar
          title='Gerenciar Pedidos'
          breadcrumb={[
            { to: '/', title: 'Home' },
            { to: '/backoffice/pedidos', title: 'Gerenciar Pedidos' },
          ]}
        />
        <div className='gray-container'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group text-right'
                title='Exportar'
              >
                <button
                  className='btn btn-export'
                  onClick={() => this.export()}
                >
                  <span className='glyphicon glyphicon-export' />
                </button>
              </div>
              <form
                className='backoffice-search-form'
                onSubmit={this.handleSearchSubmit}
              >
                <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4 form-group'>
                  <InputMask
                    type='text'
                    mask='999.999.999-99'
                    placeholder='Filtre por CPF'
                    alwaysShowMask={false}
                    maskChar=''
                    name='cpf'
                    value={this.props.cpf}
                    onChange={(e) => this.handleChange('cpf', e)}
                  />
                </div>
                <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4 form-group'>
                  <select
                    name='status'
                    value={this.props.status}
                    onChange={(ev) => this.handleChange('status', ev)}
                  >
                    <option value=''>Filtre por Status</option>
                    <option value={OrderStatus.WaitingPayment}>
                      {OrderStatusTranslation[OrderStatus.WaitingPayment]}
                    </option>
                    <option value={OrderStatus.PaymentError}>
                      {OrderStatusTranslation[OrderStatus.PaymentError]}
                    </option>
                    <option value={OrderStatus.Paid}>
                      {OrderStatusTranslation[OrderStatus.Paid]}
                    </option>
                    <option value={OrderStatus.Reversed}>
                      {OrderStatusTranslation[OrderStatus.Reversed]}
                    </option>
                    <option value={OrderStatus.Canceled}>
                      {OrderStatusTranslation[OrderStatus.Canceled]}
                    </option>
                    <option value={OrderStatus.Sent}>
                      {OrderStatusTranslation[OrderStatus.Sent]}
                    </option>
                    <option value={OrderStatus.Delivered}>
                      {OrderStatusTranslation[OrderStatus.Delivered]}
                    </option>
                  </select>
                </div>
                <div className='col-xs-12 col-sm-1 col-md-1 col-lg-1 form-group'>
                  <button className='btn btn-submit' type='submit'>
                    <span className='glyphicon glyphicon-search' />
                  </button>
                </div>
              </form>
            </div>
            <div className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th className='text'>Código</th>
                    <th className='text'>Cliente</th>
                    <th className='text'>Produtos</th>
                    <th className='text'>Entrega</th>
                    <th className='text'>Extrato Cielo</th>
                    <th className='text'>Status</th>
                    <th className='text'>Ordem de Serviço</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((o) => (
                    <tr key={o.id}>
                      <td className='text small'>{o.id}</td>
                      <td className='text small'>
                        {o.usuario.nome}{' '}
                        <span className='bold'>
                          <InputMask
                            className='user-cpf'
                            type='text'
                            mask='999.999.999-99'
                            alwaysShowMask={false}
                            maskChar=''
                            name='cpf'
                            value={o.usuario.cpf}
                          />
                        </span>
                      </td>
                      <td className={`text small`}>
                        {o.produtos.map((p) => (
                          <p key={p.id}>
                            {p.nomeProduto} - QTDE:{' '}
                            <span className='bold'>{p.quantidade}</span>
                          </p>
                        ))}
                      </td>
                      <td className={`text small`}>
                        {o.tipoEntrega === ShippingType.Unity && (
                          <div>
                            <p className='text small bold'>
                              Retirada na Unidade
                            </p>
                            <p className='text small'>
                              Unidade:{' '}
                              <span className='block bold'>
                                {o.unidade.descricao}
                              </span>
                            </p>
                            <p className='text small'>
                              Endereço:{' '}
                              <span className='block'>
                                {o.unidade.logradouro}
                              </span>
                            </p>
                            <p className='text small'>
                              {o.unidade.bairro} - {o.unidade.cidade} /{' '}
                              {o.unidade.estado}
                            </p>
                            {o.produtos.find(
                              (p) => p.agendamentoObrigatorio
                            ) && (
                              <p className='text small'>
                                Agendado para:{' '}
                                <span className='bold block'>
                                  {' '}
                                  {o.horarioAgendamento ? (
                                    moment(o.horarioAgendamento).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  ) : (
                                    <Link to={`/agendamento/${o.id}`}>
                                      {' '}
                                      Agendar coleta
                                    </Link>
                                  )}
                                </span>
                              </p>
                            )}

                            <p className='text small'>
                              Tipo de Retirada:{' '}
                              <span className='bold'>
                                {o.driveThru
                                  ? 'Serviço Drive-Thru'
                                  : 'Dentro da Unidade'}
                              </span>
                            </p>
                          </div>
                        )}
                        {o.tipoEntrega === ShippingType.Residence && (
                          <div>
                            <p className='text small bold'>
                              Aplicação em Domicílio
                            </p>
                            <p className='text small'>
                              Taxa de Deslocamento:{' '}
                              <span className='block'>
                                <NumberFormat
                                  value={o.taxaEntrega}
                                  displayType={'text'}
                                  decimalSeparator={','}
                                  thousandSeparator={'.'}
                                  prefix={'R$'}
                                  fixedDecimalScale
                                  decimalScale={2}
                                />
                              </span>
                            </p>
                            <p className='text small'>
                              {o.enderecoEntrega.cep}
                            </p>
                            <p className='text small'>
                              {o.enderecoEntrega.logradouro}
                            </p>
                            <p className='text small'>
                              {o.enderecoEntrega.bairro} -{' '}
                              {o.enderecoEntrega.cidade} /{' '}
                              {o.enderecoEntrega.estado}
                            </p>
                            {!o.produtos.find(
                              (p) => p.agendamentoObrigatorio
                            ) ? (
                              <p className='text small'>
                                Sugestão de dia e horário para entrega:{' '}
                                <span className='bold block'>
                                  {o.enderecoEntrega.sugestaoDataHoraEntrega ||
                                    'Não informada'}
                                </span>
                              </p>
                            ) : (
                              <p className='text small'>
                                Agendado para:{' '}
                                <span className='bold block'>
                                  {' '}
                                  {o.horarioAgendamento ? (
                                    moment(o.horarioAgendamento).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  ) : (
                                    <Link to={`/agendamento/${o.id}`}>
                                      {' '}
                                      Agendar coleta
                                    </Link>
                                  )}
                                </span>
                              </p>
                            )}
                          </div>
                        )}
                        <p
                          className={`text small ${
                            o.idNotaFiscal ? 'visible' : 'hidden'
                          }`}
                        >
                          Nota Fiscal:
                          <a
                            className='invoice'
                            onClick={() => this.props.getFile(o.idNotaFiscal)}
                            href='#'
                          >
                            <span className='bold block'>faça download</span>
                          </a>
                        </p>
                      </td>
                      <td className='text small'>
                        {!o.pagamento ||
                          (!o.pagamento.extratoCielo && (
                            <span>Sem informação</span>
                          ))}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Data da Venda:{' '}
                            <span className='bold block'>
                              {moment(
                                o.pagamento.extratoCielo.dataVenda
                              ).format('DD/MM/YYYY HH:mm:ss')}
                            </span>
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Estabelecimento:{' '}
                            <span className='block'>
                              {o.pagamento.extratoCielo.estabelecimento}
                            </span>
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Bandeira:{' '}
                            <span className='block'>
                              {o.pagamento.extratoCielo.bandeira}
                            </span>
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Qtde Parcelas:{' '}
                            <span className='block'>
                              {o.pagamento.extratoCielo.quantidadeParcelas}
                            </span>
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Forma de Pagamento:{' '}
                            {
                              paymentMethodTranslate[
                                o.pagamento.extratoCielo.formaPagamento
                              ]
                            }
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Valor da Venda:{' '}
                            <span className='bold block'>
                              <NumberFormat
                                value={o.pagamento.extratoCielo.valor}
                                displayType={'text'}
                                decimalSeparator={','}
                                thousandSeparator={'.'}
                                prefix={'R$'}
                                fixedDecimalScale
                                decimalScale={2}
                              />
                            </span>
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            NSU:{' '}
                            <span className='block'>
                              {o.pagamento.extratoCielo.nsu}
                            </span>
                          </p>
                        )}
                        {o.pagamento && o.pagamento.extratoCielo && (
                          <p className='text small'>
                            Código de Autorização:{' '}
                            <span className='bold block'>
                              {o.pagamento.extratoCielo.codigoAutorizacao}
                            </span>
                          </p>
                        )}
                      </td>
                      <td
                        className={`text small editable-col ${
                          this.props.selectedOrder &&
                          this.props.selectedOrder.id === o.id
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        {OrderStatusTranslation[o.status]}{' '}
                        <button
                          className='btn btn-edit'
                          onClick={() => this.editStatus(o)}
                        >
                          <span className='glyphicon glyphicon-pencil' />
                        </button>
                      </td>
                      <td
                        className={`text small editable-col ${
                          this.props.selectedOrder &&
                          this.props.selectedOrder.id === o.id
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        {o.ordemServico || 'Não informada'}{' '}
                        <button
                          className='btn btn-edit'
                          onClick={() => this.editServiceOrder(o)}
                        >
                          <span className='glyphicon glyphicon-pencil' />
                        </button>
                      </td>

                      <td
                        colSpan='2'
                        className={`text small ${
                          this.props.statusOpened &&
                          this.props.selectedOrder.id === o.id
                            ? 'visible'
                            : 'hidden'
                        }`}
                      >
                        <form
                          className='form-inline form-edit'
                          onSubmit={this.handleStatusSubmit}
                        >
                          <div className='form-group'>
                            <label>Status</label>
                            <select
                              name='status'
                              value={this.props.orderStatus}
                              onChange={(ev) =>
                                this.handleChange('orderStatus', ev, o.id)
                              }
                            >
                              <option value={o.status}>
                                {OrderStatusTranslation[o.status]}
                              </option>
                              {(OrderStatusAvailable[o.status] || []).map(
                                (s) => (
                                  <option key={s} value={s}>
                                    {OrderStatusTranslation[s]}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <button
                            type='submit'
                            className={`btn ${
                              this.props.orderStatus === 'EmEntrega' &&
                              !this.props.uploadedFile
                                ? 'disabled opacity'
                                : ''
                            }`}
                            title='Salvar'
                          >
                            <span className='glyphicon glyphicon-ok' />
                          </button>
                          <button
                            type='button'
                            className='btn btn-remove'
                            title='Cancelar'
                            onClick={this.cancelEdition}
                          >
                            <span className='glyphicon glyphicon-remove' />
                          </button>
                          <div
                            className={
                              this.props.orderStatus === 'EmEntrega' &&
                              !o.idNotaFiscal
                                ? 'visible'
                                : 'hidden'
                            }
                          >
                            <label className='invoice'>
                              Upload da Nota Fiscal
                            </label>
                            <label htmlFor='file' className='pointer'>
                              <span className='glyphicon glyphicon-upload icon-upload'>
                                {' '}
                              </span>
                              <span className='file-name'>
                                {this.props.fileName ||
                                  'Selecione o arquivo do seu computador'}
                              </span>
                            </label>
                            <input
                              id='file'
                              type='file'
                              className='file-input hidden'
                              onChange={this.fileChangedHandler}
                            />
                          </div>
                        </form>
                      </td>
                      <td
                        colSpan='2'
                        className={`text small ${
                          this.props.serviceOrderOpened &&
                          this.props.selectedOrder.id === o.id
                            ? 'visible'
                            : 'hidden'
                        }`}
                      >
                        <form
                          className='form-inline form-edit'
                          onSubmit={this.handleServiceOrderSubmit}
                        >
                          <div className='form-group'>
                            <label>Ordem de Serviço</label>
                            <input
                              type='text'
                              name='serviceOrder'
                              value={this.props.serviceOrder}
                              onChange={(ev) =>
                                this.handleChange('serviceOrder', ev)
                              }
                            />
                          </div>
                          <button type='submit' className='btn' title='Salvar'>
                            <span className='glyphicon glyphicon-ok' />
                          </button>
                          <button
                            type='button'
                            className='btn btn-remove'
                            title='Cancelar'
                            onClick={this.cancelEdition}
                          >
                            <span className='glyphicon glyphicon-remove' />
                          </button>
                        </form>
                      </td>
                    </tr>
                  ))}
                  {orders.length === 0 && this.pageNumber === 1 && (
                    <tr>
                      <td className='text text-center' colSpan='7'>
                        Não foram encontrados pedidos!
                      </td>
                    </tr>
                  )}
                  {orders.length === 0 && this.pageNumber > 1 && (
                    <tr>
                      <td className='text text-center' colSpan='7'>
                        Não há mais pedidos para serem exibidos!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='col-xs-12 text-center'>
                <button
                  className='btn btn-pagination'
                  disabled={this.props.pageNumber === 1}
                  onClick={() =>
                    this.paginateProducts(this.props.pageNumber - 1)
                  }
                >
                  <span className='glyphicon glyphicon-menu-left' /> Anterior
                </button>
                <button
                  className='btn btn-pagination'
                  disabled={orders.length < pageSize}
                  onClick={() =>
                    this.paginateProducts(this.props.pageNumber + 1)
                  }
                >
                  Próxima <span className='glyphicon glyphicon-menu-right' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = ({ backOffice }) => {
  return {
    ...backOffice,
  };
};

export default connect(
  mapStateToProps,
  {
    ...BackOfficeActions,
    ...FileActions,
  }
)(Orders);
