import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SlugService } from '../../services';
import {
  listCategories
} from '../../actions/CategoryActions';
import './style.scss';

class CategoryMenu extends Component {
  
  componentDidMount = () => {
    if (!this.props.categories.length) this.props.listCategories();
  };

  render = () => {
    const { selectedCategory } = this.props;
    return (
      <ul className="list-unstyled categories">
        {this.props.categories.map(c => (
          <li key={c.id}>
            <Link to={`/vacinas/categoria/${c.id}/${SlugService.apply(c.descricao)}`} >
              <h3 className="title primary">{c.descricao}</h3>
            </Link>
            <ul className="list-unstyled" id="categories">
              {c.categorias.map(c => (
                <li key={c.id} className={(selectedCategory || {}).id === c.id ? 'active' : ''}>
                  <Link to={`/vacinas/categoria/${c.id}/${SlugService.apply(c.descricao)}`} >
                    <img src={`/img/icons/${c.icone ? c.icone : 'syringe-2'}.png`} srcSet={`/img/icons/${c.icone ? c.icone : 'syringe-2'}@2x.png 2x, /img/icons/${c.icone ? c.icone : 'syringe-2'}@3x.png 3x`} alt={c.icone}/> 
                    <span className="text">{ c.descricao }</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
        
      </ul>
    );
  }
}


const mapStateToProps = ({ category }) => {
  return { 
    ...category
  };
};

export default connect(
  mapStateToProps,
  { listCategories }
)(CategoryMenu);