import React, { Component } from "react";
import { Header, Footer, NavigationBar } from "../../components";

import "./style.scss";

class TermsPrivacity extends Component {
  render = () => (
    <div className="terms-privacy">
      <Header />
      <NavigationBar
        title="Portal da Privacidade"
        breadcrumb={[
          { to: "/", title: "Home" },
          { to: "/cadastro", title: "Cadastro" },
          { to: "/termos-privacidade", title: "Termos de Privacidade" },
        ]}
      />
      <div className="gray-container">
        <div className="container">
          <div className="content">
            <h3 className="title text-center">COMO TRATAMOS PRIVACIDADE</h3>
            <p className="text">
              Nós, do <span className="bold">Grupo Dasa</span>, temos o
              propósito de ser um agente transformador da saúde, com medicina de
              excelência, atendimento humanizado, qualidade médica, primor em
              serviços e tecnologia de ponta e, assim, gerar conexões
              inteligentes para a saúde.
            </p>
            <p className="text">
              Buscamos melhorar a vida de nossos pacientes entendendo cada uma
              das suas necessidades, e estamos, ao mesmo tempo, comprometidos em
              proteger e resguardar a privacidade e a proteção dos dados
              pessoais de cada indivíduo. Prezamos a excelência de nossos
              serviços e a proximidade com os clientes, entendendo cada uma das
              suas necessidades, e estamos, ao mesmo tempo, comprometidos em
              proteger e resguardar a privacidade e a proteção dos dados
              pessoais de cada indivíduo.
            </p>
            <p className="text">
              Por isso, levamos muito a sério a nossa política de proteção à
              privacidade dos dados que tratamos, e adotamos as melhores
              práticas de segurança da informação disponíveis no mercado.
              Conheça a nossa Política de Privacidade.
            </p>
            <div className="col-xs-12  btn-container text-center float-none">
              <a
                className="btn btn-submit checkout"
                target="_blank"
                href="https://marketing-dasa.s3-sa-east-1.amazonaws.com/pdfs/dasa/portal+da+privacidade.pdf"
              >
                Aviso de Privacidade
              </a>

              <a
                className="btn btn-submit checkout"
                target="_blank"
                href="https://bkt-sa-east-1-cms-2-assets-prd.s3.sa-east-1.amazonaws.com/prd/sites/7/2021/04/06174123/Pol%C3%ADtica-de-Privacidade-v2.-CGR-site-2.pdf"
              >
                Política de Privacidade
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsPrivacity;
