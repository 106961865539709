import { Messages } from '../../constants';

const validation = {
  email: {
    presence: {
      message: Messages.RequiredEmailValidationMessage,
      allowEmpty: false
    }
  },
  password: {
    presence: {
      message: Messages.RequiredPasswordValidationMessage,
      allowEmpty: false
    }
  }
};

export default validation;
