import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeItemQuantity } from '../../actions/BasketActions';
import './style.scss';

class BasketItemQuantity extends Component {

  state = {
    nextQuantity: -1
  };

  changeItemQuantity = (nextQuantity) => {
    this.setState({ nextQuantity });
    this.props.changeItemQuantity(this.props.item, nextQuantity);
  };

  render = () => {
    const { quantidade } = this.props.item;
    return (
      <div className="basket-item-quantity">
        <button className="btn quantity" onClick={() => this.changeItemQuantity(quantidade - 1)} disabled={this.state.nextQuantity === 1}><span className="glyphicon glyphicon-minus"></span></button>
        <input type="text" readOnly value={quantidade}/>
        <button className="btn quantity plus" onClick={() => this.changeItemQuantity(quantidade + 1)}><span className="glyphicon glyphicon-plus"></span></button>
      </div>
    );
  }
}

const mapStateToProps = ({ basket }) => {
  const { updateInProgress } = basket;
  return { updateInProgress };
};

export default connect(
  null,
  { 
    changeItemQuantity
  }
)(BasketItemQuantity);