import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as BackOfficeActions from '../../../actions/BackOfficeActions';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../../components';
import './style.scss';

class BackOfficeCategories extends Component {
  componentDidMount = () => {
    this.props.searchCategories();
  };

  render = () => {
    const { categories } = this.props;
    return (
      <div className="categories-list">
        <Header />
          <NavigationBar title='Listar Categorias' breadcrumb={[
          { to: '/', title: 'Home' },
          { to: '/backoffice/categorias/', title: 'Listar Categorias' }
        ]}/>
        <div className="gray-container">
          <div className="container">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text">Código</th>
                    <th className="text">Descrição</th>
                    <th className="text">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map(c => (
                    <tr key={c.id}>
                      <td className="text small">{c.id}</td>
                      <td className="text small">{c.descricao}</td>
                      <td>
                      <Link to={`/backoffice/categorias/ordenar/${c.id}`}>
                        <button className="btn btn-edit" title="Ordenar">
                          <span className="glyphicon glyphicon-sort"></span>
                        </button>
                      </Link>
                      </td>
                    </tr>
                  ))}
                  {categories.length === 0 &&
                    <tr>
                      <td className="text text-center" colSpan="7">Não foram encontradas categorias!</td>
                    </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div> 
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = ({ backOfficeCategories }) => {
    return { 
      ...backOfficeCategories
    };
  };
  
  export default connect(
    mapStateToProps,
    BackOfficeActions
  )(BackOfficeCategories);