import WebApi from "./WebApi";
import { CacheKeys } from "../constants";
import { CacheService } from ".";

const zipCodeCacheKey = zipCode => `${CacheKeys.ZipCode}_${zipCode}`;

export class OrderService {
  static async registerOrder(basketId, orderData) {
    const response = await WebApi.post(
      `/checkout/pedido/${basketId}`,
      orderData
    );
    return response.data;
  }

  static async registerShippingAddress(address) {
    const response = await WebApi.post(
      "/checkout/pedido/endereco-entrega",
      address
    );
    return response.data;
  }

  static async registerReceiver(receiverData) {
    const response = await WebApi.post(
      "/checkout/pedido/recebedor",
      receiverData
    );
    return response.data;
  }

  static async pay(orderId, paymentData) {
    const response = await WebApi.post(
      `/checkout/pedido/${orderId}/pagamento`,
      paymentData
    );
    return response.data;
  }

  static async get(orderId) {
    const response = await WebApi.get(`/pedidos/${orderId}`);
    return response.data;
  }

  static async validateZipCode(zipCode) {
    const response = await WebApi.get(`/checkout/carrinho/cep/${zipCode}`);
    return response.data;
  }

  static async listUserOrders() {
    const response = await WebApi.get(`/pedidos/comprador`);
    return response.data;
  }
}
