import axios from 'axios';
import WebApi from './WebApi';
import { FileService } from '.';
import { UploadDestinations } from '../constants';

let cancelSearchRequest = null;

export class ProductService {
  static async search(
    pageNumber,
    pageSize,
    search = null,
    categoryId = null,
    active = true
  ) {
    const CancelToken = axios.CancelToken;
    if (cancelSearchRequest) {
      cancelSearchRequest();
    }
    const response = await WebApi.get(
      '/produtos',
      {
        idCategoria: categoryId,
        busca: search,
        numeroPagina: pageNumber,
        registrosPorPagina: pageSize,
        ativo: active,
      },
      {
        cancelToken: new CancelToken((c) => {
          cancelSearchRequest = c;
        }),
      }
    );
    return response.data;
  }

  static async searchBackOffice(
    pageNumber,
    pageSize,
    search = null,
    categoryId = null,
    active = true
  ) {
    const CancelToken = axios.CancelToken;
    if (cancelSearchRequest) {
      cancelSearchRequest();
    }
    const response = await WebApi.get(
      '/produtos-backoffice',
      {
        idCategoria: categoryId,
        busca: search,
        numeroPagina: pageNumber,
        registrosPorPagina: pageSize,
        ativo: active,
      },
      {
        cancelToken: new CancelToken((c) => {
          cancelSearchRequest = c;
        }),
      }
    );
    return response.data;
  }

  static async get(productId, requiredValidation) {
    const response = await WebApi.get(`/produtos/${productId}`, {
      validarUf: requiredValidation,
    });
    return response.data;
  }

  static async inactive(productId) {
    const response = await WebApi.patch(`/produtos/${productId}/inativacao`);
    return response.data;
  }

  static async active(productId) {
    const response = await WebApi.patch(`/produtos/${productId}/ativacao`);
    return response.data;
  }

  static async update(product, picture) {
    const response = await WebApi.put(`/produtos/${product.id}`, {
      ...product,
      idImagem: picture ? picture.id : null,
    });
    return response.data;
  }

  static async create(product, picture) {
    const response = await WebApi.post(`/produtos`, {
      ...product,
      idImagem: picture ? picture.id : null,
    });
    return response.data;
  }

  static async uploadProductPicture(file) {
    const data = new FormData();
    data.append('EnderecoDestino', `${UploadDestinations.ProductPicture}`);
    data.append('Arquivo', file, `perfil-${new Date().getTime()}.jpg`);
    return await FileService.upload(data);
  }
}
