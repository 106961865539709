import ActionTypes from "../actions/ActionTypes";

const initialState = {
  unityStates: [],
  products: [],
  categories: [],
  name: "",
  active: "",
  pageNumber: 1,
  productEdit: {
    nome: "",
    codigoErp: "",
    indicacao: "",
    contraindicacoes: "",
    esquemaAplicacao: "",
    eventosAdversos: "",
    descricao: "",
    exames: "",
    ativo: true,
    preco: null,
    desconto: "",
    precoDe: null,
    categorias: [],
    estoques: [],
    uf: "",
    entregaSemCusto: false,
    agendamentoObrigatorio: false,
  },
  productId: 0,
  uploadedFile: null
};

const updateProductStatus = (products, productId, active) => {
  for (let i = 0; i < products.length; i += 1) {
    if (products[i].id === productId)
      products[i] = { ...products[i], ativo: active };
  }
  return [...products];
};

const mapProductToState = product => {
  return {
    id: product.id || 0,
    nome: product.nome || "",
    codigoErp: product.codigoErp || "",
    indicacao: product.indicacao || "",
    contraindicacoes: product.contraindicacoes || "",
    esquemaAplicacao: product.esquemaAplicacao || "",
    eventosAdversos: product.eventosAdversos || "",
    descricao: product.descricao || "",
    exames: product.exames || "",
    ativo: product.ativo || false,
    preco: product.preco || null,
    desconto: product.desconto || "",
    precoDe: product.precoDe || null,
    categorias: product.categorias || [],
    comRedirecionamento: product.comRedirecionamento || "",
    urlRedirecionamento: product.urlRedirecionamento || "",
    estoques: product.estoques || [],
    uf: product.uf || "",
    entregaSemCusto: product.entregaSemCusto || false,
    agendamentoObrigatorio: product.agendamentoObrigatorio || false,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_BACKOFFICE_PRODUCTS:
      return { ...state, pageNumber: action.payload.pageNumber };
    case ActionTypes.SEARCH_BACKOFFICE_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload };
    case ActionTypes.UPDATE_BACKOFFICE_PRODUCT_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    case ActionTypes.ACTIVE_BACKOFFICE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: updateProductStatus(state.products, action.payload, true)
      };
    case ActionTypes.CLEAR_REDIRECT_URL:
      return {
        ...state,
        productEdit: { ...state.productEdit, urlRedirecionamento: "" }
      };
    case ActionTypes.SET_TYPE_ACTION_MANAGER_PRODUCT:
      return { ...state, productId: action.payload };
    case ActionTypes.RESET_FORM_PRODUCT_EDIT:
      return { ...initialState };
    case ActionTypes.INACTIVE_BACKOFFICE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: updateProductStatus(state.products, action.payload, false)
      };
    case ActionTypes.FILL_UNITS_UFS_SUCCESS:
      return { ...state, unityStates: action.payload };
    case ActionTypes.FILL_PRODUCT_TO_EDIT:
      return {
        ...state,
        productEdit: mapProductToState(action.payload),
        productId: action.payload.id,
        uploadedFile: action.payload.imagens
          ? action.payload.imagens.find(image => image.fotoCapa)
          : null
      };
    case ActionTypes.UPDATE_BACKOFFICE_PRODUCT_EDIT_FIELD:
      return {
        ...state,
        productEdit: {
          ...state.productEdit,
          [action.payload.prop]: action.payload.value
        }
      };
    case ActionTypes.UPLOAD_PRODUCT_PICTURE_SUCCESS:
      return { ...state, uploadedFile: action.payload };
    case ActionTypes.CREATE_PRODUCT_SUCCESS:
    case ActionTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        productEdit: mapProductToState(action.payload),
        productId: action.payload.id
      };
    case ActionTypes.FILL_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload };
    case ActionTypes.CHECKED_CATEGORY:
      return {
        ...state,
        productEdit: {
          ...state.productEdit,
          categorias: [...state.productEdit.categorias, action.payload]
        }
      };
    case ActionTypes.UNCHECKED_CATEGORY:
      return {
        ...state,
        productEdit: {
          ...state.productEdit,
          categorias: state.productEdit.categorias.filter(
            c => c.id !== action.payload.id
          )
        }
      };
    case ActionTypes.CHECKED_STOK:
      return {
        ...state,
        productEdit: {
          ...state.productEdit,
          estoques: [...state.productEdit.estoques, action.payload]
        }
      };
    case ActionTypes.UNCHECKED_STOK:
      return {
        ...state,
        productEdit: {
          ...state.productEdit,
          estoques: state.productEdit.estoques.filter(e => e !== action.payload)
        }
      };
    default:
      return state;
  }
};
