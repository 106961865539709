import React, { Component } from 'react';
import moment from 'moment';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../components';

import './style.scss';

class Terms extends Component {

  render = () => (
    <div className="terms">
      <Header />
      <NavigationBar title="Termos" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/cadastro', title: 'Cadastro' },
        { to: '/termos', title: 'Termos de Compra e Aplicação' },
      ]}/>
      <div className="gray-container">
        <div className="container">
          <p className="text text-center page-description">Leia o nosso Termo de Condições para Compra e Aplicação de Vacinas antes de realizar a sua primeira compra.</p>
          <div className="content">
          <h3 className="title text-center">TERMO DE CONDIÇÕES PARA COMPRA E APLICAÇÃO DE VACINAS</h3>
            <p className="text">As PARTES acima identificadas assinam o presente instrumento de <span className="bold">TERMO DE CONDIÇÕES PARA COMPRA E APLICAÇÃO DE VACINAS</span>, doravante denominado <span className="bold">"TERMO"</span>, mediante as cláusulas e condições a seguir dispostas:</p>
            <p className="text">Entende-se por CONTRATANTE aquele que acessa o sítio eletrônico do SÃO MARCOS, preenche a ficha cadastral, efetua a compra e pagamento da(s) vacina(s) objeto deste <span className="bold">"TERMO"</span>.</p>
            <p className="text">Entende-se por BENEFICIÁRIO aquele em que a(s) vacina(s) adquirida(s) será(ão) aplicada(s), podendo ser (ou não) a mesma pessoa que o CONTRATANTE neste <span className="bold">"TERMO"</span> identificado.</p>
            <p className="text">1.1 - O objeto do presente <span className="bold">"TERMO"</span> é a compra online do serviço de vacinação para ser(em) aplicada(s) na(s) loja(s) de atendimento do SÃO MARCOS que for(em) escolhida(s) pelo CONTRATANTE/BENEFICIÁRIO, mediante o fornecimento de um comprovante de endereço;</p>
            <p className="text">1.2 - Por ser uma contratação online todo e qualquer questionamento, dúvidas, esclarecimentos e solicitações somente serão realizados por este meio de telefone na Central de Atendimento do Cliente, telefone (31) 2104-0100;</p>
            <p className="text">1.3 – Fica(m) <span className="bold">ciente(s)</span> o(s) CONTRATANTE/BENEFICIÁRIO que, para a efetivação do presente <span className="bold">"TERMO"</span>, fica condicionado que o CONTRATANTE/BENEFICIÁRIO preencha o(s) questionário(s) relativo(s) a(s) vacina(s) adquiridas sendo que neste mesmo ato declara(m) serem verídicas a(s) resposta(s) e que correspondem à realidade dos fatos ora declarados, responsabilizando-se o declarante cível e criminalmente por qualquer erro, irregularidade ou desconformidade nas informações prestadas;</p>

            <p className="text">1.4 - A aplicação da(s) vacina(s) serão realizadas exclusivamente na(s) loja(s) de atendimento do SÃO MARCOS escolhida(s) pelo BENEFICIÁRIO, sendo pessoal e intransferível o direito da aplicação; </p>
            <p className="text">1.4.1. Caso o CONTRATANTE adquira a(s) vacina(s) para aplicação em BENEFICIÁRIO que seja menor de 18 (dezoito) anos, absoluta ou relativamente incapaz(es), assim declarados pela Justiça ou nos termos da Código Civil/02, deverá estar representado ou assistido, inclusive, para o preenchimento das respostas aos questionamentos previsto no item 1.3 retro;</p>
            <p className="text">1.4.2. No momento que antecede a vacinação, o representante legal do BENEFICIÁRIO  do menor ou incapaz(es) deverá apresentar documentação comprobatória da sua condição que demonstre o poder de representação (certidão de nascimento, decisão judicial, procuração e outros) sendo que o SÃO MARCOS reserva para si o direito de não aplicar a vacina no BENEFICIÁRIO caso fique constatado que qualquer documento e/ou informação contida no supracitado questionário tenha sido preenchida de forma errada, irregular, desconforme e/ou que, a exclusivo juízo do SÃO MARCOS, inviabilize a aplicação da vacina;</p>

            <p className="text">1.5 - Fica(m) <span className="bold">ciente(s)</span> o(s) CONTRATANTE/BENEFICIÁRIO que por questões técnicas e de disponibilidade estoque do SÃO MARCOS a(s) aplicação(ões) da(s) vacina(s) deverá(ão) ser(em) ministrada(s) preferencialmente no prazo de até 07 (sete) dias após a formalização deste instrumento na loja de atendimento escolhida pelo CONTRATANTE/BENEFICIÁRIO. Após o prazo sinalizado neste item 1.5. o BENEFICIÁRIO deverá observar a disponibilidade de estoque da(s) vacina(s) na loja de atendimento do SÃO MARCOS por ele escolhida;</p>
            <p className="text">1.5.1. - O CONTRATANTE poderá exercer o seu direito de arrependimento de compra no prazo máximo de 07 (sete) dias contados da compra ou ato de recebimento da(s) vacina(s) nos termos do art. 49 do Código de Defesa do Consumidor - CDC, mediante o envio de correspondência eletrônica para <a href="mailto:tesouraria@gsmarcos.com.br">tesouraria@gsmarcos.com.br</a>;</p>
            <p className="text">1.5.2. Para o exercício do direito expresso neste item 1.5.1 o SÃO MARCOS assegura que o(s) valor(es) pago(s) será(ã) devolvido(s) corrigido(s) monetariamente nos termos do p.u. do art. 49 do Código de Defesa do Consumidor – CDC;</p>
          
            <p className="text">1.6 - O pagamento da presente contratação somente será realizado por meio de cartão de crédito;</p>
            <p className="text">1.7 - As partes elegem o foro da Comarca de Belo Horizonte, Estado de Minas Gerais para dirimir quaisquer questões oriundas deste <span className="bold">"TERMO"</span> que não puderem ser solucionadas de comum acordo. Eventuais omissões, neste instrumento, caso vierem a acontecer, serão tratadas com o indispensável bom senso e com a mais atenta consideração e boa-fé.</p>
            <p className="text">Belo Horizonte, {moment().format('DD/MM/YYYY')}.</p>
            <p className="text bold">SÃO MARCOS – SAÚDE E MEDICINA DIAGNÓSTICA S.A</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;