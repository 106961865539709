import { Messages } from '../../../constants';

const validation = {
  nome: {
    presence: {
      message: Messages.RequiredNameValidationMessage,
      allowEmpty: false
    }
  },
  preco: {
    presence: {
      message: Messages.RequiredPriceValidationMessage,
      allowEmpty: false
    }
  },
  codigoErp: {
    presence: {
      message: Messages.RequiredErpCodeValidationMessage,
      allowEmpty: false
    }
  },
  ativo: {
    presence: {
      message: Messages.RequiredActiveValidationMessage,
      allowEmpty: false
    }
  }
};

export default validation;