export const OrderStatus = {
  WaitingPayment: 'AguardandoPagamento',
  PaymentError: 'ErroPagamento',
  Paid: 'Pago',
  Reversed: 'Estornado',
  Canceled: 'Cancelado',
  Sent: 'EmEntrega',
  Delivered: 'Entregue',
  WaitingSchedule: 'AguardandoAgendamento'
};
