import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "../routes/history";
import ProductSearchReducer from "./ProductSearchReducer";
import LoaderReducer from "./LoaderReducer";
import BasketReducer from "./BasketReducer";
import CategoryReducer from "./CategoryReducer";
import AuthReducer from "./AuthReducer";
import PasswordRecoveryReducer from "./PasswordRecoveryReducer";
import UserRegisterReducer from "./UserRegisterReducer";
import ContactReducer from "./ContactReducer";
import ProductDetailReducer from "./ProductDetailReducer";
import OrderReducer from "./OrderReducer";
import ReceiptReducer from "./ReceiptReducer";
import UserPageReducer from "./UserPageReducer";
import BackOfficeReducer from "./BackOfficeReducer";
import BackOfficeProductsReducer from "./BackOfficeProductsReducer";
import BackOfficeCategoriesReducer from "./BackOfficeCategoriesReducer";
import BannerReducer from "./BannerReducer";

export default combineReducers({
  router: connectRouter(history),
  productSearch: ProductSearchReducer,
  loader: LoaderReducer,
  basket: BasketReducer,
  category: CategoryReducer,
  auth: AuthReducer,
  passwordRecovery: PasswordRecoveryReducer,
  userRegister: UserRegisterReducer,
  contact: ContactReducer,
  productDetail: ProductDetailReducer,
  order: OrderReducer,
  receipt: ReceiptReducer,
  userPage: UserPageReducer,
  backOffice: BackOfficeReducer,
  backOfficeProducts: BackOfficeProductsReducer,
  backOfficeCategories: BackOfficeCategoriesReducer,
  banner: BannerReducer
});
