import React, { Component } from 'react';
import Loading from 'react-loading-bar';
import { connect } from 'react-redux';
import 'react-loading-bar/dist/index.css';

class Loader extends Component {

  render = () => (
    <Loading
      show={this.props.loading}
      color="#00a294"
    />
  );
}

const mapStateToProps = ({ loader }) => {
  return { 
    ...loader
  };
};

export default connect(
  mapStateToProps,
  null
)(Loader);