export const OrderStatusTranslation = {
  AguardandoPagamento: 'Aguardando Pagamento',
  ErroPagamento: 'Erro no Pagamento',
  Pago: 'Pagamento Aprovado',
  Estornado: 'Estornado',
  Cancelado: 'Cancelado',
  BoletoGerado: 'Boleto Gerado',
  EmEntrega: 'Em Entrega',
  Entregue: 'Entregue',
  AguardandoAgendamento: 'Aguardando Agend.'
};
