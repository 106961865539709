import moment from 'moment';

const brDateValidator = (value, options) => {
  if (!value) return null;

  return !moment(value, 'DD/MM/YYYY', true).isValid()
    ? options.message
    : null;
};

export default brDateValidator;
