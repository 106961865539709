import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { 
  Header, 
  Footer,
  NavigationBar,
  ProductList
} from '../../components';
import { updateProductSearchField } from '../../actions/ProductSearchActions';

import './style.scss';

class Search extends Component {

  render = () => {
    const { s } = queryString.parse(this.props.search);
    return (
      <div className="search">
        <Header/>
        <NavigationBar title="Busca" breadcrumb={[
          { to: '/', title: 'Home' },
          { to: '/busca', title: 'Busca' },
        ]}/>
        <div className="gray-container">
          <div className="container">
            <p className="text text-center page-description">Buscando vacinas pelo termo: <span className="bold text-upper">{s}</span></p>
            
            <ProductList colLg={'col-lg-3'} searchTerm={s}/>
          </div>
        </div>
        <Footer />
      </div>
    )
  };
}
const mapStateToProps = ({ productSearch, router }) => {
  const { search } = router.location;
  return { 
    ...productSearch,
    search
  };
};

export default connect(
  mapStateToProps,
  { updateProductSearchField }
)(Search);
