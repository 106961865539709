import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCategory,
  updateCategoryField
} from "../../actions/CategoryActions";
import { redirectToProductSearch } from "../../actions/ProductSearchActions";
import {
  Header,
  Footer,
  Banner,
  ProductList,
  CategoryMenu
} from "../../components";
import "./style.scss";

class Showcase extends Component {
  state = {
    searchTerm: ""
  };

  constructor(props) {
    super(props);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  handleSearchTermChange = event => {
    const { value } = event.target;
    this.setState({ searchTerm: value });
  };

  handleSearchSubmit = event => {
    event.preventDefault();
    this.props.redirectToProductSearch(this.state.searchTerm);
    this.setState({ searchTerm: "" });
  };

  componentDidMount = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.props.getCategory(id);
  };

  componentDidUpdate = prevProps => {
    const prevId = prevProps.match.params.id;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (prevId !== id) {
      this.props.getCategory(id);
    }
  };

  render = () => (
    <div className="show-case">
      <Header />
      <Banner />
      <div className="row without-margin">
        <div className="col-lg-8 left-col">
          <div className="title-container-product">
            <img
              src="/img/icons/heart.png"
              srcSet="/img/icons/heart@2x.png 2x, /img/icons/heart@3x.png 3x"
              className="pull-left"
            />
            <h2 className="title">{this.props.descricao || "Produtos"}</h2>
          </div>
          <form
            className="col-lg-12 title-container-product"
            onSubmit={this.handleSearchSubmit}
          >
            <input
              type="text"
              name="search"
              placeholder="O que você procura hoje?"
              value={this.state.searchTerm}
              onChange={this.handleSearchTermChange}
            />
            <button className="btn btn-search-product" type="submit">
              <img
                src="/img/icons/search-2.png"
                srcSet="/img/icons/search-2@2x.png 2x,/img/icons/search-2@3x.png 3x"
              />
            </button>
          </form>
          <ProductList />
          {(this.props.calendarioVacinacao || []).length > 0 && (
            <div className="calendar-container hidden">
              <div className="title-container">
                <img
                  src="/img/icons/calendar.png"
                  srcSet="/img/icons/calendar@2x.png 2x, /img/icons/calendar@3x.png 3x"
                  className="pull-left"
                />
                <h2 className="title">Calendário de Vacinação</h2>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text semi-bold">Idades</th>
                        <th className="text semi-bold">Vacinas</th>
                        <th className="text semi-bold">Dose</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.calendarioVacinacao || []).map(c => (
                        <tr key={c.id}>
                          <td className="text">{c.idade}</td>
                          <td
                            className="text"
                            dangerouslySetInnerHTML={{ __html: c.vacinas }}
                          ></td>
                          <td className="text">{c.doses}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-4 right-col">
          <CategoryMenu selectedCategory={this.props.selectedCategoryId} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ category }) => {
  const { selectedCategory, selectedCategoryId } = category;
  return {
    ...selectedCategory,
    selectedCategoryId
  };
};

export default connect(
  mapStateToProps,
  { getCategory, updateCategoryField, redirectToProductSearch }
)(Showcase);
