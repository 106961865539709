import React, { Component } from 'react';
import Alert from 'react-s-alert';
import { Provider } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { registerCustomValidators } from '../../validators';
import Routes from '../../routes';
import store from '../../store';
import { Helmet } from "react-helmet";
import '../../shared/common.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap.min';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import { StoreService } from '../../services';

class App extends Component {
  unlisten = null;

  alertConfig = {
    position: 'top-right',
    effect: 'flip',
  };

  componentDidMount = () => {
    registerCustomValidators();
    if (StoreService.isLabHormon()) { 
      ReactPixel.init(
        process.env.REACT_APP_FACEBOOK_PIXEL_CODE, 
        null, 
        { autoConfig: true });
    }
  };

  render() {
    return (
      <Provider store={store}>
        <Helmet>
          <title>Loja Virtual - São Marcos Laboratório</title>
          <meta name="description" content="Mantenha a saúde em dia com comodidade! A Loja Virtual São Marcos oferece Vacinas e Check Ups para Recém Nascidos, Crianças, Gestantes, Idosos e muito mais!" />
        </Helmet>
        <Routes />
        <Alert 
          stack={{ limit: 5 }} 
          html 
          position={'top-right'} 
          effect={'flip'}
          timeout={5000}
        />
      </Provider>
    );
  }
}

export default App;
