import moment from 'moment';
import ActionTypes from '../actions/ActionTypes';
import { ShippingType, PaymentType } from '../constants';

const initialState = {
  receiver: {
    name: '',
    birthday: '',
    genre: '',
    relationship: '',
    cpf: '',
    type: '',
    dontHaveCpf: false,
  },
  shipping: {
    id: '',
    type: ShippingType.Unity,
    unityId: '',
    driveThru: false,
    cep: '',
    state: '',
    city: '',
    neighborhood: '',
    address: '',
    tax: 0,
    complement: '',
    number: '',
    deliverySuggestion: '',
    cepIsRural: '',
  },
  payment: {
    parcels: 1,
    parcelValue: 0,
    type: PaymentType.CreditCard,
    cardNumber: '',
    securityCode: '',
    expirationMonth: '',
    expirationYear: '',
    cardName: '',
    brand: '',
  },
  units: [],
  allUnits: [],
  parcels: [],
  canProcceed: false,
  zipCodeValidated: false,
  order: null,
  paymentInProgress: false,
  termsAccepted: false,
  termsPrivacyAccepted: false,
  schedulingDays: [],
  schedulingHours: [],
  schedulingHoursOptions: [],
  schedulingDate: '',
  schedulingHour: '',
  doctorId: null,
};

function removeDuplicates(array, key) {
  let lookup = new Set();
  return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FILL_DOCTOR:
      return { ...state, doctorId: action.payload };
    case ActionTypes.UPDATE_SHCEDULING_HOUR:
      return { ...state, schedulingHour: action.payload };
    case ActionTypes.CLEAR_SHCEDULING_SELECTED_DATA:
      return { ...state, schedulingDate: '', schedulingHour: '' };
    case ActionTypes.SEARCH_SCHEDULING_HOUR_SUCCESS:
      return {
        ...state,
        schedulingHours: action.payload,
        schedulingHoursOptions: removeDuplicates(action.payload, 'horario').map(
          (h) => h.horario
        ),
      };
    case ActionTypes.SEARCH_SCHEDULING_DAY:
      return {
        ...state,
        schedulingHour: '',
        schedulingHoursOptions: [],
        schedulingHours: [],
      };
    case ActionTypes.UPDATE_SHCEDULING_DAY:
      return { ...state, schedulingDate: action.payload, schedulingHour: '' };
    case ActionTypes.SEARCH_SCHEDULING_DAY_SUCCESS:
      return { ...state, schedulingDays: action.payload };
    case ActionTypes.ADD_PRODUCT_TO_BASKET_SUCCESS:
      return { ...initialState };
    case ActionTypes.REMOVE_PRODUCT_FROM_BASKET_SUCCESS:
      return {
        ...state,
        units: action.payload.produtos.find((p) => p.vacina)
          ? state.allUnits.filter((u) => u.vacinadora)
          : state.allUnits,
      };
    case ActionTypes.LIST_UNITS_SUCCESS:
      return {
        ...state,
        allUnits: action.payload.units,
        units: action.payload.products.find((p) => p.vacina)
          ? action.payload.units.filter((u) => u.vacinadora)
          : action.payload.units,
      };
    case ActionTypes.LOGOUT_USER_SUCCESS:
    case ActionTypes.PAY_ORDER_SUCCESS:
      return { ...initialState, units: state.units };
    case ActionTypes.VALIDATE_UNITY_AVAILABILITY_SUCCESS:
      return { ...state, canProcceed: action.payload.allProductsAreAvailable };
    case ActionTypes.UPDATE_ORDER_FIELD:
      return { ...state, [action.payload.prop]: action.payload.value };
    case ActionTypes.VALIDATE_SHIPPING_ZIPCODE_SUCCESS:
      return {
        ...state,
        zipCodeValidated: true,
        shipping: {
          ...state.shipping,
          ...action.payload,
          state: action.payload.localization.estado,
          city: action.payload.localization.cidade,
          neighborhood: action.payload.localization.bairro,
          address: action.payload.localization.logradouro,
          cepIsRural: action.payload.localization.cepRural,
        },
      };
    case ActionTypes.CLEAR_DELIVERY_TAX:
      return { ...state, shipping: { ...state.shipping, tax: 0 } };
    case ActionTypes.HANDLE_SHIPPING_TYPE_CHANGE_SUCCESS:
      return { ...state, shipping: { ...state.shipping, ...action.payload } };
    case ActionTypes.FILL_RECEIVER_FROM_LOGGED_USER:
      return {
        ...state,
        receiver: {
          ...state.receiver,
          name: action.payload.nome || '',
          cpf: action.payload.cpf || '',
          genre: action.payload.sexo || '',
          birthday: action.payload.dataNascimento
            ? moment(action.payload.dataNascimento).format('DD/MM/YYYY')
            : '',
          dontHaveCpf: false,
        },
      };
    case ActionTypes.VALIDATE_SHIPPING_ZIPCODE_FAIL:
      return {
        ...state,
        shipping: {
          ...initialState.shipping,
          unityId: state.shipping.unityId,
          cep: state.shipping.cep,
          type: state.shipping.type,
          tax: state.shipping.tax,
          deliverySuggestion: state.shipping.deliverySuggestion,
        },
        zipCodeValidated: false,
      };
    case ActionTypes.FILL_SHIPPING_ADDRESS_WITH_LOGGED_USER_ADDRESS:
      return {
        ...state,
        shipping: {
          ...state.shipping,
          cep: action.payload.cep,
          address: action.payload.logradouro,
          city: action.payload.cidade,
          state: action.payload.estado,
          neighborhood: action.payload.bairro,
          number: action.payload.numero,
          complement: action.payload.complemento,
        },
      };
    case ActionTypes.CALCULATE_PAYMENT_PARCELS:
      return { ...state, parcels: action.payload };
    case ActionTypes.IDENTIFY_CARD_BRAND_FROM_CARD_NUMBER:
      return { ...state, payment: { ...state.payment, ...action.payload } };
    case ActionTypes.REGISTER_ORDER_SUCCESS:
      return { ...state, order: action.payload };
    case ActionTypes.PAY_ORDER:
      return { ...state, paymentInProgress: true };
    case ActionTypes.PAY_ORDER_SUCCESS:
    case ActionTypes.PAY_ORDER_FAIL:
      return { ...state, paymentInProgress: false };
    case ActionTypes.CHANGE_BRAND:
      return { ...state, payment: { ...state.payment, brand: action.payload } };
    default:
      return state;
  }
};
