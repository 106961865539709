import React, { Component } from 'react';
import './style.scss';

class CheckoutSteps extends Component {

  render = () => {
    const { payment, basket, receipt, products } = this.props;
    const cols = products && products.find(p => p.agendamentoObrigatorio) ? 3 : 4;
    return (
      <div className="checkout-steps hidden-xs">
        <div className="container">
          <div className="row line">
            <div className={`col-lg-${cols} col-md-${cols} col-sm-${cols}`}>
              <div className="pull-left text-left step">
                <div className={`circle ${basket ? 'complete' : ''}`}></div>
                <span className="text">Pedido</span>
              </div>
            </div>
            <div className={`col-lg-${cols} col-md-${cols} col-sm-${cols}`}>
              <div className="center text-center step">
                <div className={`circle ${payment ? 'complete' : ''}`}></div>
                <span className="text">Pagamento</span>
              </div>
            </div>
            {(products && products.find(p => p.agendamentoObrigatorio)) && (
              <div className={`col-lg-${cols} col-md-${cols} col-sm-${cols}`}>
                <div className="center text-center step">
                  <div className={`circle ${receipt ? 'complete' : ''}`}></div>
                  <span className="text">Agendamento</span>
                </div>
              </div>
            )}
            <div className={`col-lg-${cols} col-md-${cols} col-sm-${cols}`}>
              <div className="pull-right text-right step">
                <div className={`circle ${receipt ? 'complete' : ''}`}></div>
                <span className="text">Recibo</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutSteps;