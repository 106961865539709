import React, { Component } from "react";
import { connect } from "react-redux";
import * as BackOfficeActions from "../../../actions/BackOfficeActions";
import { Header, Footer, NavigationBar } from "../../../components";
import { ValidationService } from "../../../services";
import validation from "./validation";
import NumberFormat from "react-number-format";
import "./style.scss";

class BackOfficeProductEdit extends Component {
  componentDidMount = () => {
    this.props.fillUnitsUfs();
    this.props.fillCategories();
    if (this.props.productId > 0)
      this.props.fillProductToEdit(this.props.productId);
    else this.props.resetFormProductEdit();
  };

  formIsValid = () =>
    ValidationService.validate(this.props.productEdit, validation);

  handleSubmit = event => {
    event.preventDefault();
    if (!this.formIsValid()) return;
    if (this.props.productId > 0) this.props.updateProduct(this.props);
    else this.props.createProduct(this.props);
  };

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updateProductField({ prop, value });
  };

  handleChangeMoney = (prop, value) => {
    this.props.updateProductField({ prop, value });
  };

  handleFileChange = event => {
    const { files } = event.target;
    const file = files.length > 0 ? files[0] : null;
    this.props.uploadProductPicture(file);
  };

  handleChangePartnerSell = event => {
    this.handleChange("comRedirecionamento", event);
    if (!event.target.value) this.props.clealRedirectUrl();
  };

  handleChangeCategory(category) {
    if (this.props.productEdit.categorias.find(cat => cat.id === category.id))
      this.props.uncheckedCategory(category);
    else this.props.checkedCategory(category);
  }

  handleChangeStock(state) {
    if (this.props.productEdit.estoques.find(s => s === state))
      this.props.uncheckedStok(state);
    else this.props.checkedStok(state);
  }

  render = () => {
    const product = this.props.productEdit;
    return (
      <div className="product-edit">
        <Header />
        <NavigationBar
          title={this.props.productId ? "Editar Produto" : "Cadastrar Produto"}
          breadcrumb={[
            { to: "/", title: "Home" },
            { to: "/backoffice/produtos", title: "Gerenciar Produtos" },
            {
              to: "/backoffice/produtos/edicao",
              title: this.props.productId
                ? "Editar Produto"
                : "Cadastrar Produto"
            }
          ]}
        />
        {}
        <div className="form-container">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-xs-12 text-center">
                <label htmlFor="file" className="uploader">
                  {!this.props.uploadedFile && (
                    <div className="user-picture">
                      <img src="/img/vacina.png" className="avatar" />
                      <span className="camera text-center">
                        <img
                          src="/img/icons/camera.png"
                          srcSet="/img/icons/camera@2x.png 2x, /img/icons/camera@3x.png 3x"
                        />
                      </span>
                    </div>
                  )}
                  {this.props.uploadedFile && (
                    <div className="user-picture">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/arquivos/${this.props.uploadedFile.id}`}
                        className="avatar"
                      />
                      <span className="camera text-center">
                        <img
                          src="/img/icons/camera.png"
                          srcSet="/img/icons/camera@2x.png 2x, /img/icons/camera@3x.png 3x"
                        />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  id="file"
                  type="file"
                  className="file-input"
                  onChange={this.handleFileChange}
                ></input>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center">
                <p className="text small">
                  Clique sobre a imagem para selecionar
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Nome *</label>
                <input
                  type="text"
                  className="form-control"
                  name="nome"
                  value={product.nome}
                  onChange={e => this.handleChange("nome", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Código Erp *</label>
                <input
                  type="text"
                  className="form-control"
                  name="codigoErp"
                  value={product.codigoErp}
                  onChange={e => this.handleChange("codigoErp", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Indicação</label>
                <textarea
                  rows="4"
                  type="text"
                  className="form-control"
                  name="indicacao"
                  value={product.indicacao}
                  onChange={e => this.handleChange("indicacao", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Contraindicação</label>
                <textarea
                  rows="4"
                  type="text"
                  className="form-control"
                  name="contraindicacoes"
                  value={product.contraindicacoes}
                  onChange={e => this.handleChange("contraindicacoes", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Esquema de Aplicação</label>
                <textarea
                  rows="4"
                  type="text"
                  className="form-control"
                  name="esquemaAplicacao"
                  value={product.esquemaAplicacao}
                  onChange={e => this.handleChange("esquemaAplicacao", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Eventos Adversos</label>
                <textarea
                  rows="4"
                  type="text"
                  className="form-control"
                  name="eventosAdversos"
                  value={product.eventosAdversos}
                  onChange={e => this.handleChange("eventosAdversos", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Descrição</label>
                <textarea
                  rows="4"
                  type="text"
                  className="form-control"
                  name="descricao"
                  value={product.descricao}
                  onChange={e => this.handleChange("descricao", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Exames</label>
                <textarea
                  rows="4"
                  type="text"
                  className="form-control"
                  name="exames"
                  value={product.exames}
                  onChange={e => this.handleChange("exames", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Status *</label>
                <select
                  name="status"
                  value={product.ativo}
                  onChange={ev => this.handleChange("ativo", ev)}
                >
                  <option value="true">ATIVO</option>
                  <option value="false">INATIVO</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Preço *</label>
                <NumberFormat
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"R$ "}
                  value={product.preco}
                  onValueChange={e =>
                    this.handleChangeMoney("preco", e.floatValue)
                  }
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Desconto</label>
                <input
                  type="text"
                  className="form-control"
                  name="desconto"
                  value={product.desconto}
                  onChange={e => this.handleChange("desconto", e)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Preço De</label>
                <NumberFormat
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"R$ "}
                  value={product.precoDe}
                  onValueChange={e =>
                    this.handleChangeMoney("precoDe", e.floatValue)
                  }
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Vendido por parceiro</label>
                <select
                  name="comRedirecionamento"
                  value={product.comRedirecionamento}
                  onChange={ev => this.handleChangePartnerSell(ev)}
                >
                  <option value="">NÃO</option>
                  <option value="true">SIM</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">URL Redirecionamento</label>
                <input
                  type="text"
                  className="form-control"
                  name="urlRedirecionamento"
                  value={product.urlRedirecionamento}
                  onChange={e => this.handleChange("urlRedirecionamento", e)}
                  disabled={!product.comRedirecionamento}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Entrega sem custo</label>
                <select
                  name="entregaSemCusto"
                  value={product.entregaSemCusto}
                  onChange={ev => this.handleChange("entregaSemCusto", ev)}
                >
                  <option value="">NÃO</option>
                  <option value="true">SIM</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">Agendamento obrigatório</label>
                <select
                  name="agendamentoObrigatorio"
                  value={product.agendamentoObrigatorio}
                  onChange={ev => this.handleChange("agendamentoObrigatorio", ev)}
                >
                  <option value="">NÃO</option>
                  <option value="true">SIM</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 form-group">
                <label className="text primary">UF *</label>
                <select
                  name="uf"
                  value={product.uf}
                  onChange={e => this.handleChange("uf", e)}
                >
                  <option value="">SELECIONE</option>
                  {this.props.unityStates.map(u => (
                    <option key={u} value={u}>{u}</option>
                  ))};
                </select>
              </div>
              {this.props.productId ? (
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 form-check stock-box">
                  <label className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text primary stock">
                    Estoque *
                  </label>
                  {this.props.unityStates.map(u => (
                    <div
                      key={u}
                      className="col-lg-6 col-md-6 col-sm-12 col-xs-12 input-stock"
                    >
                      <input
                        type="checkbox"
                        name="categorias"
                        checked={false}
                        checked={
                          product.estoques.find(e => e === u) ? true : false
                        }
                        onChange={e => this.handleChangeStock(u)}
                      />
                      <label className="txt-stock">{u}</label>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-check">
                <label className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text primary category">
                  Categorias *
                </label>
                {this.props.categories.map(c => (
                  <div
                    key={c.id}
                    className="col-lg-6 col-md-6 col-sm-12 col-xs-12 input-category"
                  >
                    <input
                      type="checkbox"
                      name="categorias"
                      checked={
                        product.categorias.find(cat => cat.id === c.id)
                          ? true
                          : false
                      }
                      onChange={e => this.handleChangeCategory(c)}
                    />
                    <label className="txt-category">{c.descricao}</label>
                  </div>
                ))}
              </div>
              <div className="col-xs-12 text-center btn-container">
                <button type="submit" className="btn btn-submit">
                  SALVAR
                </button>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = ({ backOfficeProducts }) => {
  return {
    ...backOfficeProducts
  };
};

export default connect(
  mapStateToProps,
  BackOfficeActions
)(BackOfficeProductEdit);
