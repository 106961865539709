import { Messages } from '../../constants';

const validation = {
  
  name: {
    presence: {
      message: Messages.RequiredNameValidationMessage,
      allowEmpty: false
    }
  },
  cpf: {
    presence: {
      message: Messages.RequiredCpfValidationMessage,
      allowEmpty: false
    },
    cpf: {
      message: Messages.InvalidCpfValidationMessage
    }
  },
  motherName: {
    presence: {
      message: Messages.RequiredMotherNameValidationMessage,
      allowEmpty: false
    }
  },
  rg: {
    presence: {
      message: Messages.RequiredRgValidationMessage,
      allowEmpty: false
    }
  },
  email: {
    presence: {
      message: Messages.RequiredEmailValidationMessage,
      allowEmpty: false
    },
    email: {
      message: Messages.InvalidEmailValidationMessage,
    }
  },
  birthday: {
    brDate: {
      message: Messages.InvalidBirthdayValidationMessage,
    },
    presence: {
      message: Messages.RequiredBirthdayValidationMessage,
      allowEmpty: false
    }
  },
  genre: {
    presence: {
      message: Messages.RequiredGenreValidationMessage,
      allowEmpty: false
    }
  },
  cep: {
    presence: {
      message: Messages.RequiredCepValidationMessage,
      allowEmpty: false
    }
  },
  address: {
    presence: {
      message: Messages.RequiredAddressValidationMessage,
      allowEmpty: false
    }
  },
  number: {
    presence: {
      message: Messages.RequiredNumberValidationMessage,
      allowEmpty: false
    }
  },
  city: {
    presence: {
      message: Messages.RequiredCityValidationMessage,
      allowEmpty: false
    }
  },
  state: {
    presence: {
      message: Messages.RequiredStateValidationMessage,
      allowEmpty: false
    }
  },
  neighborhood: {
    presence: {
      message: Messages.RequiredNeighborhoodValidationMessage,
      allowEmpty: false
    }
  },
  cellPhone: {
    presence: {
      message: Messages.RequiredCellPhoneValidationMessage,
      allowEmpty: false
    },
  },
  password: {
    presence: (value, attributes) => {
      if (!attributes.id) {
        return { 
          message: Messages.RequiredPasswordValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
  confirmation: {
    equality: {
      attribute: 'password',
      message: Messages.InvalidConfirmationPasswordValidationMessage
    },
    presence: (value, attributes) => {
      if (attributes.password) {
        return { 
          message: Messages.RequiredPasswordConfirmationValidationMessage,
          allowEmpty: false
        };
      }
      return false;
    }
  },
};

export default validation;