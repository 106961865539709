import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  Header, 
  Footer,
  NavigationBar
} from '../../components';
import { login, updateLoginField } from '../../actions/AuthActions';
import { ValidationService } from '../../services';
import validation from './validation';
import './style.scss';

class Login extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formIsValid = () => ValidationService.validate(this.props, validation);

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updateLoginField({ prop, value });
  };

  handleSubmit = (event) => {
    const { email, password } = this.props;
    event.preventDefault();
    if (!this.formIsValid()) return;

    this.props.login(email, password);
  };

  render = () => (
    <div className="login">
      <Header />
      <NavigationBar title="Login" breadcrumb={[
        { to: '/', title: 'Home' },
        { to: '/login', title: 'Login' },
      ]}/>
      <div className="gray-container">
        <div className="container">
          <p className="text text-center page-description">Entre com seu e-mail e senha ou faça um cadastro e crie uma conta em nosso site.</p>
          <div className="form-container">
            <form className="login-form" onSubmit={this.handleSubmit}>
              <h3 className="title text-center bold">Login</h3>
              <div className="form-group">
                <label className="text bold">E-mail:</label>
                <input type="text" name="email" autoComplete="off" value={this.props.email} onChange={e => this.handleChange('email', e)}/>
              </div>
              <div className="form-group">
                <label className="text bold">Senha:</label>
                <input type="password" name="password" autoComplete="off" value={this.props.password} onChange={e => this.handleChange('password', e)}/>
              </div>
              <button type="submit" className="btn bold">ENTRAR</button>
              <div className="text-center">
                <Link to="/esqueci-minha-senha" className="forgot-password bold">Esqueci minha senha</Link>
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link to="/cadastro" className="register">CRIAR UMA CONTA</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  return { 
    ...auth
  };
};

export default connect(
  mapStateToProps,
  { updateLoginField, login }
)(Login);