import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from './routes/history';
import reducers from './reducers';

const middlewares = [
  thunk,
  routerMiddleware(history)
];

const store = createStore(
  connectRouter(history)(reducers), 
  applyMiddleware(...middlewares)
 );

export default store;