import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { verifyIfUserIsLogged } from '../actions/AuthActions';
import { fillBasketFromStorage } from '../actions/BasketActions';
import { resetUserRegisterForm } from '../actions/UserRegisterActions';
import { fillBanners } from '../actions/BannerActions';
import { fillUserRegisterFromLoggedUser } from '../actions/UserPageActions';
import history from './history';
import { isAuthenticated } from './auth';
import {
  Basket,
  Contact,
  Login,
  Payment,
  ProductDetail,
  Receipt,
  Register,
  Search,
  Showcase,
  Terms,
  TermsPrivacity,
  ForgotPassword,
  PasswordRecoveryCode,
  PasswordRecovery,
  UserPageOrders,
  BackOfficeOrders,
  BackOfficeProducts,
  BackOfficeProductEdit,
  BackOfficeCategories,
  BackOfficeCategorySort,
  Scheduling,
} from '../screens';
import { AuthenticationService, StoreService } from '../services';
import { UserProfile } from '../constants';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const userIsAuthenticated = isAuthenticated();
      if (!userIsAuthenticated)
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );

      const loggedUser = AuthenticationService.getLoggedUser();
      if (rest.onlyAdmin && loggedUser.perfil.codigo !== UserProfile.Admin)
        return (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );

      return <Component {...props} />;
    }}
  />
);

const trackPageView = (WrappedComponent, pageType) => {
  return class extends Component {
    componentDidMount = () => {
      if (window.dataLayer) {
        const dataLayer = {
          event: 'virtualPageView',
          pageType: pageType,
        };
        window.dataLayer.push(dataLayer);
      }
      if (StoreService.isLabHormon()) ReactPixel.pageView();
    };

    render = () => <WrappedComponent {...this.props} />;
  };
};

class Routes extends Component {
  unlisten = null;

  state = {
    reduxLoaded: false,
  };

  componentDidMount = () => {
    this.fillReduxFromStorage();
  };

  fillReduxFromStorage = () => {
    this.props.verifyIfUserIsLogged();
    this.props.fillBasketFromStorage();
    this.props.fillBanners();
    this.setState({ reduxLoaded: true });
  };

  render = () =>
    this.state.reduxLoaded ? (
      <ConnectedRouter history={history}>
        <Switch>
          <PrivateRoute
            exact
            path='/pagamento'
            component={trackPageView(Payment, 'pagamento-checkout')}
          />
          <PrivateRoute
            exact
            path='/agendamento/:orderId'
            component={trackPageView(Scheduling, 'agendameneto-detalhe')}
          />
          <PrivateRoute
            exact
            path='/recibo/:orderId'
            component={trackPageView(Receipt, 'recibo-detalhe')}
          />
          <PrivateRoute
            exact
            path='/meus-pedidos'
            component={trackPageView(UserPageOrders, 'meus-pedido-listagem')}
          />
          <PrivateRoute
            exact
            path='/meus-dados'
            component={trackPageView(Register, 'meus-dados-edicao')}
          />
          <PrivateRoute
            exact
            path='/backoffice/pedidos'
            component={trackPageView(
              BackOfficeOrders,
              'administracao-pedido-listagem'
            )}
            onlyAdmin
          />
          <PrivateRoute
            exact
            path='/backoffice/produtos'
            component={trackPageView(
              BackOfficeProducts,
              'administracao-produtos-listagem'
            )}
            onlyAdmin
          />
          <PrivateRoute
            exact
            path='/backoffice/produtos/gerenciar'
            component={trackPageView(
              BackOfficeProductEdit,
              'administracao-editar-produto'
            )}
            onlyAdmin
          />
          <PrivateRoute
            exact
            path='/backoffice/categorias'
            component={trackPageView(
              BackOfficeCategories,
              'administracao-categorias-listagem'
            )}
            onlyAdmin
          />
          <PrivateRoute
            exact
            path='/backoffice/categorias/ordenar/:categoryId'
            component={trackPageView(
              BackOfficeCategorySort,
              'administracao-categorias-busca'
            )}
            onlyAdmin
          />
          <Route
            exact
            path='/cadastro'
            component={trackPageView(Register, 'cadastro')}
          />
          <Route
            exact
            path='/'
            exact
            component={trackPageView(Showcase, 'home')}
          />
          <Route
            exact
            path='/pedido'
            component={trackPageView(Basket, 'carrinho')}
          />
          <Route
            exact
            path='/contato'
            component={trackPageView(Contact, 'contatos')}
          />
          <Route
            exact
            path='/login'
            component={trackPageView(Login, 'login')}
          />
          <Route
            exact
            path='/vacinas/:id/:name'
            component={trackPageView(ProductDetail, 'detalhes-produto')}
          />
          <Route
            exact
            path='/busca'
            component={trackPageView(Search, 'busca-produto')}
          />
          <Route
            exact
            path='/vacinas/categoria/:id/:description'
            exact
            component={trackPageView(Showcase, 'home-listagem-filtrada')}
          />
          <Route
            exact
            path='/vacinas/categoria/:id'
            exact
            component={trackPageView(Showcase, 'home-listagem-filtrada')}
          />
          <Route
            exact
            path='/esqueci-minha-senha'
            exact
            component={trackPageView(ForgotPassword, 'esqueceu-senha')}
          />
          <Route
            exact
            path='/esqueci-minha-senha/codigo'
            exact
            component={trackPageView(
              PasswordRecoveryCode,
              'validacao-codigo-senha'
            )}
          />
          <Route
            exact
            path='/esqueci-minha-senha/codigo/:code'
            exact
            component={trackPageView(PasswordRecovery, 'cadastro-nova-senha')}
          />
          <Route
            exact
            path='/termos'
            component={trackPageView(Terms, 'termos')}
          />
          <Route
            exact
            path='/termos-privacidade'
            component={trackPageView(TermsPrivacity, 'termos-privacidade')}
          />
        </Switch>
      </ConnectedRouter>
    ) : null;
}

const mapStateToProps = ({ auth, app }) => {
  const { loggedUser } = auth;
  return {
    loggedUser,
    ...app,
  };
};

export default connect(mapStateToProps, {
  verifyIfUserIsLogged,
  fillBasketFromStorage,
  resetUserRegisterForm,
  fillUserRegisterFromLoggedUser,
  fillBanners,
})(Routes);
