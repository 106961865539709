import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Banner extends Component {
  settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  render = () => (
    <div className="banner hidden-xs">
      <Slider {...this.settings}>
        {this.props.banners.map(b => (
          <React.Fragment key={b.id}>
            {b.link ? (
              <a href={b.link} target="_blank">
                <img src={b.path} className="banner-link" />
              </a>
            ) : (
              <a>
                <img src={b.path} className="banner-link" />
              </a>
            )}
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
}

const mapStateToProps = ({ banner }) => {
  return {
    ...banner
  };
};

export default connect(
  mapStateToProps,
  null
)(Banner);
