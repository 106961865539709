import { Messages } from "../../constants";

const validation = {
  termsAccepted: {
    inclusion: {
      within: [true],
      message: Messages.RequiredBasketAcceptTermsValidationMessage,
    },
  },
  termsPrivacyAccepted: {
    inclusion: {
      within: [true],
      message: Messages.RequiredBasketAcceptTermsPrivacyValidationMessage,
    },
  },
};

export default validation;
