import React, { Component } from "react";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { Header, Footer, NavigationBar } from "../../components";
import { sendContact, updateContactField } from "../../actions/ContactActions";
import "./style.scss";
import { ValidationService } from "../../services";
import validation from "./validation";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  formIsValid = () => ValidationService.validate(this.props, validation);

  handleChange = (prop, event) => {
    const { value } = event.target;
    this.props.updateContactField({ prop, value });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!this.formIsValid()) return;
    this.props.sendContact(this.props);
  };

  render = () => (
    <div className="contact">
      <Header />
      <NavigationBar
        title="Contato"
        breadcrumb={[
          { to: "/", title: "Home" },
          { to: "/contato", title: "Contato" }
        ]}
      />
      <div className="gray-container">
        <div className="container">
          <p className="text text-center page-description">
            Este é o seu canal direto com a São Marcos laboratório, e poderá
            entrar em contato conosco através do nosso telefone, e-mail ou
            formulário de contato.
          </p>
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 form-container">
              <h2 className="title text-center semi-bold">
                Formulário de Contato
              </h2>
              <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <label className="text">Nome *</label>
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    value={this.props.name}
                    onChange={e => this.handleChange("name", e)}
                  />
                </div>
                <div className="input-group">
                  <label className="text">Estado *</label>
                  <select
                    className="state-select"
                    name="state"
                    value={this.props.state}
                    onChange={ev => this.handleChange("state", ev)}
                  >
                    <option value="">Selecione</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>
                <div className="input-group">
                  <label className="text">E-mail *</label>
                  <input
                    placeholder="seuemail@email.com"
                    type="text"
                    name="email"
                    autoComplete="off"
                    value={this.props.email}
                    onChange={e => this.handleChange("email", e)}
                  />
                </div>
                <div className="input-group">
                  <label className="text">Telefone *</label>
                  <InputMask
                    type="text"
                    placeholder="(xx) 99999-9999"
                    mask="(99) 99999-9999"
                    alwaysShowMask={false}
                    maskChar=""
                    name="phone"
                    value={this.props.phone}
                    onChange={e => this.handleChange("phone", e)}
                  />
                </div>
                <div className="input-group">
                  <label className="text">Mensagem *</label>
                  <textarea
                    rows="5"
                    name="message"
                    autoComplete="off"
                    onChange={e => this.handleChange("message", e)}
                    value={this.props.message}
                  />
                </div>
                <div className="btn-container text-center">
                  <button className="btn btn-submit" type="submit">
                    ENVIAR
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 right-col">
              <h2 className="title text-center semi-bold">Atendimento</h2>
              <div className="phone-container">
                <h3 className="title text-center">
                  Central de <br />
                  Relacionamento
                </h3>
                <div className="phone first-number first text-center">
                  <div className="text state-unity">MG - São Marcos</div>
                  <a href="callto:03121040100" className="text" target="_blank">
                    <img
                      src="/img/icons/phone.png"
                      srcSet="/img/icons/phone@2x.png 2x, /img/icons/phone@3x.png 3x"
                    />
                    (31) 2104-0100
                  </a>
                </div>
                <div className="phone last-number first text-center">
                  <div className="text state-unity">SP - Labhormon</div>
                  <a href="callto:01144333233" className="text" target="_blank">
                    <img
                      src="/img/icons/phone.png"
                      srcSet="/img/icons/phone@2x.png 2x, /img/icons/phone@3x.png 3x"
                    />
                    (11) 4433-3233
                  </a>
                </div>
                <h3 className="title text-center">
                  Atendimento <br />
                  Whatsapp
                </h3>
                <div className="phone text-center">
                  <div className="text state-unity">MG - São Marcos</div>
                  <div className="first-number">
                    <a
                      href="https://wa.me/+55031988721551"
                      className="text"
                      target="_blank"
                    >
                      <img
                        src="/img/icons/whatsapp.png"
                        srcSet="/img/icons/whatsapp@2x.png 2x, /img/icons/whatsapp@3x.png 3x"
                      />
                      (31) 98872-1551
                    </a>
                  </div>
                  <div className="text state-unity">SP - Labhormon</div>
                  <div>
                    <a
                      href="https://wa.me/+55011993532070"
                      className="text"
                      target="_blank"
                    >
                      <img
                        src="/img/icons/whatsapp.png"
                        srcSet="/img/icons/whatsapp@2x.png 2x, /img/icons/whatsapp@3x.png 3x"
                      />
                      (11) 99353-2070
                    </a>
                  </div>
                  <p className="text small hour">
                    Segunda a Sexta: 07hs às 19hs <br /> Sábado: 07hs às 14hs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ contact }) => {
  return {
    ...contact
  };
};

export default connect(
  mapStateToProps,
  { sendContact, updateContactField }
)(Contact);
