import WebApi from './WebApi';

export class FileService {
  static async upload(file) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = await WebApi.post('/arquivos', file, { headers });
    return response.data;
  } 

  static async getFile(fileId) {
    const response = await WebApi.get(`/arquivos/nota-fiscal/${fileId}`, { }, { responseType: 'blob'});
    return response.data;
  } 
}
