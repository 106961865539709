import ActionTypes from "../actions/ActionTypes";

const initialState = {
  banners: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FILL_BANNERS:
      return { ...state, banners: action.payload };
    default:
      return state;
  }
};
